// #region Hooks and Required files
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import Loader from "../loader";
import EventContextMenu from "./EventContextMenu";
import Technician from "./Technician";
import CoverageModal from "./CoverageModal";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Tooltip } from "antd";
import {
    getAllAssignedTechniciansByGroup, putSupportDataOnCalendar, getSubCompaniesByUserId, postSupportEvent, putSupportEvent, deleteSupportEvent, getAllEventByGroupId,
    getallSupperAdmins, assignSupportFallback, getFallbackBySupportId, getUsersAndOrderByDate, swapTechnicianPresentOrder, deletefallback,
    getSitesBySupportGroupId, postBulkEventsAssign
} from "../../Redux/Api/fetch.api";
import setting from "../../assets/images/settings.svg";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import '../../../src/calendar.css';
import clockIcon from "../../assets/images/clock-icon.svg";
import Clock from "../Support/Clock";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Title from "../Title";
import Trash_light from "../../assets/images/Trash_light.svg";
import multimodal_hand_eye from "../../assets/images/multimodal_hand_eye.svg";
import settings_backup_restore from "../../assets/images/settings_backup_restore.svg";
import dlt from "../../assets/images/dlt.svg";
import { Modal } from 'antd';
import ShoppingBagIcon from '@mui/icons-material/MapTwoTone';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GroupIcon from '@mui/icons-material/GroupAddRounded';
import CampaignIcon from '@mui/icons-material/Campaign';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import RepartitionIcon from '@mui/icons-material/Repartition';
// #endregion

const SupportTechnician = () => {

    // #region Local Variables
    const { t } = useTranslation('supportCalender');
    const scrollButtonsPosition = 'far';
    const schedulerRef = useRef();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [allTechnicians, setAllTechnicians] = useState([]);
    const [technicians, setTechnicians] = useState([]);
    const [events, setEvents] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [supportId, setSupportId] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const history = useHistory();
    const [resourceTechnicians, setResourceTechnicians] = useState([]);
    //const timeZoneName = "Eastern Standard Time"
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [orderUpdated, setOrderUpdated] = useState(false);
    const [supperAdmins, setSupperAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState("");
    const [fallbackId, setFallbackId] = useState(0);
    const [fallbackName, setFallbackName] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [draggedUserId, setDraggedUserId] = useState();
    const [draggedOrderId, setDraggedOrderId] = useState();
    const [droppedUserId, setTechnicianDropUserId] = useState();
    const [droppedOrderId, setTechnicianDropOrderId] = useState();
    const [presentDate, setPresentDate] = useState();
    const [isShadeActive, setIsShadeActive] = useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(true);
    const [isCurrentTimeIndicator, setIsCurrentTimeIndicator] = useState(true);
    const [is12HrFormat, setIs12HrFormat] = useState(true);
    const [currentTime, setCurrentTime] = useState();
    const [supportedSites, setSupportedSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(-1);
    const [selectedSiteName, setSelectedSiteName] = useState(null);
    const [siteStartTime, setSiteStartTime] = useState(null);
    const [siteEndTime, setSiteEndTime] = useState(null);
    const [workingDays, setWorkingDays] = useState(null);
    const [isCoverageModalOpen, setIsCoverageModalOpen] = useState(false);
    const [dateOfCoverage, setDateOfCoverage] = useState(null);
    const [supportGroupName, setSupportGroupName] = useState(null);
    const [activeTab, setActiveTab] = useState("home");
    const weekStartDateRef = useRef(null);
    const weekEndDateRef = useRef(null);
    const iNotificationRef = useRef(null);
    const [showDiv, setShowDiv] = useState(false);
    const [isMaximized, setIsMaximized] = useState(false);

    let cleanupFunction = null;  // To store the cleanup function of old listeners
    let siteid;
    let supportid;
    let groupid;
    const views = ['day', 'week', 'month'];
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set today's date at midnight

    const yesterdayStart = new Date(today);
    yesterdayStart.setDate(today.getDate() - 1); // Subtract 1 day from today
    yesterdayStart.setHours(23, 59, 59, 999); // Set to 23:59:59.999 of yesterday

    const addedListeners = useRef({
        dragover: null,
        drop: null,
        mouseover: null,
        mouseout: null,
        cleanup: null,
    });

    dayjs.extend(utc);
    dayjs.extend(timezone);
    // #endregion

    // #region useEffect
    useEffect(() => {
        // Remove panel when component is unmounted (or when route changes)
        return () => {
            let panel = document.getElementById('change-order-panel');
            if (panel) {
                panel.remove();
            }
        };
    }, []);

    useEffect(() => {
        const processURLParams = async () => {

            // Parse query parameters from the URL
            const queryParams = new URLSearchParams(location.search);
            const siteIdFromUrl = queryParams.get('siteId');
            const supportIdFromUrl = queryParams.get('supportId');
            const groupIdFromUrl = parseInt(queryParams.get('groupId'), 10);

            // Check if `groupIdFromUrl` is a valid number and greater than 0
            if (!isNaN(groupIdFromUrl) && groupIdFromUrl > 0) {
                try {
                    setLoading(true);
                    // Set global variables (if absolutely necessary)
                    groupid = groupIdFromUrl;
                    siteid = siteIdFromUrl;
                    supportid = supportIdFromUrl;

                    // Make asynchronous calls and update states
                    await getAllActiveSitesBySupportGroupId(groupIdFromUrl);
                    setGroupId(groupIdFromUrl);
                    await getAllAssignedTechnician(groupIdFromUrl);
                    await initializeEventScheduler(groupIdFromUrl);
                    await getFallbackAdminBySupportID(groupIdFromUrl);
                    await getAllFallbackSupperAdmins();

                    history.replace({ search: queryParams.toString() });
                } catch (error) {
                    console.error("Error processing URL params:", error);
                }
                finally {
                    setLoading(false);
                }
            }

            // Reset orderUpdated flag
            setOrderUpdated(false);
        };

        processURLParams(); // Call the async function
    }, [location.search, orderUpdated]); // Dependencies

    //useEffect(() => {
    //    filterEvents();
    //}, [selectedSite]);

    useEffect(() => {
        if (droppedOrderId > 0 && droppedUserId !== draggedUserId)
            swapTechnicianOrder();
    }, [droppedOrderId]);

    useEffect(() => {
        // Initial set of the EST time
        setCurrentTime(getGMTTime());

        // Update the current time every minute (60000ms)
        const interval = setInterval(() => {
            setCurrentTime(getGMTTime());
        }, 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (selectedSite != null) {
            addedListeners.current.cleanup = addEventListeners(schedulerRef, handleDrop, handleMouseOver, handleMouseOut);
        }

        return () => {
            if (addedListeners.current.cleanup) {
                addedListeners.current.cleanup();
            }
        };
    }, [selectedSite]);

    // #endregion

    // #region Api's
    // Get the fallbacl supper admin 
    const getAllFallbackSupperAdmins = async () => {

        try {
            const { data: { data: supperAdmins } } = await getallSupperAdmins();
            setSupperAdmins(supperAdmins);

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    // Get the fallbacl supper admin 
    const getFallbackAdminBySupportID = async (supportGroupId) => {

        try {

            const response = await getFallbackBySupportId(supportGroupId);

            if (response.data.data && response.data.data.superAdminId > 0) {
                setFallbackName(response.data.data.fallbackPerson);
                setSelectedAdmin(response.data.data.superAdminId);
                setFallbackId(response.data.data.fallbackId);
            }
            //else {
            //    getAllFallbackSupperAdmins();
            //}

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    // Get the fallbacl supper admin 
    const getAllActiveSitesBySupportGroupId = async (supportGroupId) => {
        try {
            const response = await getSitesBySupportGroupId(supportGroupId);

            if (response.data && response.data.data.length > 0) {

                // get Lowest Start, Highest End Time and unique Selected Days
                const siteDetails = getLowestStartAndHighestEndTime(response.data.data);

                initializeSiteData(response.data.data);

                // Check if siteDetails exists and contains the necessary properties
                if (siteDetails && siteDetails.lowestStartTime && siteDetails.highestEndTime) {

                    // Set start and end times from the selected site's details                    
                    setSiteStartTime(is12HrFormat ? format12TimeZone(siteDetails.lowestStartTime) : format24TimeZone(siteDetails.lowestStartTime));
                    setSiteEndTime(is12HrFormat ? format12TimeZone(siteDetails.highestEndTime) : format24TimeZone(siteDetails.highestEndTime));
                    setWorkingDays(siteDetails.uniqueSelectedDays);

                } else {
                    console.error('Site details not found or missing start/end time');
                }
            }
        } catch (error) {
            console.error("Error fetching support events:", error); // Log any errors encountered
        }
    };

    // Initialize Site Data
    const initializeSiteData = (allSites) => {
        // Update time zone of the sites based on selected time zone
        updateSitesWithTimeZone(allSites, is12HrFormat);
    };

    // Function to assign or update fallback
    const assignFallback = async () => {
        let successMessage;
        let successUpdateMessage;

        if (!selectedAdmin) {
            toast.error("Please select a user"), {
                className: "toastSuccess",
            };
            return;
        }

        const payload = {
            fallbackId: fallbackId,
            supportGroupId: groupId,
            superAdminId: selectedAdmin,
            assignedBy: localStorage.getItem('UserId')
        };

        try {
            let response;

            // Assign new fallback
            response = await assignSupportFallback({ payload });
            successMessage = t("Fallbackhasbeensuccessfullyassigned")
            successUpdateMessage = t("Fallbackhasbeensuccessfullyupdated")

            if (response && response.data.success) {
                getFallbackAdminBySupportID(groupId);
                setIsEdit(false)
                if (fallbackId == 0) {
                    toast.success(successMessage, {
                        className: "toastSuccess",
                    });
                }
                else {
                    toast.success(successUpdateMessage, {
                        className: "toastSuccess",
                    });
                }
            }


            //if (response && response.data.success) {
            //    toast.success(response.data.message, {
            //        className: "toastSuccess",
            //    });
            //}
        } catch (error) {
            console.error("Error assigning/updating fallback:", error);
        }
    };
    // #endregion

    // #region Event Listeners
    const getLowestStartAndHighestEndTime = (data) => {
        if (!data || !Array.isArray(data)) {
            return {
                lowestStartTime: null,
                highestEndTime: null,
                uniqueSelectedDays: ""
            };
        }

        // Extract start and end times, treating them as UTC
        const times = data
            .filter(item => item.startTime && item.endTime) // Filter out rows with null startTime or endTime
            .map(item => ({
                startTime: new Date(`1970-01-01T${item.startTime}Z`), // Convert start time to UTC Date object
                endTime: new Date(`1970-01-01T${item.endTime}Z`) // Convert end time to UTC Date object
            }));

        // Collect all selectedDays, flatten, and handle null/undefined cases
        const allSelectedDays = data
            .map(item => item.selectedDays || []) // Default to an empty array if selectedDays is null/undefined
            .flat();

        // Get unique selected days
        const uniqueSelectedDays = [...new Set(allSelectedDays)];

        // Sort days of the week in order
        const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const sortedUniqueDays = uniqueSelectedDays.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));

        if (times.length > 0) {
            // Find the lowest start time and highest end time
            const lowestStartTime = times.reduce((acc, curr) => (curr.startTime < acc.startTime ? curr : acc)).startTime;
            const highestEndTime = times.reduce((acc, curr) => (curr.endTime > acc.endTime ? curr : acc)).endTime;

            // Return the results in HH:MM:SS format along with sorted unique days
            return {
                lowestStartTime: lowestStartTime.toISOString().substr(11, 8), // Extract HH:MM:SS
                highestEndTime: highestEndTime.toISOString().substr(11, 8), // Extract HH:MM:SS
                uniqueSelectedDays: sortedUniqueDays.join(", ") // Join unique days into a string
            };
        }

        // If no valid times, return default values
        return {
            lowestStartTime: null,
            highestEndTime: null,
            uniqueSelectedDays: sortedUniqueDays.join(", ") // Join unique days into a string
        };
    };

    // add Event Listeners here
    const addEventListeners = (schedulerRef, handleDrop, handleMouseOver, handleMouseOut) => {
        const schedulerElement = schedulerRef.current.nativeElement;

        // Add event listener to allow drag over events (to prevent default browser behavior)
        schedulerElement.addEventListener('dragover', (e) => e.preventDefault());

        // Add event listener to handle the drop event (for external drag-and-drop functionality)
        schedulerElement.addEventListener('drop', handleDrop);

        // Add event listeners for tooltips
        document.addEventListener('mouseover', handleMouseOver);
        document.addEventListener('mouseout', handleMouseOut);

        // Cleanup: remove event listeners when the component is unmounted
        return () => {
            schedulerElement.removeEventListener('dragover', (e) => e.preventDefault());
            schedulerElement.removeEventListener('drop', handleDrop);

            // Clean up event listeners on component unmount
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('mouseout', handleMouseOut);
        };
    };

    // Filter Events
    const filterEventsByTimeZone = (events, selectedTimeZone) => {

        // Filter the events based on selected technicians
        // If no technicians are selected, include all events
        // Filter events by time zone
        let filteredEvents = events;

        if (selectedTimeZone) {
            // Set the filtered events
            filteredEvents = events.filter(x => x.timeZone === selectedTimeZone);
        }

        // Update the data source of the scheduler with the filtered events
        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
            dataSource: filteredEvents,
            dataSourceType: 'array',
            dataFields: getSchedulerDataFields()
        });
    };

    // Get all Technicians By Selected Date
    const getTechniciansByDate = async (date) => {
        setPresentDate(date)
        const payload = {
            groupId: groupid != null ? groupid : groupId,
            date: date,
        };
        try {
            let response;
            response = await getUsersAndOrderByDate(payload);
            if (response.data && response.data.data) {
                return response.data.data; // Assuming response.data.data is an array of technicians

            }
            return [];
        }
        catch (err) {
            // Log the error and display an error toast notification
            console.error("Error:", err);
        }
    }

    // Swap Technicians Order
    const swapTechnicianOrder = async () => {
        setLoading(true)
        const payload = {
            oldUserId: draggedUserId,
            oldCallOrderId: draggedOrderId,
            newUserId: droppedUserId,
            newCallOrderId: droppedOrderId,
            groupId: groupid != null ? groupid : groupId,
            date: presentDate
        };

        try {
            let response;
            response = await swapTechnicianPresentOrder(payload);

            if (response.data.success) {
                await updateTechnicianPanel(presentDate);
                initializeEventScheduler(groupid != null ? groupid : groupId);
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
            }
            setLoading(false)
            setDraggedUserId()
            setDraggedOrderId()
            setTechnicianDropOrderId()
            setTechnicianDropUserId()

            return [];
        }
        catch (err) {
            // Log the error and display an error toast notification
            console.error("Error:", err);
        }

    }

    // Update Technicians Panel
    const updateTechnicianPanel = async (date) => {

        // Fetch the updated technicians list
        const updatedTechnicians = await getTechniciansByDate(date);

        // Find the technician list inside the panel
        const technicianList = document.getElementById('change-order-panel').querySelector('ul');

        // Clear the current list
        technicianList.innerHTML = '';

        // Populate the list with updated technicians
        updatedTechnicians.forEach((technician, index) => {
            bindTechnician(technician, index, technicianList);
        });

        setDraggedUserId()
        setDraggedOrderId()
        setTechnicianDropOrderId()
        setTechnicianDropUserId()
    };

    // Function to format the header of a timeline based on orientation and date details
    const timelineHeaderFormatFunction = (date, orientation, isHeaderDetails, dateValue) => {
        if (orientation === 'horizontal') {
            const segments = dateValue.split(' ');
            if (segments.length === 2) {
                return `
                <div style="display: flex; align-items: center; justify-content: center; gap: 4px;">
                    <span style="font-size: 14px; ">${segments[1]}</span>
                    <div style="
                        width: 24px; 
                        height: 24px; 
                        border-radius: 50%; 
                        background-color: #BA7B53; 
                        display: flex; 
                        align-items: center; 
                        justify-content: center; 
                        font-size: 12px; 
                        color: #fff;
                    ">
                        ${segments[0]}
                    </div>
                </div>
            `;
            }
        } else if (orientation === 'vertical') {
            // Format for vertical timeline headers (time in HH:mm) with custom styling
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: is12HrFormat, // Use 12-hour or 24-hour format based on the flag
            });

            return `
            <div style="text-align: center; font-size: 13px;">
                ${formattedTime}
            </div>
        `;
        }
        return dateValue; // Fallback in case no formatting matches
    };

    // Open Success Notification
    const openSuccessNotification = (message) => {
        schedulerRef.current.openNotification(message, {
            autoClose: true,
            type: 'success',        // Notification type
            itemClass: 'custom-notification', // Custom CSS class
        });
    };

    // Open Error Notification
    const openErrorNotification = (message) => {
        schedulerRef.current.openNotification(message, {
            autoClose: true,
            type: 'error',
            itemClass: 'custom-notification'
        });
    }

    // Open Information Notification
    const openInfoNotification = (message) => {
        schedulerRef.current.openNotification(message, {
            autoClose: true, // Show notification for 5000ms (5 seconds) or adjust as needed
            type: 'info',
            itemClass: 'custom-notification'
        });
    };

    // Showing Confirmation
    const showConfirmation = (message) => {
        schedulerRef.current.openNotification(message, {
            autoClose: true, // Prevent the notification from closing automatically
            type: 'confirmation', // Assuming the type supports confirmation-style dialogs
            itemClass: 'custom-confirmation',
        });
    };

    // Technician resources
    const resources = [
        {
            label: 'Technician',
            value: 'userId',
            dataSource: resourceTechnicians
        }
    ];

    // Initialize Event Scheduler API
    const initializeEventScheduler = async (groupid, searchedTimeZone = null,
        weekStartDate = null, weekEndDate = null) => {

        try {
            if (!weekStartDate || !weekEndDate) {
                const today = dayjs();
                weekStartDate = today.startOf('week').format('YYYY-MM-DD');
                weekEndDate = today.endOf('week').format('YYYY-MM-DD');
            }

            const payload = {
                GroupId: groupid != null ? groupid : groupId,
                WeekStartDate: weekStartDate,
                WeekEndDate: weekEndDate,
            };

            // Fetch events from the API
            const response = await getAllEventByGroupId(payload);

            // Convert the event times to the user's local time zone
            const events = response.data.data.map(event => ({
                ...event,
                // Ensure StartDate and EndDate are formatted in the user's local time
                StartDate: dayjs(event.StartDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'),
                EndDate: dayjs(event.EndDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss')
            }));

            filterEventsByTimeZone(events, searchedTimeZone);

            //// Initialize the scheduler with the converted event data
            //schedulerRef.current.dataSource = new window.Smart.DataAdapter({
            //    dataSource: events, // Use converted event times
            //    dataSourceType: 'array',
            //    dataFields: getSchedulerDataFields()
            //});

            // Set the events in the local state
            setEvents(events);

            // Apply any custom calendar settings (if needed)
            await customCalendarSettings();
        } catch (error) {
            // Handle any errors that occur during the event fetching process
            console.error("Error fetching support events:", error);
        }
    };

    // Function to apply custom settings to the calendar scheduler component
    const customCalendarSettings = () => {
        const scheduler = document.querySelector('smart-scheduler');

        // Listen for the edit dialog opening event on the scheduler
        scheduler.addEventListener('editDialogOpen', async function (event) {
            // Get the editors used in the edit dialog
            const editors = event.detail.editors;
            // If there are no editors, exit the function
            if (!editors) {
                return;
            }
            // Get references to various editors and elements in the dialog
            const schedulerEvent = event.detail.item,
                allDayEditor = editors.allDay,
                repeatEditor = editors.repeat,
                statusEditor = editors.status,
                labelEditor = editors.label,
                descriptionEditor = editors.description,
                notificationEditor = editors.notifications,
                disableLabel = editors.label,
                disableStartDate = editors.dateStart,
                disableEndDate = editors.dateEnd,
                disableBackgroundColor = editors.backgroundColor,
                resourceDataEditor = editors.resourceData,
                resourcesEditor = editors.resources;

            // Hidden all properties 
            repeatEditor.classList.add('smart-hidden');
            allDayEditor.classList.add('smart-hidden');
            notificationEditor.classList.add('smart-hidden');
            resourceDataEditor.classList.add('smart-hidden');
            resourcesEditor.classList.add('smart-hidden');

            // Set custom placeholders for the label and description editors
            labelEditor.querySelector('.smart-element').placeholder = 'Enter Support Note...';
            descriptionEditor.querySelector('.smart-element').placeholder = 'Enter Support Description...';
            statusEditor.querySelector('#schedulerstatusLabel').textContent = "Sub Companies"


            // Get today's date without time for comparison
            const today_ = new Date();
            today_.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(schedulerEvent.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Check if the start date is before today or if the event falls within restricted hours
            if (startDate < today_) {
                // Hide Ok footer 
                event.detail.target.footerPosition = 'none';
                disableLabel.classList.add('smart-disabled');
                descriptionEditor.classList.add('smart-disabled');
                disableStartDate.classList.add('smart-disabled');
                disableEndDate.classList.add('smart-disabled');
                disableBackgroundColor.classList.add('smart-disabled');
            } else {
                // Show Ok footer 
                event.detail.target.footerPosition = 'bottom';
                disableLabel.classList.remove('smart-disabled');
                descriptionEditor.classList.remove('smart-disabled');
                disableStartDate.classList.remove('smart-disabled');
                disableEndDate.classList.remove('smart-disabled');
                disableBackgroundColor.classList.remove('smart-disabled');
            }
        });
    }

    // clear Scheduler
    const clearScheduler = () => {
        const schedulerElement = schedulerRef.current;

        // Clear the data source
        schedulerElement.dataSource = new window.Smart.DataAdapter({
            dataSource: [],
            dataSourceType: 'array',
            dataFields: getSchedulerDataFields(),
        });

        schedulerElement.refresh();
    };

    function getSchedulerDataFields() {
        return [
            { name: 'id', map: 'eventId', dataType: 'number' },
            { name: 'label', map: 'label', dataType: 'string' },
            { name: 'dateStart', map: 'startDate', dataType: 'date' }, // Change to 'date'
            { name: 'dateEnd', map: 'endDate', dataType: 'date' },     // Change to 'date'
            { name: 'description', map: 'description', dataType: 'string' },
            { name: 'allDay', map: 'isAllDay', dataType: 'bool' },
            { name: 'backgroundColor', map: 'backGroundColor', dataType: 'string' },
            { name: 'isDrag', map: 'isDrag', dataType: 'bool' },
            { name: 'userId', map: 'clientId', dataType: 'number' },
            { name: 'orderId', map: 'orderId', dataType: 'number' },
            { name: 'groupName', map: 'supportGroup', dataType: 'string' },
            { name: 'conference', map: 'conference', dataType: 'string' },
            { name: 'status', map: 'status', dataType: 'string' },
            { name: 'timeZone', map: 'timeZone', dataType: 'string' }
        ];
    }

    const getAllAssignedTechnician = async (groupIdFromUrl) => {
        try {
            // Fetch events from API
            const response = await getAllAssignedTechniciansByGroup(groupIdFromUrl);

            // Check if data is available in the response
            if (!response || !response.data || !Array.isArray(response.data.data) || response.data.data.length === 0) {
                throw new Error("No technicians found for the given group.");
            }

            // Map the unique clients as resources for the scheduler (technicians)
            const technicians_ = response.data.data.map((resource, index) => ({
                label: resource.userName,  // Display name for the technician
                id: resource.clientId,   // Unique ID for the technician
                supportGroup: resource.supportGroup,
                orderId: resource.orderId,
                groupId: groupIdFromUrl,
                timeZone: resource.timeZone,
                userId: resource.clientId,
                startTime: resource.startTime,
                endTime: resource.endTime,
                bgColor: getUniqueColor(index),
                localStartTime: resource.localStartTime,
                localEndTime: resource.localEndTime,
            }));

            // Update the UI state with technician data
            setAllTechnicians(technicians_);
            setTechnicians(technicians_);
            setResourceTechnicians(technicians_);
            setSupportGroupName(technicians_[0] && technicians_[0].supportGroup);

        } catch (error) {
            console.error("Error fetching assigned technicians:", error);  // Log the detailed error for debugging
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);  // Toggle the collapse state
        setActiveTab("home");
    };

    const parseTime = (time) => {
        let hours = 0, minutes = 0, seconds = 0;

        if (typeof time === "string") {
            const timeRegex = /^(\d{1,2})(?::(\d{1,2}))?(?::(\d{1,2}))?\s*(am|pm)?$/i;
            const match = time.match(timeRegex);

            if (match) {
                hours = parseInt(match[1], 10);
                minutes = match[2] ? parseInt(match[2], 10) : 0;
                seconds = match[3] ? parseInt(match[3], 10) : 0;

                if (match[4]) {
                    const period = match[4].toLowerCase();
                    if (period === "pm" && hours < 12) {
                        hours += 12;
                    } else if (period === "am" && hours === 12) {
                        hours = 0;
                    }
                }
            }
        } else if (typeof time === "number") {
            hours = time;
        }

        return { hours, minutes, seconds };
    };

    // Function to handle the drag start event for external tasks (technicians)
    const handleDragStart = (event, tech) => {
        // Safely extract properties from the tech object
        //const { label, bgColor, userId, supportGroup, orderId, groupId } = tech || {};

        // Create a data object to transfer
        const data = JSON.stringify({
            label: tech.label || '',
            bgColor: tech.bgColor || '',
            userId: tech.userId || null,
            supportGroup: tech.supportGroup || '',
            orderId: tech.orderId || null,
            groupId: groupId || null,
            techStartTime: tech.startTime || null,
            techEndTime: tech.endTime || null,
            siteStartTime: siteStartTime || null,
            siteEndTime: siteEndTime || null,
            siteWorkingDays: workingDays || null,
            allEvents: events || null
        });

        // Set the data in JSON format
        event.dataTransfer.setData('application/json', data);
    };

    const handleDrop_ = (event) => {
        const data = event.dataTransfer.getData('application/json');  // Retrieve the label of the dropped task
        const { label, bgColor, userId, orderId, techStartTime, techEndTime, siteStartTime, siteEndTime, siteWorkingDays, allEvents } = JSON.parse(data);
        const scheduler = schedulerRef.current;
        if (scheduler) {

            // Get the drop location date/time based on the coordinates of the drop event
            const dropDate = scheduler.getDateFromCoordinates(event.clientX, event.clientY);
            const dropDateHours = dropDate.getHours();
            const dropDateDay = getDay(dropDate);
            const startDate = new Date(dropDate);

            let _siteStartTime = parseCellTime(siteStartTime);
            let _siteEndTime = parseCellTime(siteEndTime);
            let isLayOver = false;

            // Adding Site start date and end date time 
            let siteStartDateTime = new Date(startDate.setHours(_siteStartTime, 0, 0, 0));
            let siteEndDateTime = new Date(startDate.setHours(_siteEndTime, 0, 0, 0));

            debugger;
            if (_siteStartTime > _siteEndTime) {
                //_siteEndTime = _siteEndTime + 12;
                isLayOver = !isLayOver;
                siteEndDateTime.setDate(siteEndDateTime.getDate() + 1); // Move to the next day
            }

            // Validation checking
            // Helper Functions for Validation
            const isEventInPast = (dropDate) => dropDate <= yesterdayStart;
            //const isEventWithinWorkingHours = (dropDateHours, siteStartTime, siteEndTime) => dropDateHours >= siteStartTime && dropDateHours <= siteEndTime;
            const isEventWithinWorkingHours = (dropDate, siteStartDateTime, siteEndDateTime) => {
                if (!(dropDate instanceof Date) || !(siteStartDateTime instanceof Date) || !(siteEndDateTime instanceof Date)) {
                    throw new Error("All inputs must be valid Date objects.");
                }
                return dropDate.getTime() >= siteStartDateTime.getTime() && dropDate.getTime() <= siteEndDateTime.getTime();
            };

            const isEventOnWorkingDay = (dropDateDay, siteWorkingHrs) => siteWorkingHrs.includes(dropDateDay);

            // Validation Checks
            const validateEvent = () => {
                if (isEventInPast(dropDate)) {
                    openErrorNotification("The event must not fall on a previous date or time.");
                    return false;
                }

                //if (!isEventWithinWorkingHours(dropDate, siteStartDateTime, siteEndateTime)) {
                //    openErrorNotification("The event must fall within the site's working hours.");
                //    return false;
                //}

                if (!isEventOnWorkingDay(dropDateDay, siteWorkingDays)) {
                    openErrorNotification("The event must fall on a working day.");
                    return false;
                }

                return true;
            };

            // Call the validation
            if (!validateEvent()) {
                return; // Stop further execution if any validation fails
            }

            debugger;

            const endDate = new Date(dropDate);
            const _techStartTime = parseCellTime(techStartTime);
            const _techEndTime = parseCellTime(techEndTime);
            let techStartDateTime = new Date(startDate.setHours(_techStartTime, 0, 0, 0));
            let techEndDateTime = new Date(startDate.setHours(_techEndTime, 0, 0, 0));
            let techStartDateMint = getMinutesFromTime(techStartTime);
            let techEndDateMint = getMinutesFromTime(techEndTime);
            let siteStartDateMint = getMinutesFromTime(siteStartTime);
            let siteEndDateMint = getMinutesFromTime(siteEndTime);

            const segments = []; // Array to hold the calculated segments for task positioning

            if (_techEndTime <= _techStartTime) {
                // First segment: From _techStartTime to midnight (end of the current day)
                if (siteEndDateTime >= techStartDateTime) {
                    // technician start time is greater then site end time 
                    segments.push({
                        startDate: (siteEndDateTime <= techStartDateTime) ? new Date(dropDate.setHours(_siteEndTime, 0, 0, 0))
                            : new Date(dropDate.setHours(_techStartTime, 0, 0, 0)), // Set start time to _techStartTime

                        //endDate: (siteStartDateTime >= techEndDateTime) ? new Date(dropDate.setHours(23, 59, 59, 0))
                        //    : new Date(dropDate.setHours(_siteStartTime, 0, 0, 0)),               // Set end time to midnight

                        endDate: (siteStartDateTime >= techEndDateTime) ? new Date(dropDate.setHours(23, 59, 59, 0))
                            : (!isLayOver) ? new Date(dropDate.setHours(_siteEndTime, 0, 0, 0))
                                : new Date(dropDate.setHours(_siteStartTime, 0, 0, 0)),               // Set end time to midnight
                    });
                } // Passed

                // Second segment: From midnight (start of the next day) to _techEndTime
                // Calculate Technician end time for next day 
                // Next Day
                const nextDayStart = new Date(dropDate);
                nextDayStart.setDate(nextDayStart.getDate() + 1); // Move to the next day

                const nextDayTechStartTime = 0;
                let techStartTimeNextDay = new Date(nextDayStart.setHours(nextDayTechStartTime, 0, 0, 0));
                let techEndTimeNextDay = new Date(nextDayStart.setHours(_techEndTime, 0, 0, 0));
                if (techEndTimeNextDay >= siteStartDateTime) {
                    debugger;

                    // Check for Working Days 
                    if (isEventOnWorkingDay(getDay(nextDayStart), siteWorkingDays)) {
                        //openInfoNotification("The event must fall on a working day.");
                        //return false;

                        // Next Day Site start and End dater calculation
                        let siteStartDateTimeNextDay = new Date(nextDayStart.setHours((!isLayOver) ? _siteStartTime : 0, 0, 0, 0));
                        let siteEndDateTimeNextDay = new Date(nextDayStart.setHours(_siteEndTime, 0, 0, 0));

                        //if (siteStartDateTime <= techStartTimeNextDay || techEndTimeNextDay > siteStartDateTime) {
                        // Next day tech start and end time completely outside
                        if (siteStartDateTimeNextDay <= techStartTimeNextDay || techEndTimeNextDay > siteEndDateTimeNextDay) {

                            segments.push({
                                startDate: (siteStartDateTimeNextDay.getTime() === techStartTimeNextDay.getTime()) ? new Date(nextDayStart.setHours(0, 0, 0, 0))
                                    : new Date(nextDayStart.setHours(_siteStartTime, 0, 0, 0)),           // Start at midnight of the next day

                                endDate: (siteEndDateTimeNextDay >= techEndTimeNextDay) ? new Date(nextDayStart.setHours(_techEndTime, 0, 0, 0))
                                    : new Date(nextDayStart.setHours(_siteEndTime, siteEndDateMint, 0, 0)),  // End at _techEndTime of the next day
                            });
                        }

                        // Next day tech start outside and end time inside
                        if (techStartTimeNextDay < siteStartDateTimeNextDay && techEndTimeNextDay <= siteEndDateTimeNextDay) {
                            segments.push({
                                startDate: new Date(nextDayStart.setHours(_siteStartTime, 0, 0, 0)), // Start at the site start time
                                endDate: new Date(nextDayStart.setHours(_techEndTime, techEndDateMint, 0, 0)),     // End at the tech end time
                            });
                        }
                    }
                } // Passed
            }
            else {
                // Inside Event on coverage
                if (isLayOver) {
                    // Check if the site's start and end times overlap or are entirely within the technician's time range
                    // (Start Time) Inside Coverage + (End Time) Inside Coverage
                    if (siteStartDateTime <= techStartDateTime && siteEndDateTime >= techEndDateTime) {
                        segments.push({
                            startDate: new Date(dropDate.setHours(_techStartTime, 0, 0, 0)),
                            endDate: new Date(endDate.setHours(_techEndTime, 59, 0, 0))
                        });
                    }

                    // (Start Time) Outside Coverage + (End Time) Inside Coverage
                    if (techStartDateTime <= siteStartDateTime && siteEndDateTime >= techEndDateTime) {
                        segments.push({
                            startDate: new Date(dropDate.setHours(_siteStartTime, siteStartDateMint, 0, 0)),
                            endDate: new Date(endDate.setHours(_techEndTime, techEndDateMint, 0, 0))
                        });
                    }
                }
                else {
                    // Logic to adjust the start time
                    //if (_siteEndTime >= _techEndTime) {
                    debugger;

                    //if (siteStartDateTime <= techStartDateTime || techEndDateTime > siteEndDateTime) {
                    if ((techStartDateTime >= siteStartDateTime && techStartDateTime <= siteEndDateTime) ||
                        (techEndDateTime >= siteStartDateTime && techEndDateTime <= siteEndDateTime)) {
                        segments.push({
                            startDate: (_siteStartTime <= _techStartTime) ? new Date(dropDate.setHours(_techStartTime, techStartDateMint, 0, 0))
                                : new Date(dropDate.setHours(_siteStartTime, 0, 0, 0)),

                            endDate: (_siteEndTime >= _techEndTime) ? new Date(endDate.setHours(_techEndTime, techEndDateMint, 0, 0))
                                : new Date(endDate.setHours(_siteEndTime, siteEndDateMint, 0, 0))
                        });
                    } // Passed
                }
            }

            // Validation checking
            // Ensure StartDate and EndDate are in the same format before comparing
            const formatDate = (date) => dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

            // Loop through the segments array to create a new event for each segment
            segments.forEach(segment => {
                const newEvent = {
                    label: label,  // Set the label of the event (technician's name)
                    dateStart: formatDate(segment.startDate),  // Format start time of the new event
                    dateEnd: formatDate(segment.endDate), // Format end time
                    backgroundColor: bgColor,  // Background color for the event
                    userName: label,
                    userId: userId,
                    description: "",
                    //orderId: orderId
                };

                // Check if the event already exists based on userId and date
                const eventExists = allEvents.some(event => {
                    const eventDate = getDateOnly(event.dateStart);
                    const dropDateOnly = getDateOnly(dropDate);
                    return event.clientId === newEvent.userId && eventDate.getTime() === dropDateOnly.getTime();
                });

                if (!eventExists) {
                    // Add the new event to the existing list of events
                    setEvents((prevEvents) => {
                        const updatedEvents = [...prevEvents, newEvent];
                        scheduler.dataSource = [];
                        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                            dataSource: updatedEvents,
                            dataSourceType: 'array',
                            dataFields: getSchedulerDataFields()
                        });
                        return updatedEvents;
                    });

                    // Insert data into the database on drag and drop
                    refreshData('insert', newEvent);
                } else {
                    // Event already exists, you may want to handle this case
                    openErrorNotification("Event already exists. Please create a new one.");
                }
            });

            if (segments.length == 0) {
                openInfoNotification("The event cannot be fall due to a time mismatch.");
                return false;
            }
        }
    };

    const handleDrop = (event) => {
        const data = event.dataTransfer.getData('application/json');
        const {
            label,
            bgColor,
            userId,
            orderId,
            techStartTime,
            techEndTime,
            siteStartTime,
            siteEndTime,
            siteWorkingDays,
            allEvents,
        } = JSON.parse(data);
        const scheduler = schedulerRef.current;

        if (!scheduler) return;

        const dropDate = scheduler.getDateFromCoordinates(event.clientX, event.clientY);
        const dropDateDay = getDay(dropDate);
        let isSiteLayOver = false;

        const adjustDate = (date, { hours, minutes, seconds = 0 }, isNextDay = false) => {
            const adjustedDate = new Date(date);
            adjustedDate.setHours(hours, minutes, seconds, 0);
            if (isNextDay) adjustedDate.setDate(adjustedDate.getDate() + 1);
            return adjustedDate;
        };

        const siteStart = parseTime(siteStartTime);
        const siteEnd = parseTime(siteEndTime);
        const techStart = parseTime(techStartTime);
        const techEnd = parseTime(techEndTime);
        debugger;

        // Determine if the site times lay over midnight
        isSiteLayOver = siteStart.hours > siteEnd.hours ||
            (siteStart.hours === siteEnd.hours && siteStart.minutes > siteEnd.minutes);

        let siteStartDateTime = adjustDate(dropDate, siteStart);
        let siteEndDateTime = adjustDate(dropDate, siteEnd, siteEnd.hours < siteStart.hours || (siteEnd.hours === siteStart.hours && siteEnd.minutes < siteStart.minutes));

        let techStartDateTime = adjustDate(dropDate, techStart);
        let techEndDateTime = adjustDate(dropDate, techEnd, techEnd.hours < techStart.hours || (techEnd.hours === techStart.hours && techEnd.minutes < techStart.minutes));

        debugger;
        const isEventInPast = (date) => date <= new Date().setHours(0, 0, 0, 0);
        const isEventWithinTime = (startTime, endTime, targetTime) => targetTime >= startTime && targetTime <= endTime;
        const isEventOnWorkingDay = (day, workingDays) => workingDays.includes(day);

        if (isEventInPast(dropDate)) {
            openErrorNotification("The event must not fall on a previous date or time.");
            return;
        }

        if (!isEventOnWorkingDay(dropDateDay, siteWorkingDays)) {
            openErrorNotification("The event must fall on a working day.");
            return;
        }

        if (!isEventWithinTime(siteStartDateTime, siteEndDateTime, dropDate)) {
            openErrorNotification("The event must fall within the site's working hours.");
            return;
        }

        const areDatesOnSameDay = (date1, date2) => {
            return date1.toDateString() === date2.toDateString(); // Returns true if both dates are the same day
        }

        const calculateSegments = () => {
            const segments = [];
            debugger;
            // Current day segment
            if (techEndDateTime > techStartDateTime) {
                // Same day checking
                if (techStartDateTime < siteEndDateTime && techEndDateTime > siteStartDateTime) {
                    const segmentStart = techStartDateTime > siteStartDateTime ? techStartDateTime : siteStartDateTime;
                    const segmentEnd = techEndDateTime < siteEndDateTime ? techEndDateTime : siteEndDateTime;
                    if (segmentStart < segmentEnd) {
                        segments.push({ startDate: segmentStart, endDate: segmentEnd });
                    }
                }

                if (!areDatesOnSameDay(techStartDateTime, techEndDateTime) && !isSiteLayOver) {
                    // Next day segment (only if techEndTime < techStartTime)
                    const secondSegmentStart = siteStartDateTime;
                    const secondSegmentEnd = techEndDateTime < siteEndDateTime ? techEndDateTime : siteEndDateTime;
                    if (secondSegmentStart < secondSegmentEnd) {
                        const nextDaySegmentStart = new Date(secondSegmentStart);
                        nextDaySegmentStart.setDate(nextDaySegmentStart.getDate() + 1);

                        // Check for Working Days 
                        if (isEventOnWorkingDay(getDay(nextDaySegmentStart), siteWorkingDays)) {

                            const nextDaySegmentEnd = new Date(secondSegmentEnd);
                            nextDaySegmentEnd.setDate(nextDaySegmentEnd.getDate() + 1);

                            segments.push({ startDate: nextDaySegmentStart, endDate: nextDaySegmentEnd });
                        }
                    }
                }
            } else {
                // Overnight handling for the current day
                const firstSegmentStart = techStartDateTime > siteStartDateTime ? techStartDateTime : siteStartDateTime;
                const firstSegmentEnd = siteEndDateTime;
                if (firstSegmentStart < firstSegmentEnd) {
                    segments.push({ startDate: firstSegmentStart, endDate: firstSegmentEnd });
                }

                // Next day segment (only if techEndTime < techStartTime)
                const secondSegmentStart = siteStartDateTime;
                const secondSegmentEnd = techEndDateTime < siteEndDateTime ? techEndDateTime : siteEndDateTime;
                if (secondSegmentStart < secondSegmentEnd) {
                    const nextDaySegmentStart = new Date(secondSegmentStart);
                    nextDaySegmentStart.setDate(nextDaySegmentStart.getDate() + 1);

                    const nextDaySegmentEnd = new Date(secondSegmentEnd);
                    nextDaySegmentEnd.setDate(nextDaySegmentEnd.getDate() + 1);

                    segments.push({ startDate: nextDaySegmentStart, endDate: nextDaySegmentEnd });
                }
            }

            return segments;
        };

        const segments = calculateSegments();

        if (segments.length === 0) {
            openInfoNotification("The event cannot be scheduled due to a time mismatch.");
            return;
        }

        const formatDate = (date) => dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

        segments.forEach((segment) => {
            const newEvent = {
                label,
                dateStart: formatDate(segment.startDate),
                dateEnd: formatDate(segment.endDate),
                backgroundColor: bgColor,
                userName: label,
                userId,
                description: "",
            };

            const eventExists = allEvents.find((x) => x.clientId === newEvent.userId && getDateOnly(x.dateStart) === getDateOnly(newEvent.dateStart));
            if (!eventExists) {
                setEvents((prevEvents) => {
                    const updatedEvents = [...prevEvents, newEvent];
                    schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                        dataSource: updatedEvents,
                        dataSourceType: 'array',
                        dataFields: getSchedulerDataFields(),
                    });
                    return updatedEvents;
                });

                refreshData("insert", newEvent);
            } else {
                openErrorNotification("Event already exists. Please create a new one.");
            }
        });
    };

    const getDay = (coverageDate) => {
        const date = new Date(coverageDate);
        if (isNaN(date)) {
            throw new Error("Invalid date format");
        }
        return date.toLocaleDateString(undefined, { weekday: "long" });
    };

    // Function to extract only the date portion (ignoring time)
    function getDateOnly(dateTime) {
        return dateTime.split("T")[0]; // Splits at "T" and returns the date part
    }

    const refreshData = async (action, eventItem) => {
        if (!eventItem.userId) {
            toast.error("Event not added", {
                className: "toastError",
            });
            return;
        }

        const localGroupId = groupid || groupId;

        // Prepare the payload
        const payload = {
            EventId: eventItem.id,
            EventName: eventItem.label,
            StartDate: dayjs(eventItem.dateStart).format('YYYY-MM-DDTHH:mm:ss'),
            EndDate: dayjs(eventItem.dateEnd).format('YYYY-MM-DDTHH:mm:ss'),
            Description: eventItem.description,
            IsAllDay: eventItem.allDay,
            SupportUserGroupId: eventItem.userId,
            BackGroundColor: eventItem.backgroundColor,
            Conference: eventItem.conference,
            GroupID: localGroupId,
            OrderId: eventItem.orderId,
        };

        try {
            const actionMap = {
                insert: {
                    apiCall: () => postSupportEvent(payload),
                    successMessage: t("Eventcreatedsuccessfully"),
                    errorMessage: t("Noeventsadded"),
                },
                update: {
                    apiCall: () => putSupportEvent(payload),
                    successMessage: t("Eventupdatedsuccessfully"),
                    errorMessage: t("Eventnotfoundorcouldnotbeupdated"),
                },
                delete: {
                    apiCall: () => deleteSupportEvent(eventItem.id),
                    successMessage: t("Eventdeletedsuccessfully"),
                    errorMessage: t("Eventnotfound."),
                },
            };

            if (!actionMap[action]) {
                throw new Error("Unknown action type");
            }

            const { apiCall, successMessage, errorMessage } = actionMap[action];
            const response = await apiCall();

            removeChangeOrderPanel();

            if (response.data.success) {
                await initializeEventScheduler(localGroupId, selectedTimeZone, weekStartDateRef.current, weekEndDateRef.current); // Refresh event data
                openSuccessNotification(successMessage);
                return response;
            } else {
                openErrorNotification(errorMessage);
            }

        } catch (err) {
            console.error("Error:", err);
        }
    };

    // Helper to remove the Change Order Panel
    const removeChangeOrderPanel = () => {
        const panel = document.getElementById('change-order-panel');
        if (panel) {
            panel.remove();
        }
    };

    const handleTechnicianDragStart = (technician) => {

        setDraggedUserId(technician.clientId);
        setDraggedOrderId(technician.orderId)
    };

    const handleTechnicianDrop = (technician) => {
        setTechnicianDropUserId(technician.clientId)
        setTechnicianDropOrderId(technician.orderId)
        // swapTechnicianOrder();
    };

    // Function to customize the appearance of an event in the scheduler 
    const eventTemplate = function (eventContent, eventObj) {

        const scheduler = this;
        // Wrapper for event content
        let eventContentWrapper = eventContent.querySelector('.event-content-wrapper');
        if (!eventContentWrapper) {
            eventContentWrapper = document.createElement('div');
            eventContentWrapper.classList.add('event-content-wrapper');
        }
        // Get or create various elements for the event display
        let label = eventContent.querySelector('label'),
            time = eventContent.querySelector('div.time'),
            orderNoContainer = eventContent.querySelector('.support-order-no-container'),
            //descLabel = eventContent.querySelector('.desc'),
            orderIdCircle = eventContent.querySelector('.support-Orderid-circle'),
            groupName = eventContent.querySelector('.support-GroupName'),
            timeZone = eventContent.querySelector('.support-TimeZone'), // Variable for timeZone
            img = eventContent.querySelector('img'),
            inviteButton = eventContent.querySelector('.invite-link-button'); // Check for existing button

        // Label for the technician's name or event
        if (!label) {
            label = document.createElement('label');
            label.classList.add('time', 'support-technician-name');
            label.title = "Click to reorder"; // Add tooltip to the label
            eventContentWrapper.appendChild(label);

            // Event onclick
            label.onclick = async () => {

                let gettechnicians = [];
                // Call the api and get the technicians based on selcted date and support group
                gettechnicians = await getTechniciansByDate(formatDate(eventObj.dateStart));

                // Check if the panel already exists
                let panel = document.getElementById('change-order-panel');
                if (panel) {
                    panel.remove(); // Remove the old panel
                }

                // Create the panel element
                panel = document.createElement('div');
                panel.id = 'change-order-panel';
                panel.style.position = 'fixed';
                panel.style.right = '0';
                panel.style.top = '0';
                panel.style.width = '500px';
                panel.style.height = '100%';
                panel.style.backgroundColor = '#585858';
                panel.style.boxShadow = '0 0 10px rgba(0,0,0,0.3)';
                panel.style.padding = '20px';
                panel.style.overflowY = 'auto';
                panel.style.zIndex = '1000';
                panel.style.fontFamily = 'Arial, sans-serif';

                // Add header with close icon
                const header = document.createElement('div');
                header.style.display = 'flex';
                header.style.justifyContent = 'space-between';
                header.style.alignItems = 'center';
                header.style.marginBottom = '20px';

                const headerTitle = document.createElement('h3');
                headerTitle.textContent = 'Change Technician Order';
                header.appendChild(headerTitle);

                const closeButton = document.createElement('button');
                closeButton.style.border = 'none';
                closeButton.style.background = 'none';
                closeButton.style.fontSize = '16px';
                closeButton.style.cursor = 'pointer';
                closeButton.style.color = '#fff';
                closeButton.title = 'Click to close';
                closeButton.textContent = '✖'; // Close icon
                closeButton.onclick = () => {
                    panel.remove(); // Remove the panel when the close button is clicked
                };
                header.appendChild(closeButton);
                panel.appendChild(header);

                // Add icon and text (Selected Date)
                const selectedDateContainer = document.createElement('div');
                selectedDateContainer.style.display = 'flex';
                selectedDateContainer.style.alignItems = 'center';
                selectedDateContainer.style.backgroundColor = '#f4a261';
                selectedDateContainer.style.borderRadius = '10px';
                selectedDateContainer.style.padding = '10px 15px';
                selectedDateContainer.style.marginBottom = '20px';

                const circleIcon = document.createElement('div');
                circleIcon.style.width = '30px';
                circleIcon.style.height = '30px';
                circleIcon.style.borderRadius = '50%';
                circleIcon.style.backgroundColor = '#007bff';
                circleIcon.style.display = 'flex';
                circleIcon.style.justifyContent = 'center';
                circleIcon.style.alignItems = 'center';
                circleIcon.style.marginRight = '10px';

                const circleIconContent = document.createElement('span');
                circleIconContent.textContent = '📅'; // Use emoji or replace with an image for date
                circleIconContent.style.color = 'white';
                circleIcon.style.fontSize = '14px';
                circleIcon.appendChild(circleIconContent);

                const selectedDateText = document.createElement('span');
                selectedDateText.textContent = 'Selected Date : ' + formatDate(eventObj.dateStart); // Replace with dynamic date if needed
                selectedDateText.style.color = '#000';
                selectedDateText.style.fontWeight = 'bold';

                selectedDateContainer.appendChild(circleIcon);
                selectedDateContainer.appendChild(selectedDateText);
                panel.appendChild(selectedDateContainer);

                // Add draggable list of technicians with Order ID
                const technicianList = document.createElement('ul');
                technicianList.style.listStyle = 'none';
                technicianList.style.padding = '0';
                technicianList.style.margin = '0';

                // bind technicians 
                gettechnicians.forEach((technician, index) => {
                    bindTechnician(technician, index, technicianList);
                });

                panel.appendChild(technicianList);
                document.body.appendChild(panel);
            };
        }

        // Time label
        if (!time) {
            time = document.createElement('div');
            time.classList.add('time', 'support-small-time');
            eventContentWrapper.appendChild(time);
        }

        // Group Name
        if (!groupName) {
            groupName = document.createElement('div');
            groupName.classList.add('support-GroupName');
            eventContentWrapper.appendChild(groupName);
        }

        // Order No container for aligning Order ID and Circle
        if (!orderNoContainer) {
            orderNoContainer = document.createElement('div');
            orderNoContainer.classList.add('support-order-no-container');
            eventContentWrapper.appendChild(orderNoContainer);
        }

        // Order ID circle
        if (!orderIdCircle) {
            orderIdCircle = document.createElement('div');
            orderIdCircle.classList.add('support-Orderid-circle');
            orderNoContainer.appendChild(orderIdCircle);
        }

        // Check if the timeZone element exists, if not, create it
        if (!timeZone) {
            timeZone = document.createElement('div');
            timeZone.classList.add('support-TimeZone'); // Add a class for styling
            timeZone.title = "Time Zone"; // Optional: Add a tooltip for time zone
            eventContentWrapper.appendChild(timeZone);
        }

        // Set text content
        label.innerHTML = eventObj.orderId
            ? `${eventObj.label} <span class="support-tech-order-circle">${eventObj.orderId}</span>`
            : `${eventObj.label}`;

        let startFrom = formatTimefromDate(eventObj.dateStart, is12HrFormat);
        let endTo = formatTimefromDate(eventObj.dateEnd, is12HrFormat);

        time.textContent = `${startFrom} - ${endTo}`;

        // Set the Order ID circle color and text
        orderIdCircle.style.backgroundColor = eventObj.orderIdColor || '#FF5722'; // Default color
        orderIdCircle.textContent = eventObj.orderId;

        // Check if "Order Id:" label already exists
        let orderIdLabel = orderNoContainer.querySelector('.support-order-id-label');
        if (!orderIdLabel) {
            orderIdLabel = document.createElement('span');
            orderIdLabel.classList.add('support-order-id-label');
            orderIdLabel.textContent = "Order Id : ";
            orderNoContainer.insertBefore(orderIdLabel, orderIdCircle); // Insert before the circle
        }

        // Set additional information
        //descLabel.textContent = "Description : ";
        timeZone.textContent = "Zone : " + (eventObj.timeZone || "Not specified"); // Display the time zone text    

        if (!eventContentWrapper.parentElement) {
            eventContent.appendChild(eventContentWrapper);
        }

        // Add right-click event (context menu) to the entire event
        eventContent.oncontextmenu = (event) => {
            event.preventDefault(); // Prevent the default browser context menu
            openRightClickMenuOnEvent(event, eventObj); // Call a custom function to handle the right-click menu
        };

        return eventContent;
    }

    const bindTechnician = (technician, index, technicianList) => {
        const listItem = document.createElement('li');
        listItem.className = 'change-order-list-item';
        listItem.style.setProperty('--bgColor', technician.bgColor || '#ffffff');
        listItem.draggable = true;

        // Circle for Order ID
        const orderCircle = document.createElement('div');
        orderCircle.className = 'change-order-circle';
        orderCircle.textContent = technician.orderId;

        // Technician Name
        const techName = document.createElement('span');
        techName.textContent = technician.userName;

        // Drag Icon
        const dragIcon = document.createElement('span');
        dragIcon.className = 'change-order-drag-icon';
        dragIcon.textContent = '≡'; // Drag icon (you can replace this with an actual image or SVG)
        dragIcon.title = "Technician drag and drop"

        listItem.appendChild(orderCircle);
        listItem.appendChild(techName);
        listItem.appendChild(dragIcon);

        // Add drag event listeners
        listItem.addEventListener('dragstart', (e) => {
            e.dataTransfer.setData('text/plain', index.toString());
            handleTechnicianDragStart(technician);
            listItem.classList.add('dragging');
        });

        listItem.addEventListener('dragend', () => {
            listItem.classList.remove('dragging');
        });

        listItem.addEventListener('dragover', (e) => {
            e.preventDefault(); // Allow dropping
            listItem.classList.add('dragover');
        });

        listItem.addEventListener('dragleave', () => {
            listItem.classList.remove('dragover');
            listItem.classList.add('dragleave');
        });

        listItem.addEventListener('drop', (e) => {
            e.preventDefault();
            const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
            const draggedItem = technicianList.children[draggedIndex];
            handleTechnicianDrop(technician);
            listItem.classList.remove('dragover', 'dragleave');
        });

        technicianList.appendChild(listItem);
    }

    const startTimeObj = parseTime(siteStartTime); // Convert start time to hours, minutes, and seconds
    const endTimeObj = parseTime(siteEndTime);     // Convert end time to hours, minutes, and seconds

    const cellTemplate = (cell, value) => {
        if (siteStartTime && siteEndTime) {
            // Parse start and end times
            const startTimeObj = parseTime(siteStartTime);
            const endTimeObj = parseTime(siteEndTime);

            const startTime = startTimeObj.hours + startTimeObj.minutes / 60 + startTimeObj.seconds / 3600;
            const endTime = endTimeObj.hours + endTimeObj.minutes / 60 + endTimeObj.seconds / 3600;

            const workDays = workingDays?.split(',').map(day => day.trim()) || [];
            const dayName = new Date(value).toLocaleString('en-US', { weekday: 'long' });

            const isWorkingDay = workDays.includes(dayName);

            cell.style.background = '';
            cell.textContent = '';

            if (isWorkingDay) {
                const hourGMT = value.getHours() + value.getMinutes() / 60 + value.getSeconds() / 3600;
                let isWithinTimeRange = false;

                if (startTime < endTime) {
                    isWithinTimeRange = (hourGMT == startTimeObj.hours) || (hourGMT >= startTime && hourGMT < endTime);
                } else {
                    isWithinTimeRange = (hourGMT == startTimeObj.hours) || (hourGMT >= startTime || hourGMT < endTime);
                }

                if (isWithinTimeRange) {
                    if (hourGMT === startTimeObj.hours) {
                        if (startTimeObj.minutes > 0) {
                            const gradientPercentage = (startTimeObj.minutes / 60) * 100;
                            cell.style.background = `linear-gradient(to top, #E3F9E8 ${100 - gradientPercentage}%, transparent 50%)`;
                        } else {
                            cell.style.background = '#E3F9E8';
                        }
                       // cell.textContent = checkAvailability(value) ? '' : '<span>Coverage Required</span>';
                        cell.innerHTML = checkAvailability(value) ? '' : '<span>Coverage Required</span>';
                    } else if (hourGMT === endTimeObj.hours && endTimeObj.minutes > 0) {
                        const gradientPercentage = (endTimeObj.minutes / 60) * 100;
                        cell.style.background = `linear-gradient(to bottom, #E3F9E8 ${100 - gradientPercentage}%, transparent 50%)`;
                    } else {
                        cell.style.background = '#E3F9E8';
                    }

                    cell.oncontextmenu = (e) => {
                        e.preventDefault();
                        openRightClickMenuOnCell(value);
                    };
                } else {
                    resetCell(cell);
                }
            } else {
                resetCell(cell);
            }
        } else {
            resetCell(cell);
        }
    };

    // open Cell Modal On Right Click
    const openRightClickMenuOnCell = (value) => {
        // Check if a modal already exists and remove it
        const existingModal = document.getElementById('cell-custom-modal');
        if (existingModal) {
            existingModal.remove();
        }

        // Create a new modal element
        const modal = document.createElement('div');
        modal.id = 'cell-custom-modal'; // Use ID to match CSS styles

        // Add content to the modal
        const modalContent = document.createElement('div');
        modalContent.classList.add('modal-content'); // Add a class for CSS styling

        // Add the username
        const userName = document.createElement('div');
        userName.textContent = `Date: ${value.toLocaleDateString()}`; // Only date
        userName.className = 'date-userName';
        modalContent.appendChild(userName);

        // Add "View Details" option
        const viewDetails = document.createElement('button');
        viewDetails.className = "common-btn";

        const viewDetailsIcon = document.createElement('img');
        viewDetailsIcon.src = multimodal_hand_eye; // Assuming multimodal_hand_eye is the image URL
        viewDetails.appendChild(viewDetailsIcon);
        viewDetails.appendChild(document.createTextNode('View Details'));

        viewDetails.addEventListener('click', () => {
            modal.remove(); // Remove the modal on close
            // Open modal on button click
            openCoverageModal(value);
        });

        modalContent.appendChild(viewDetails);

        // Add "Change Technician Order" option
        const changeTechnicianOrder = document.createElement('button');
        changeTechnicianOrder.className = "common-btn";
        const changeIcon = document.createElement('img');
        changeIcon.src = settings_backup_restore;
        changeTechnicianOrder.appendChild(changeIcon);
        changeTechnicianOrder.appendChild(document.createTextNode('Change Technician Order'));
        changeTechnicianOrder.addEventListener('click', () => {
            modal.remove();
        });
        modalContent.appendChild(changeTechnicianOrder);

        modal.appendChild(modalContent);

        // Add a close button
        const closeButton = document.createElement('button');
        closeButton.className = "common-btn";
        const deleteIcon = document.createElement('img');
        deleteIcon.src = dlt;
        closeButton.appendChild(deleteIcon);
        closeButton.appendChild(document.createTextNode('Cancel'));
        closeButton.addEventListener('click', () => {
            modal.remove(); // Remove the modal on close
        });
        modalContent.appendChild(closeButton);

        // Append the modal to the body
        document.body.appendChild(modal);

        // Remove the menu if clicked outside
        const closeContextMenu = (e) => {
            if (!modal.contains(e.target)) {
                modal.remove();
                document.removeEventListener('click', closeContextMenu);
            }
        };

        document.addEventListener('click', closeContextMenu);
    };

    // Function to open the modal when the "View Details" button is clicked
    const openCoverageModal = (value = new Date()) => {
        setDateOfCoverage(value);
        setIsCoverageModalOpen(true);
    };

    // Function to close the modal
    const colseCoverageModal = () => {
        setIsCoverageModalOpen(false);
    };

    // open Right Click Menu On Event Click
    let isWeekDays = false;
    const openRightClickMenuOnEvent = (event, eventObj) => {

        // Remove existing context menu if any
        let existingMenu = document.getElementById('schedule-context-menu');
        if (existingMenu) existingMenu.remove();

        // Create a custom context menu
        const contextMenu = document.createElement('div');
        contextMenu.id = 'schedule-context-menu';
        contextMenu.style.top = `${event.clientY}px`;
        contextMenu.style.left = `${event.clientX - 200}px`;

        // Add blue circle
        const blueCircle = document.createElement('div');
        blueCircle.className = 'blue-circle';
        blueCircle.style.backgroundColor = eventObj.backgroundColor;
        contextMenu.appendChild(blueCircle);

        // Add event details container with border
        const eventDetails = document.createElement('div');
        eventDetails.className = 'event-details';

        // Event name
        const eventName = document.createElement('div');
        eventName.className = 'event-name';
        eventName.textContent = eventObj.label;

        // Circle with order ID
        const orderIdCircle = document.createElement('div');
        orderIdCircle.className = 'order-id-circle';
        orderIdCircle.textContent = eventObj.orderId;

        eventName.appendChild(orderIdCircle);

        // Event time
        const eventTime = document.createElement('div');
        eventTime.className = 'event-time';
        eventTime.textContent = formatTimeRange(eventObj.dateStart, eventObj.dateEnd);

        eventDetails.appendChild(eventName);
        eventDetails.appendChild(eventTime);
        contextMenu.appendChild(eventDetails);

        // Create a container div for buttons
        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'btn-box';

        const Days = document.createElement('button');
        Days.classList.add('days-weeks', 'days-selected');
        Days.appendChild(document.createTextNode('Days'));

        const Weeks = document.createElement('button');
        Weeks.className = 'days-weeks';
        Weeks.appendChild(document.createTextNode('Weeks'));

        // Append buttons to the container
        buttonContainer.appendChild(Days);
        buttonContainer.appendChild(Weeks);

        contextMenu.appendChild(buttonContainer);

        // Create a container div for input
        const InputBox = document.createElement('div');
        InputBox.className = 'InputBox';
        const daysHeading = document.createElement('span');
        daysHeading.className = 'days-heading';
        daysHeading.textContent = 'Add Days';

        // Create the input field
        const daysInput = document.createElement('input');
        daysInput.type = 'number';
        daysInput.className = 'days-input';
        daysInput.placeholder = 'Enter days';

        // Create the Apply button (disabled by default)
        const applyButton = document.createElement('button');
        applyButton.className = 'apply-button';
        applyButton.textContent = 'Apply';
        applyButton.disabled = true;

        // Enable the Apply button if valid input is entered
        daysInput.addEventListener('input', () => {
            const inputValue = parseInt(daysInput.value, 10);
            applyButton.disabled = isNaN(inputValue) || inputValue <= 0;
        });

        applyButton.addEventListener('click', () => {
            contextMenu.remove(); // Remove the menu after action
            bulkEventsAssignment(eventObj.id, isWeekDays, parseInt(daysInput.value, 10) || 0);
        });

        // Add toggle functionality to the "Days" button
        Days.addEventListener('click', () => {
            isWeekDays = false;

            Days.classList.toggle('days-selected');
            if (Days.classList.contains('days-selected')) {
                Weeks.classList.remove('days-selected');
                daysHeading.textContent = 'Add Days';
                daysInput.placeholder = 'Enter days';
                daysInput.value = '';
            }
        });

        // Add toggle functionality to the "Weeks" button
        Weeks.addEventListener('click', () => {
            isWeekDays = true;

            Weeks.classList.toggle('days-selected');
            if (Weeks.classList.contains('days-selected')) {
                Days.classList.remove('days-selected');
                daysHeading.textContent = 'Add Weeks';
                daysInput.placeholder = 'Enter weeks';
                daysInput.value = '';
            }
        });

        // Append elements to the container
        InputBox.appendChild(daysHeading);
        InputBox.appendChild(daysInput);
        InputBox.appendChild(applyButton);

        // Append the container to the contextMenu
        contextMenu.appendChild(InputBox);

        // Add delete icon
        const deleteIcon = document.createElement('div');
        deleteIcon.className = 'delete-icon';
        const deleteImg = document.createElement('img');
        deleteImg.src = dlt;
        deleteImg.alt = 'Delete Icon';
        deleteImg.title = "Click to delete event";

        deleteIcon.appendChild(deleteImg);
        deleteIcon.appendChild(document.createTextNode('Delete Event'));
        deleteIcon.onclick = () => {
            const confirmDelete = window.confirm('Are you sure you want to delete this event?');
            if (confirmDelete) {
                refreshData('delete', eventObj);
                contextMenu.remove(); // Remove the menu after action
            }
        };

        contextMenu.appendChild(deleteIcon);

        // Append the context menu to the body
        document.body.appendChild(contextMenu);

        // Remove the menu if clicked outside
        const closeContextMenu = (e) => {
            if (!contextMenu.contains(e.target)) {
                contextMenu.remove();
                document.removeEventListener('click', closeContextMenu);
            }
        };

        document.addEventListener('click', closeContextMenu);
    };

    const bulkEventsAssignment = async (eventId, isWeek, addCoverage) => {
        try {
            // Validate inputs
            if (!eventId) {
                throw new Error("Event ID is required.");
            }
            if (typeof isWeek !== "boolean") {
                throw new Error("Invalid value for isWeek. Expected a boolean.");
            }
            if (typeof addCoverage !== "number") {
                throw new Error("Invalid value for addCoverage. Expected a number.");
            }

            // Prepare the payload
            const payload = {
                eventId: eventId,
                isWeek: isWeek,
                addCoverage: addCoverage,
                workingDays: [...new Set(workingDays.split(', ').map(day => day.trim()))]
            };

            // Make the API call
            const response = await postBulkEventsAssign(payload);

            // Check API response
            if (response?.data?.success) {
                const createdEvents = response.data.data;
                if (createdEvents === 0) {
                    openInfoNotification("No events were created."); // Notify Info 
                }
                else {
                    await initializeEventScheduler(groupId, selectedTimeZone, weekStartDateRef.current, weekEndDateRef.current); // Refresh event data
                    openSuccessNotification(`${createdEvents} events created successfully.`); // Notify success
                }
            } else {
                throw new Error(response?.data?.message || "Bulk event assignment failed.");
            }
        } catch (error) {
            // Log error for debugging
            console.error("Bulk events assignment error:", error);

            // Notify the user about the error
            openErrorNotification(error.message || "An unexpected error occurred.");
        } finally {
            // Clean up and refresh data
            removeChangeOrderPanel();
        }
    };

    // Rest cell
    const resetCell = (cell, fallbackText = '') => {
        cell.style.backgroundColor = '#fff'; // Default background
        cell.textContent = fallbackText;    // Optional fallback text
    };

    const formatTimeRange = function (startDate, endDate) {
        const options = { hour: 'numeric', minute: 'numeric' }; // 12-hour format
        const formatter = new Intl.DateTimeFormat('en-US', options);

        const startTime = formatter.format(new Date(startDate));
        const endTime = formatter.format(new Date(endDate));

        return `${startTime} - ${endTime}`;
    }

    const formatDate = function (date) {
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }

    const formatTimefromDate = (dateString, is12HrFormat) => {
        const date = new Date(dateString);

        // Extract hours and minutes
        const hours24 = date.getHours();
        const minutes = date.getMinutes();

        if (is12HrFormat) {
            // Convert to 12-hour time
            const hours12 = hours24 % 12 || 12;
            const period = hours24 >= 12 ? 'PM' : 'AM';
            return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
        } else {
            // Format 24-hour time (HH:mm)
            return `${hours24.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        }
    };

    const handleSchedulerUpdate_ = async (detail, isDrag = false) => {
        const { item, itemDateRange } = detail;
        if (itemDateRange.dateStart && itemDateRange.dateEnd) {
            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(itemDateRange.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Validation checking 
            if (itemDateRange.dateStart >= yesterdayStart) {
                item.dateStart = itemDateRange.dateStart;
                item.dateEnd = itemDateRange.dateEnd;
                item.isDrag = isDrag;
                refreshData('update', item);
            }
            else {
                toast.error(t("Pleasenote"), {
                    className: "toastError",
                });
                // Re-initialize the event scheduler after each successful action
                await initializeEventScheduler(groupid, selectedTimeZone, weekStartDateRef.current, weekEndDateRef.current);
            }
        }
    }

    const handleSchedulerUpdate = async (detail, isDrag = false) => {
        const { item, itemDateRange } = detail;

        if (itemDateRange.dateStart && itemDateRange.dateEnd) {
            // Calculate the difference between start and end date in hours
            const dateStart = new Date(itemDateRange.dateStart);
            const dateEnd = new Date(itemDateRange.dateEnd);
            const timeDifference = (dateEnd - dateStart) / (1000 * 60 * 60); // Difference in hours

            // Validation checking
            if (timeDifference >= 24) {   
                // Re-initialize the event scheduler after each unsuccessful action
                await initializeEventScheduler(
                    groupid,
                    selectedTimeZone,
                    weekStartDateRef.current,
                    weekEndDateRef.current
                );

                // Show error if the difference is 24 hours or more
                openErrorNotification("Start and end dates cannot exceed 24 hours");
                return false; // Exit the function
            }

            if (itemDateRange.dateStart >= yesterdayStart) {
                item.dateStart = itemDateRange.dateStart;
                item.dateEnd = itemDateRange.dateEnd;
                item.isDrag = isDrag;
                refreshData("update", item);
            } else {
                // Show error if the difference is 24 hours or more
                openErrorNotification("Event cannot plot yesterday's date.");

                // Re-initialize the event scheduler after each unsuccessful action
                await initializeEventScheduler(
                    groupid,
                    selectedTimeZone,
                    weekStartDateRef.current,
                    weekEndDateRef.current
                );

                return false; // Exit the function
            }
        }
    };

    const handleMouseOver = (event) => {
        // Check if the mouse is over an event element
        if (event.target.classList.contains('smart-scheduler-event-content')) {
            const content = event.target;
            const label = content.querySelector('.support-technician-name')?.textContent || 'No label';
            const time = content.querySelector('.support-small-time')?.textContent || 'No time';
            // const desc = content.querySelector('.desc')?.textContent || 'No desc';
            const timeZone = content.querySelector('.support-TimeZone')?.textContent || 'Not specified';
            const orderId = getOrderId(label);

            const tooltip = document.createElement('div');
            tooltip.className = 'event-tooltip';
            tooltip.innerHTML = `
            Technician : ${label.replace(/\d+/g, '').trim()}<br>
            Order Id : ${orderId}<br>
            Assigned Time : ${time}<br>          
            ${timeZone}
        `; // Use <br> for line breaks

            document.body.appendChild(tooltip);

            // Position the tooltip
            const rect = content.getBoundingClientRect();
            tooltip.style.left = `${rect.left}px`;
            tooltip.style.top = `${rect.bottom + 5}px`; // Position below the event
            tooltip.classList.add('visible');
        }
    };

    const getOrderId = (name) => {
        const text = name;

        // Extract the number from the text
        const number = text.match(/\d+/); // Matches one or more digits

        // Convert to a number type if needed
        const extractedNumber = number ? parseInt(number[0], 10) : null;
        return extractedNumber;
    }

    const handleCancel = () => {
        setIsEdit(false);
        setSelectedAdmin(""); // Reset selection if needed
    };

    const handleEdit = () => {
        setIsEdit(true);
    };

    // Remove tooltips when the mouse leaves the event area
    const handleMouseOut = (event) => {
        // Remove tooltips when mouse leaves the event area
        if (event.target.classList.contains('smart-scheduler-event-content')) {
            const tooltips = document.querySelectorAll('.event-tooltip');
            tooltips.forEach(tooltip => tooltip.remove());
        }
    };

    // Function to get the current time in EST
    const getESTTime = () => {
        const estTime = new Date(
            new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
        );
        return estTime;
    };

    const getGMTTime = () => {
        const gmtTime = new Date(
            new Date().toLocaleString('en-US', { timeZone: 'Etc/GMT' })
        );
        return gmtTime;
    };

    const handleShadeToggle = () => {
        setIsShadeActive(!isShadeActive);
    };

    const handleCurrentToggle = () => {
        setIsCurrentTimeIndicator(!isCurrentTimeIndicator);
    };

    const handle12HrToggle = () => {
        // Toggle the format
        setIs12HrFormat((prev) => !prev);

        // Reload or recalculate the technicians' data
        setTechnicians((prevTechnicians) =>
            prevTechnicians.map((tech) => ({
                ...tech
            }))
        );

        // Update time zone of the sites basd on selected time zone
        updateSitesWithTimeZone(supportedSites, !is12HrFormat);
    };

    const updateSitesWithTimeZone = (siteDetails, is12HrFormat) => {
        // Transform `siteDetails` based on the time format
        const updatedSites = siteDetails.map((site) => {
            return {
                ...site,
                startTime: is12HrFormat ? format12TimeZone(site.startTime) : format24TimeZone(site.startTime), // Adjust start time format
                endTime: is12HrFormat ? format12TimeZone(site.endTime) : format24TimeZone(site.endTime),     // Adjust end time format
                localStartTime: is12HrFormat ? format12TimeZone(site.localStartTime) : format24TimeZone(site.localStartTime),
                localEndTime: is12HrFormat ? format12TimeZone(site.localEndTime) : format24TimeZone(site.localEndTime),
            };
        });
        // Update the state for `siteDetails`
        setSupportedSites(updatedSites);
    }

    const { confirm } = Modal;

    const handleDelete = () => {
        confirm({
            title: t("Areyousureyouwanttodeletefallbackperson"),
            okText: t("Yes"),
            okType: 'danger',
            cancelText: t("No"),
            onOk() {
                deleteFallBack()
            },
            onCancel() {
            },
        });
    }

    const deleteFallBack = async () => {
        let successMessage;
        let errorMessage;
        try {
            const response = await deletefallback(fallbackId);
            successMessage = t("Fallbackhasbeensuccessfullyremoved")
            errorMessage = t("Fallbacknotfound")
            if (response.data.success) {
                toast.success(successMessage, {
                    className: "toastSuccess",
                });
            } else {
                toast.error(errorMessage, {
                    className: "toastError",
                });
            }
            setFallbackId(0)
            setIsEdit(false)
            setSelectedAdmin("")
        }
        catch (err) {
            console.error("Error:", err);

        }
    }

    const format12TimeZone = (timeSpan) => {
        // Check if timeSpan is null or invalid
        if (!timeSpan) return ""; // Return a fallback value if timeSpan is invalid

        // Parse the timeSpan string (HH:mm:ss format)
        const [hours, minutes] = timeSpan.split(":").map(Number);

        // Check for invalid time format
        if (isNaN(hours) || isNaN(minutes)) return "Invalid Time"; // Handle invalid format

        // Determine the period (AM/PM)
        const period = hours >= 12 ? "pm" : "am";

        // Format hours for 12-hour clock
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return formatted time
        return `${formattedHours}${formattedMinutes !== "00" ? `:${formattedMinutes}` : ""}${period}`;
    };

    const format24TimeZone = (formattedTime) => {
        // Check if formattedTime is null or invalid
        if (!formattedTime) return ""; // Return a fallback value if invalid

        // Regular expression to match the 12-hour format (e.g., 3:15pm or 12am)
        const match = formattedTime.match(/^(\d{1,2})(?::(\d{2}))?(am|pm)$/i);

        // Handle invalid format
        if (!match) return "Invalid Time";

        // Extract hours, minutes, and period
        let [_, hours, minutes = "00", period] = match;
        hours = Number(hours);
        minutes = Number(minutes);

        // Validate hours and minutes
        if (isNaN(hours) || isNaN(minutes) || hours < 1 || hours > 12 || minutes < 0 || minutes > 59) {
            return "Invalid Time";
        }

        // Convert hours to 24-hour format
        if (period.toLowerCase() === "pm" && hours !== 12) {
            hours += 12;
        } else if (period.toLowerCase() === "am" && hours === 12) {
            hours = 0;
        }

        // Format hours and minutes into HH:mm:ss
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return formatted time
        return `${formattedHours}:${formattedMinutes}`;
    };

    // Function to get the first two letters from the userName
    const getUserInitials = (userName) => {
        const nameParts = userName.split(' '); // Split the name into words
        const initials = nameParts
            .map(part => part.charAt(0).toUpperCase()) // Get first letter of each word
            .join(''); // Join them to form the initials
        return initials.slice(0, 2); // Return the first two initials (e.g., "SS" for "Saunak Saha")
    };

    //sort working Days By Week Order
    const sortDaysByWeekOrder = (days) => {
        const weekOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        return days.sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));
    };

    // Set the cell background color to red for hours between startTime and endTime
    const parseCellTime = (timeStr) => {
        // Check if the time string includes 'am' or 'pm'
        const isAM = timeStr.toLowerCase().includes('am');
        const isPM = timeStr.toLowerCase().includes('pm');

        // If it's a string with 'am' or 'pm', remove it and split it into hour and minutes
        let time = timeStr.replace(/(am|pm)/i, '').trim();
        let [hour, minute] = time.split(':').map(num => parseInt(num));

        // Convert to 24-hour format
        if (isAM && hour === 12) {
            hour = 0; // 12 AM is 00:00
        } else if (isPM && hour !== 12) {
            hour += 12; // Convert PM hours to 24-hour format
        }

        return hour;
    };

    // Get Minutes From Time
    function getMinutesFromTime(time) {
        // Split the time string by ":"
        const [hours, minutes, seconds] = time.split(":");

        // Return the minutes as an integer
        return parseInt(minutes, 10);
    }

    const checkAvailability = (date) => {
        // Convert input date to a Date object
        const targetDate = new Date(date);

        // Check if the date falls within any event range
        return events.some(event => {
            const { dateStart, dateEnd } = event;

            // Convert event start and end dates to Date objects if they are strings
            const startDate = new Date(dateStart);
            const endDate = new Date(dateEnd);

            // Check if targetDate is within the range
            return targetDate >= startDate && targetDate <= endDate;
        });
    };

    // handle Date Change on Schedular 
    const handleDateChange = (event) => {

        const scheduler = schedulerRef.current;
        if (scheduler && event.detail) {
            // Get the provided dates
            const startDate = dayjs(event.detail.oldValue);
            const endDate = dayjs(event.detail.value);

            // Check if the user is moving forward or backward
            const isMovingForward = endDate.isAfter(startDate);

            // Align start and end dates to fixed week ranges
            let alignedStartDate;
            let alignedEndDate;

            if (isMovingForward) {
                // If moving forward, adjust to next week's start and end date
                alignedStartDate = startDate.endOf('week').add(1, 'day'); // Next week's start date (Monday)
                alignedEndDate = alignedStartDate.add(6, 'day'); // End of the week (Sunday)
            } else {
                // If moving backward, adjust to previous week's start and end date
                alignedEndDate = startDate.startOf('week').subtract(1, 'day'); // Previous week's end date (Sunday)
                alignedStartDate = alignedEndDate.subtract(6, 'day'); // Start of the week (Monday)
            }
            // Save the dates to useRef
            weekStartDateRef.current = alignedStartDate.format('YYYY-MM-DD');
            weekEndDateRef.current = alignedEndDate.format('YYYY-MM-DD');
            // Call initializeEventScheduler with the aligned dates
            initializeEventScheduler(
                groupId,
                null,
                alignedStartDate.format('YYYY-MM-DD'),
                alignedEndDate.format('YYYY-MM-DD')
            );
        } else {
            console.error("Event details are not available.");
        }
    };
    // #endregion

    // #region Handle Site  
    // On Select site
    const handleSelectSite = (index) => {
        debugger;

        // Check if the selected event is the same as the current one
        addedListeners.current.cleanup();
        setSelectedSite(index);

        // Ensure the siteDetails object exists for the given index
        const siteDetails = supportedSites[index];
        setSelectedSiteName(siteDetails.siteName);

        // Set start and end times from the selected site's details
        setSiteStartTime(siteDetails.startTime);
        setSiteEndTime(siteDetails.endTime);
        setWorkingDays(siteDetails.selectedDays);
        setSelectedTimeZone(siteDetails.timeZone);

        const getTechnicians = allTechnicians.filter(x => x.timeZone == siteDetails.timeZone);
        setTechnicians(getTechnicians);

        filterEventsByTimeZone(events, siteDetails.timeZone);
    };

    // On Deselect Site
    const handleDeselectSite = async () => {
        try {
            setLoading(true);
            // Use the appropriate group ID for API calls
            const effectiveGroupId = groupid != null ? groupid : groupId;

            // Perform asynchronous operations
            await getAllActiveSitesBySupportGroupId(effectiveGroupId);
            await getAllAssignedTechnician(effectiveGroupId);
            await initializeEventScheduler(effectiveGroupId);
        } catch (error) {
            console.error("Error during site deselection:", error);
        }
        finally {
            setLoading(false);
        }
    };

    // Clear Selections
    const clearSelection = async () => {
        // Update state synchronously
        setSelectedSite(-1);
        setSelectedSiteName(null);
        setSelectedTimeZone("");
    };
    // #endregion

    // #region Random calendar colors 
    const colorAssignments = new Map(); // Map to store user-color assignments
    let colorIndex = 0;
    const getUniqueColor = (id) => {
        if (!colorAssignments.has(id)) {
            if (id == 0) {
                colorIndex = 0;
            }

            // Assign the next available color
            colorAssignments.set(id, colors[colorIndex]);
            colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
        }
        return colorAssignments.get(id);
    };

    const colors = ['#007bff', '#28a745', '#dc3545', '#ffc107', '#17a2b8', '#343a40', '#6f42c1', '#20c997', '#e83e8c'];

    function getAbbreviation(timezone) {
        return timezone ? timezone.split(" ").map(word => word[0]).join("") : "";
    }
    // #endregion

    // #region Schedular Update
    const handleDragEnd = (event) => handleSchedulerUpdate(event.detail, true);
    const handleResizeEnd = (event) => handleSchedulerUpdate(event.detail);
    const handleItemUpdate = (event) => refreshData('update', event.detail.item);
    const handleItemRemove = (event) => refreshData('delete', event.detail.item);
    // #endregion

    // #region Handle Change
    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    // Handle clicking the icon
    const handleIconClick = () => {
        setShowDiv((prev) => !prev);
    };

    // Close the hover-div when clicking outside
    const handleClickOutside = (event) => {
        if (iNotificationRef.current && !iNotificationRef.current.contains(event.target)) {
            setShowDiv(false);
        }
    };

    // Add event listener to detect outside clicks
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleMaximize = () => {
        setIsMaximized(!isMaximized);
    };
    // #endregion

    return (
        <div>
            <Title title="Support Technician"></Title>
            <>
                {loading ? <Loader /> : ""}
                <Layout>

                    <div className={`content ${isMaximized ? 'maximized' : ''}`}
                        style={{
                            flex: 1,
                            backgroundColor: '#585858',
                            transition: 'all 0.3s ease',
                            position: isMaximized ? 'fixed' : 'relative',
                            top: isMaximized ? 0 : 'auto',
                            left: isMaximized ? 0 : 'auto',
                            width: isMaximized ? '100%' : 'auto',
                            height: isMaximized ? '100%' : 'auto',
                            zIndex: isMaximized ? 9999 : 'auto',
                            margin: isMaximized ? 0 : 'auto',
                            overflow: isMaximized ? 'auto' : 'visible',
                        }}
                    >
                        <div style={styles.supportcontainer} className={isCollapsed ? "supportcontainer sidebar-remove" : "supportcontainer"}>

                            <CoverageModal isOpen={isCoverageModalOpen} onClose={colseCoverageModal} coverageDate={dateOfCoverage} is12HrFormat={is12HrFormat} />

                            <div className="support-Cont-child" style={{ ...styles.navbar, width: isCollapsed ? '80px' : '22%', minWidth: isCollapsed ? '80px' : '22%' }}>

                                <div style={styles.header}>
                                    {!isCollapsed && <h4>Support Calendar</h4>}

                                    <div className="support-header-right">
                                        {!isCollapsed && (
                                            <div className="dropdown setting-dropdown-menu">
                                                <button
                                                    className="dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    title="Settings"
                                                >
                                                    <img src={setting} alt="Settings" />
                                                </button>

                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    {[
                                                        {
                                                            label: "Time Format (12 Hr)",
                                                            isActive: is12HrFormat,
                                                            toggleHandler: handle12HrToggle,
                                                        },
                                                        {
                                                            label: "Current Time Indicator",
                                                            isActive: isCurrentTimeIndicator,
                                                            toggleHandler: handleCurrentToggle,
                                                        },
                                                        {
                                                            label: "Shade Until Current Time",
                                                            isActive: isShadeActive,
                                                            toggleHandler: handleShadeToggle,
                                                        },
                                                    ].map((toggleItem, index) => (
                                                        <li key={index}>
                                                            <div className="toggle-container">
                                                                <div className="toggle-label">{toggleItem.label}:</div>
                                                                <div
                                                                    className={`toggle-btn ${toggleItem.isActive ? "active" : ""}`}
                                                                    onClick={toggleItem.toggleHandler}
                                                                >
                                                                    <div className="indicator"></div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}

                                        {!isCollapsed && <div class="dropdown setting-dropdown-menu">
                                            <Tooltip title="Contracted Site Coverage">
                                                <button
                                                    className="dropdown-toggle"
                                                    type="button"
                                                    onClick={() => openCoverageModal()}
                                                >
                                                    <EqualizerIcon className="equalizer-icon" />
                                                </button>
                                            </Tooltip>
                                        </div>}

                                        <Tooltip title={isCollapsed ? t("OpenMenu") : t("Close Menu")}>
                                            <button onClick={toggleCollapse} className="support-technicians-toggleButton">
                                                {isCollapsed ? <MenuOpenIcon fontSize="small" /> : <MenuIcon fontSize="small" />}
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>

                                {!isCollapsed && (
                                    <>
                                        <div className="support-technicians-tabs">
                                            <div className="d-flex align-items-center">
                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                                                            id="home-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#home"
                                                            type="button" role="tab"
                                                            aria-controls="home"
                                                            aria-selected={activeTab === "home"}
                                                            onClick={() => handleTabChange("home")}
                                                        >
                                                            {supportGroupName}
                                                        </button>
                                                    </li>

                                                    <li class="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
                                                            id="profile-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#profile"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="profile"
                                                            aria-selected={activeTab === "profile"}
                                                            onClick={() => handleTabChange("profile")}
                                                        >Associated Sites</button>
                                                    </li>
                                                </ul>

                                                <div className="ms-3">
                                                    {activeTab === "home" && (
                                                        <Tooltip title="click to open support group">
                                                            <a
                                                                className="btn btn-primary addtech-btn m-0"
                                                                href={`/CallOrder?group=${encodeURIComponent(supportGroupName)}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <GroupIcon fontSize="10px" />
                                                            </a>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>

                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <Technician
                                                        technicians={technicians}
                                                        handleDragStart={handleDragStart}
                                                        is12HrFormat={is12HrFormat}
                                                        loading={loading} />
                                                </div>

                                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    <div className="event-container">
                                                        <div className="event-list">
                                                            {supportedSites && supportedSites.length > 0 ? (
                                                                supportedSites.map((admin, index) => (
                                                                    <div
                                                                        className={`event ${selectedSite !== null && selectedSite === index ? 'selected' : ''}`}
                                                                        key={index}
                                                                        onClick={() => handleSelectSite(index)} // Add onClick handler
                                                                    >
                                                                        <div className="event-row">
                                                                            <div className="event-name">{admin.siteName}</div>
                                                                            <div className="time-zone">{admin.timeZone || "Unknown Time Zone"}</div>
                                                                        </div>

                                                                        <div className="support-details-row">
                                                                            {admin.startTime && admin.endTime ? (
                                                                                <>
                                                                                    <div className="time-slot operating-hours">
                                                                                        Operating Hrs. (GMT) : {admin.startTime} - {admin.endTime}
                                                                                        <br />
                                                                                        Local Hrs. ({getAbbreviation(admin.timeZone)}) : {admin.localStartTime} - {admin.localEndTime}
                                                                                    </div>


                                                                                </>
                                                                            ) : (
                                                                                <div className="time-slot">No Operating hours</div>
                                                                            )}

                                                                            <div className="support-user-circle-container">
                                                                                {admin.users?.map((user, userIndex) => (
                                                                                    <div
                                                                                        className="support-user-circle"
                                                                                        style={{ backgroundColor: getUniqueColor(user.userId) }}
                                                                                        key={userIndex}
                                                                                        title={user.userName}
                                                                                    >
                                                                                        {getUserInitials(user.userName)}
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>

                                                                        <div className="support-details-row weeklyHOurs">
                                                                            {/* Weekday circles */}
                                                                            {admin.selectedDays ? (
                                                                                <div className="weekday-container">
                                                                                    {sortDaysByWeekOrder(admin.selectedDays.split(", "))
                                                                                        .map((day, dayIndex) => (
                                                                                            <div
                                                                                                key={dayIndex}
                                                                                                className="weekday-circle"
                                                                                                title={day}
                                                                                            >
                                                                                                {day[0]}
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="time-slot">No Working Days</div>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="no-data-tech">No sites are associated</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="settings-container d-none">
                                            {/* #LANGUAGE_UPDATE */}
                                            <div class="settings-title">Settings</div>

                                            <div className="toggle-container">
                                                <div className="toggle-label">Time Format (12 Hr) : </div>
                                                <div
                                                    className={`toggle-btn ${is12HrFormat ? "active" : ""}`}
                                                    onClick={handle12HrToggle}
                                                >
                                                    <div className="indicator"></div>
                                                </div>
                                            </div>

                                            <div className="toggle-container">
                                                <div className="toggle-label">Current Time Indicator : </div>
                                                <div
                                                    className={`toggle-btn ${isCurrentTimeIndicator ? "active" : ""}`}
                                                    onClick={handleCurrentToggle}
                                                >
                                                    <div className="indicator"></div>
                                                </div>
                                            </div>

                                            <div className="toggle-container">
                                                <div className="toggle-label">Shade Until Current Time : </div>
                                                <div
                                                    className={`toggle-btn ${isShadeActive ? "active" : ""}`}
                                                    onClick={handleShadeToggle}
                                                >
                                                    <div className="indicator"></div>
                                                </div>
                                            </div>

                                            {/* #LANGUAGE_UPDATE */}
                                            <div className="support-technicians-note">
                                                <span className="bold">Note :</span> Click on username to reorder the technicians.
                                            </div>

                                            <div className="support-technicians-note">
                                                <span className="bold">Note :</span> Right-click on an event to delete it.
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="d-flex flex-column clock-support">
                                <div className="d-flex flex-wrap align-items-center w-100 justify-content-between gap-2 fallback-heading">
                                    <div className="fallback-panel flex-1">
                                        <h3>Fallback</h3>

                                        {fallbackId == 0 || isEdit ? (
                                            <>
                                                <div className="fallback-dropdown">
                                                    <select
                                                        id="userSelect"
                                                        value={selectedAdmin}
                                                        onChange={(e) => setSelectedAdmin(e.target.value)}
                                                    >
                                                        <option value="">-- Select a User --</option>
                                                        {supperAdmins.map(admin => (
                                                            <option key={admin.userId} value={admin.userId}>
                                                                {`${admin.lastName} ${admin.firstName}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                {!isEdit ? (
                                                    <button className="fallback-assign-btn text-white btn-primary" onClick={assignFallback}>
                                                        {t("Assign")}
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button className="fallback-edit-btn text-white btn-primary" onClick={assignFallback}>
                                                            {t("Update")}
                                                        </button>
                                                        <button className="fallback-edit-btn text-white btn-primary" onClick={handleDelete} >
                                                            {t("Delete")}
                                                        </button>
                                                        <button className="fallback-edit-btn text-white btn-primary" onClick={handleCancel} >
                                                            {t("Cancel")}
                                                        </button>
                                                    </>
                                                )}
                                            </>

                                        ) : (
                                            <div class="fallback-info">
                                                <span class="fallback-name">{fallbackName}</span>
                                                <button className="fallback-edit-btn text-white btn-primary" onClick={handleEdit}>
                                                    {t("Edit")}
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div className="time-zone d-flex gap-2 align-items-center">
                                        <div className="clock-wrap">
                                            <ShoppingBagIcon className="shopping-bagIcon" />
                                            {selectedSiteName ? (
                                                <>
                                                    <span className="selected-site">
                                                        Selected Site : {selectedSiteName} {siteStartTime ? `[Start: ${siteStartTime} - End: ${siteEndTime}]` : ''}
                                                    </span>

                                                    <button
                                                        className="fallback-edit-btn text-white btn-primary"
                                                        onClick={() => {
                                                            clearSelection();
                                                            handleDeselectSite();
                                                        }}
                                                    >
                                                        Remove
                                                    </button>

                                                </>
                                            ) : (
                                                <span>
                                                    All Sites {siteStartTime ? `[Start: ${siteStartTime} - End: ${siteEndTime}]` : ''}
                                                </span>
                                            )}
                                        </div>

                                        <div style={{ position: "relative", display: "inline-block" }}>

                                            <div className="attention-circle" title="Click to view notifications" >
                                                <CampaignIcon className="i-icon" onClick={handleIconClick} />
                                            </div>

                                            {showDiv && (
                                                <div className="hover-div" ref={iNotificationRef}>
                                                    <div className="hover-div-header">ATTENTION</div>

                                                    <p>1. All events on the calendar are scheduled in GMT.</p>
                                                    <p>2. Events are added based on site coverage hours, technician working hours, and the corresponding time zones.</p>
                                                    <p>3. Events cannot be added to past dates.</p>
                                                    <p>4. Multiple technicians can be scheduled on the same date.</p>
                                                    <p>5. The same technician cannot be scheduled more than once on the same date.</p>
                                                    <p>6. Site coverage hours can be managed on the site edit page.</p>
                                                    <p>7. Technician working hours can be managed on the user edit page.</p>
                                                    <p>8. The options to delete an event or add bulk days for events appear when right-clicking on an event.</p>
                                                    <p>9. In the menu, you can check the coverage time for a particular site by clicking on the associated site.</p>
                                                    <p>10. A button on the left side allows you to view the site coverage when clicked.</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="time-zone">
                                        <button
                                            onClick={toggleMaximize}
                                            className="maximize-button"
                                            title={!isMaximized ? 'Click to Maximize' : 'Click to Restore'}>
                                            {!isMaximized ? <HomeMaxIcon /> : <RepartitionIcon />}
                                        </button>

                                        <div className="clock-wrap"><img src={clockIcon} alt="" />
                                            <Clock is12HrFormat={is12HrFormat} />
                                        </div>
                                    </div>
                                </div>

                                <div style={styles.scheduler} className="scheduler-calendar">
                                    <Scheduler
                                        ref={schedulerRef}
                                        currentTime={currentTime}
                                        view="week"
                                        id="scheduler"
                                        //onDragEnd={handleDragEnd}
                                        notificationInterval={5000}
                                        onResizeEnd={handleResizeEnd}
                                        onItemUpdate={handleItemUpdate}
                                        onItemRemove={handleItemRemove}
                                        currentTimeIndicator={isCurrentTimeIndicator}
                                        shadeUntilCurrentTime={isShadeActive}
                                        //timeZone='Greenwich Mean Time'
                                        eventTemplate={eventTemplate}
                                        timelineHeaderFormatFunction={timelineHeaderFormatFunction}
                                        resources={resources}
                                        statuses={statuses.length ? statuses : [{ label: "Loading...", value: "loading" }]}
                                        // Disable all creation-related features
                                        enableEventCreation={false}
                                        disableContextMenu={true}

                                        // Prevent cell clicks from creating events
                                        onCellClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            return false;
                                        }}
                                        // Additional props to disable interactions
                                        disableSelection={true}
                                        disableWindowEditor={true}
                                        hideAllDay={true}
                                        views={["week", "month", "day"]}
                                        cellTemplate={cellTemplate}
                                        hourFormat="numeric"
                                        autoHeightAllDayCells

                                        onDragStart={(event) => {
                                            debugger;
                                            event.preventDefault();
                                            event.stopPropagation();
                                            return false;
                                        }}
                                        onDateChange={handleDateChange}
                                    //hourStart={3}
                                    //hourEnd={18}
                                    //nonworkingDays={[0,6]}
                                    //nonworkingHours={[[9, 14],[16, 18]]}
                                    //hideNonworkingWeekdays={true}
                                    //timelineDayScale={'halfHour'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Toaster />
                </Layout>
            </>
        </div>
    )
}

// #region Style #TODO - Refactor needed
const styles = {
    supportcontainer: {
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '0',

    },

    navbar: {
        transition: 'width 0.3s ease',  // Smooth transition for collapsing
        padding: '35px 20px 0',  // Padding inside the navbar

        overflow: 'hidden',  // Hide overflow content when collapsed
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 0 0 10px', // Rounded corners on the left side
        maxHeight: 'calc(100vh - 15px)',
        height: '100%',
        overflowY: 'scroll !important',


    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',  // Align items to the start and end
        marginBottom: '15px',  // Add space below the header
    },
    scheduler: {
        width: '100%',  // Adjust width based on collapsed navbar width (80px when collapsed) 
        padding: '20px',  // Add padding around the scheduler
        borderRadius: '0 10px 10px 0', // Rounded corners on the right side

    }
};
// #endregion

export default connect()(SupportTechnician);