import React, { Component, useEffect, useState } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { PrivateRoute } from './Routes/PrivateRoute';
import Login from './components/Authentication/Login'
import ForgotPassword from './components/Authentication/ForgotPassword';
import Dashboard from './components/Dashboard/Index';
import DashboardData from './components/Dashboard/Dashboard';
import AboutUs from './components/Dashboard/AboutUs';
import Access from './components/Accessibility/Access';
/*import ContactSection from './components/Dashboard/Contact';*/
import Themes from './components/ThemeSection/Themes';
import EmailVerification from './components/Authentication/EmailVerification';
import NotFound from './components/Authentication/NotFound';
import NotAuthorised from './components/Authentication/NotAuthorised';
import Home from './components/Dashboard/Home';
import ChangeOldPassword from './components/ChangePassword/ChangeOldPassword';
import EmailConfirmation from './components/Authentication/EmailConfirmation';
import CompanyIndex from './components/Company/Index'
import SiteIndex from './components/Site/Index'
import SupportIndex from './components/Support/Support'
import SupportTechnician from './components/Support/SupportTechnician'
import SupportLogs from './components/Support/SupportLogs'
import SupportGroupLog from './components/Support/SupportGroupLog'
import SupportCalendar from './components/Support/SupportCalendar'
import MyCalendar from './components/Support/MyCalendar'
import SupportReport from './components/Support/SupportReport'
import SupportGroupIndex from './components/Support/SupportGroup'
import MappingIndex from './components/CompanyMapping/Index'
import SubCompanyIndex from './components/CompanyMapping/SubCompanyIndex'
import SubCompanyAdd from './components/CompanyMapping/SubCompanyAdd'
import AddMapping from './components/CompanyMapping/Add'
import AddCompany from './components/Company/CompanyAdd'
import AddSite from './components/Site/SiteAdd'
import AddProject from './components/Projects/ProjectsAdd'
import UserIndex from './components/User/Index'
import UserAccessLevel from './components/User/UserAccessLevel'
import AccessLevel from './components/User/AccessLevel'
import AddUser from './components/User/UserAdd'
import UserCompanyIndex from './components/UserCompany/Index'
import UserSiteIndex from './components/UserSite/Index'
import AddUserCompany from './components/UserCompany/Add'
import AddUserSite from './components/UserSite/Add'
import Assign from './components/Assign/index';
import Create from './components/Assign/create';
import EditAssign from './components/Assign/edit';
import UserLogs from './components/Settings/UserLogs';
import BannerIndex from './components/Settings/Banner';
//import CallOrderIndex from './components/Settings/CallOrder';
import NotificationIndex from './components/Settings/Notification';
import VersionIndex from './components/Settings/Version';
import { connect, useDispatch, useSelector } from 'react-redux';
/*import './custom.css'*/
import axios from "axios";
import * as url from "./api/uri_helper";
import TekacontrollerIndex from './components/TEKaController/Index';
import ProjectsIndex from './components/Projects/Index';
import AddTekacontroller from './components/TEKaController/AddTEKaController';
import UserSessions from './components/Settings/UserSessions';
import { fetchRefreshTime, fetchIdleTime, fetchLogout } from "./Redux/Api/fetch.api";
import GetClientIPAddress from "./api/GetClientIP";
import Modal from "react-bootstrap/Modal";
import '../src/custom.css';
import MyProfile from './components/MyProfile/MyProfile';
import Map from "./components/Site/Map"
import SiteMap from "./components/Dashboard/SiteMap"
import logDetails from './components/Support/LogDetailsDisplay'
import "./i18n/i18n";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Import the idle icon
import Subscription from './components/Support/Subscription'
import Parts from './components/Parts/Parts';
import PartsDeviceMapping from './components/Parts/PartsDeviceMapping';

// login - get roleName
// pass to the app.js

const useIdleDetection = (idleTime) => {

    const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
        let timeoutId;

        const resetTimer = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setIsIdle(true);
            }, idleTime);
        };

        const handleUserActivity = () => {
            setIsIdle(false);
            resetTimer();
        };

        const initializeIdleTimer = () => {
            resetTimer();
            document.addEventListener('mousemove', handleUserActivity);
            document.addEventListener('keydown', handleUserActivity);
        };

        initializeIdleTimer();

        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keydown', handleUserActivity);
            clearTimeout(timeoutId);
        };
    }, [idleTime]);

    return isIdle;
};

const App = () => {
    const { t } = useTranslation('routing');
    const [currentPageURL, setCurrentPageURL] = useState('');
    const loggedInEmailId = localStorage.getItem('emailid');
    const [idleTime, setIdleTime] = useState(300000);
    const [showModal, setShowModal] = useState(false);
    const remmeberMe = localStorage.getItem('isChecked');
    const tekaVerseToken = localStorage.getItem('tekaVerseToken');

    const Logout = () => {

        logoutUser();
        if (!remmeberMe) {
            localStorage.removeItem('userName')
            localStorage.removeItem('emailid')
            localStorage.removeItem('password')
            localStorage.removeItem('isChecked')
            localStorage.removeItem('tekaVerseToken');
            window.location.reload(true);
        }
        localStorage.removeItem('tekaVerseToken');
        window.location.replace("/");
    };

    const logoutUser = () => {
        //IsLogoutOrRefresh = true -- Refresh
        //IsLogoutOrRefresh = false -- Logout

        fetchLogout(loggedInEmailId, false, true)
            .then(response => {
            })

            .catch((err) => {
                console.log("Err: ", err);
            });

    }

    const Close = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setCurrentPageURL(window.location.pathname);
        if (loggedInEmailId != null) {
            fetchRefreshTime(loggedInEmailId)
                .then(response => {
                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        }
    }, []);

    useEffect(() => {

        if (tekaVerseToken) {
            fetchIdleTime()
                .then(response => {

                    setIdleTime(response.data.data.data);
                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        }
    }, [tekaVerseToken]);

    const isIdle = useIdleDetection(idleTime);

    useEffect(() => {
        if (isIdle && !showModal && tekaVerseToken && currentPageURL !== "/") {
            setShowModal(true);
        }
    }, [isIdle, showModal]);



    return (
        <div className="App" >
            <Modal show={showModal} onHide={Close} backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="idle-modal-header-custom ">
                    <AccessTimeIcon className="idle-modal-header-icon" />
                    <Modal.Title>{t("IdleTimeout")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t("Youhavebeenidleforthelast")}{" "}
                        {idleTime < 60000
                            ? `${Math.floor(idleTime / 1000)} ${t("seconds")}`
                            : `${Math.floor(idleTime / 60000)} ${t("minutes")}`
                        }
                    </p>
                    <p>{t("clickMessage")}</p>
                </Modal.Body>
                <Modal.Footer className="idle-modal-footer-custom">
                    <button type="button" className="btn btn-primary idle-modal-button-logout" onClick={Logout}>
                        {t("Logout")}
                    </button>
                    <button type="button" className="btn btn-light idle-modal-button-continue" onClick={Close}>
                        {t("Continue")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Router>
                <Switch>
                    <Route exact path='/' component={Login} />
                    <Route exact path="/EmailVerification" component={EmailVerification} />
                    <Route exact path="/EmailConfirmation" component={EmailConfirmation} />
                    <Route exact path="/Workitem/Edit/:id" component={EditAssign} />
                    <PrivateRoute exact path="/Themes" component={Themes} />
                    <PrivateRoute exact path="/Workitem" component={Assign} />
                    <PrivateRoute exact path="/Access" component={Access} />
                    <PrivateRoute exact path="/Workitem/Create/:type/:supportid?/:companyid?/:siteid?" component={Create} />
                    {/*  <Route exact path="/Workitem/Edit/:id" component={EditAssign} />*/}
                    <PrivateRoute exact path="/Apps" component={Dashboard} />
                    <PrivateRoute exact path="/AboutUs" component={AboutUs} />
                    {/* <PrivateRoute exact path="/Support" component={ContactSection} />*/}
                    <PrivateRoute exact path="/Support" component={SupportIndex} />
                    <PrivateRoute exact path="/supportlogs" component={SupportLogs} />
                    <PrivateRoute exact path="/supportgrouplogs" component={SupportGroupLog} />
                    <PrivateRoute exact path="/supportcalendar" component={SupportCalendar} />
                    <PrivateRoute exact path="/MyCalendar" component={MyCalendar} />
                    <PrivateRoute exact path="/SupportReport" component={SupportReport} />
                    <PrivateRoute exact path="/SupportTechnician" component={SupportTechnician} />
                    {/* <PrivateRoute exact path="/SupportLogs/:siteId/:companyId/:appId" component={SupportLogs} />*/}
                    <PrivateRoute exact path="/Home" component={Home} />
                    <PrivateRoute exact path="/AllSites" component={SiteMap} />
                    <PrivateRoute exact path="/ChangePassword" component={ChangeOldPassword} />
                    <Route exact path="/UpdatePassword/:emailId" component={ForgotPassword} />
                    <PrivateRoute exact path="/UserCompany" component={UserCompanyIndex} />
                    <PrivateRoute exact path="/UserSite" component={UserSiteIndex} />
                    <PrivateRoute exact path="/UserLogs" component={UserLogs} />
                    <PrivateRoute exact path="/Banner" component={BannerIndex} />
                    <PrivateRoute exact path="/CallOrder" component={SupportGroupIndex} />
                    <PrivateRoute exact path="/Notifications" component={NotificationIndex} />
                    <PrivateRoute exact path="/Version" component={VersionIndex} />
                    <PrivateRoute exact path="/Company/Edit/:id" component={AddCompany} />
                    <PrivateRoute exact path="/Site/Edit/:id" component={AddSite} />
                    <PrivateRoute exact path="/Map/:id" component={Map} />
                    <PrivateRoute exact path="/CompanyMapping/Edit/:id" component={SubCompanyAdd} />
                    <PrivateRoute exact path="/Mapping/Edit/:id" component={AddMapping} />
                    <PrivateRoute exact path="/UserCompany/Add" component={AddUserCompany} />
                    <PrivateRoute exact path="/UserSite/Add" component={AddUserSite} />
                    <PrivateRoute exact path="/UserCompany/Edit/:id" component={AddUserCompany} />
                    <PrivateRoute exact path="/UserSite/Edit/:id" component={AddUserSite} />
                    <PrivateRoute exact path="/Add/User" component={AddUser} />
                    <PrivateRoute exact path="/User" component={UserIndex} />
                    <PrivateRoute exact path="/User/Access/:userId" component={UserAccessLevel} />
                    <PrivateRoute exact path="/UserAccess" component={AccessLevel} />
                    <PrivateRoute exact path="/User/Edit/:id" component={AddUser} />
                    <PrivateRoute exact path="/Site" component={SiteIndex} />
                    <PrivateRoute exact path="/Projects" component={ProjectsIndex} />
                    <PrivateRoute exact path="/Project/Add" component={AddProject} />
                    <PrivateRoute exact path="/Project/Edit/:id" component={AddProject} />
                    <PrivateRoute exact path="/Mapping" component={MappingIndex} />
                    <PrivateRoute exact path="/Company" component={CompanyIndex} />
                    <PrivateRoute exact path="/SubCompanyMapping" component={SubCompanyIndex} />
                    <PrivateRoute exact path="/Mapping/Add" component={AddMapping} />
                    <PrivateRoute exact path="/Site/Add" component={AddSite} />
                    <PrivateRoute exact path="/Add/Company" component={AddCompany} />
                    <PrivateRoute exact path="/CompanyMapping/Add" component={SubCompanyAdd} />
                    <PrivateRoute exact path="/Tekacontroller" component={TekacontrollerIndex} />
                    <PrivateRoute exact path="/Tekacontroller/Add" component={AddTekacontroller} />
                    <PrivateRoute exact path="/Tekacontroller/Edit/:id" component={AddTekacontroller} />
                    <PrivateRoute exact path="/UserSessions" component={UserSessions} />
                    <PrivateRoute exact path="/Dashboard" component={DashboardData} />
                    <PrivateRoute exact path="/Parts" component={Parts} />
                    <PrivateRoute exact path="/PartsDeviceMapping" component={PartsDeviceMapping} />
                    <PrivateRoute exact path="/MyProfile" component={MyProfile} />
                    <PrivateRoute exact path="/LogDetails/:supportId" component={logDetails} />
                    <PrivateRoute exact path="/NotAuthorised" component={NotAuthorised} />
                    <PrivateRoute exact path="/Support/Subscription" component={Subscription} />
                    <Route component={NotFound} />

                </Switch>
            </Router>
        </div>
    )
};

export default App;