import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../../../src/custom.css";
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from "antd";
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import {
    fetchUsersWithRoles,
    fetchUserById,
    loginasuser,
    fetchUserRoledata,
    fetchAllRoles,
    fetchAllCompaniesdata,
    fetchUserSiteRelation,
    fetchUserSiteName
} from "../../Redux/Api/fetch.api";
import {
    getusersandroles,
    deleteuserdata,
} from "../../Redux/Actions/UsersActions";
import Add from "../../assets/images/Add.svg";
import Table, { TableProps } from "antd/lib/table";
import Title from "../Title";
import Trash_light from "../../assets/images/Trash_light.svg";
import updateIcon from "../../assets/images/updateIcon.svg";
import searchIcon from "../../assets/images/SearchIcon.svg";
//import emailIcon from "../../assets/images/emailicon.svg";
import emailIcon from "../../assets/images/EmailIconNew.svg";
import { Modal, Drawer, Button, Typography } from "antd";
import Layout from "../Layout";
import axios from "axios";
import * as url from "../../api/uri_helper";
import NotAuthorised from "../Authentication/NotAuthorised";
import {
    getuserroleandmenu,
    saveuserdetails,
    validateuserdata,
} from "../../Redux/Actions/UsersActions";
import { Menu, Dropdown } from "antd";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { getallroles } from "../../Redux/Actions/RolesActions";
import { getallcompanies } from "../../Redux/Actions/CompanyActions";
import { Input, Form, FormFeedback } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Tooltip, Switch } from "antd";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useTranslation } from "react-i18next";
import GroupIcon from "@mui/icons-material/Group";
import MapIcon from "@mui/icons-material/Map";
import { CloseCircleOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { User } from "oidc-client";
import { getusersitedata } from "../../Redux/Actions/UserSiteAction";
import { UserOutlined } from '@ant-design/icons'; // Use Ant Design icon for the user
import { getUserAccessLevels } from "../../Redux/Api/fetch.api";


const UserIndex = () => {
    const { t } = useTranslation("user");
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [drawerPageSize, setdrawerPagesize] = useState(10);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ["50", "100"],
            showSizeChanger: true,
        },
    });

    const [drawerptions, setdrawerOptions] = useState({
        pagination: {
            current: 1,
            pageSize: drawerPageSize,
            pageSizeOptions: ["10", "20", "50"],
            showSizeChanger: true,
        },
    });

    // const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState();
    const [totalDrawerIndex, setDrawerIndex] = useState();
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState();
    let history = useHistory();
    const loggedUserId = localStorage.getItem("UserId");
    const [searchClicked, setSearchClicked] = useState("");
    const [userName, setUserName] = useState("");
    const [roleName, setRoleName] = useState(null);
    const [sortCompany, setSortCompany] = useState("");
    const [isrolecheck, setIsRoleCheck] = useState(false);
    const [iscompanycheck, setIsCompanyCheck] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [name, setName] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [masterCompanies, setMasterCompanies] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedMasterCompanies, setSelectedMasterCompanies] = useState([]);
    const [isRoleReset, setIsRoleReset] = useState(false);
    const [isCompanyReset, setIsCompanyReset] = useState(false);
    const [isCloneUser, setIsCloneUser] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchCompany, setSearchCompany] = useState("");
    const [cloneModal, setCloneModal] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [selectedCountryCode, setSlectedCountryCode] = useState();
    const [phnumber, setPhnumber] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [roleId, setRoleId] = useState();
    const [allowSupportCall, setAllowSupportCall] = useState(false);
    const [isActive, setisActive] = useState(true);
    const [resetDisplay, setResetDisplay] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({ name: "", id: null });
    const [userSitesData, setUserSitesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    //handle drawer table change 
    const handleTableChange = (pagination) => {
        const updatedOptions = { ...drawerptions, pagination };
        setdrawerOptions(updatedOptions);

    };
    useEffect(() => {
        getRoleDetails();
        getalluserswithroles();
        getRoles();
        getallcompaniesdata();
    }, []);

    useEffect(() => {
        if (currentId) {
            setLoading(true);
            fetchUserById(currentId).then((response) => {
                setFirstName(response.data.data.firstName);
                setLastName(response.data.data.lastName);
                setRoleId(response.data.data.roles[0].roleId);
                setCompanyId(response.data.data.companyId);
                setisActive(response.data.data.isActive);
                setAllowSupportCall(response.data.data.isSupportCall);
                setLoading(false);
            });
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [currentId]);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    const getRoles = () => {
        fetchAllRoles()
            .then((response) => {
                dispatch(getallroles(response.data));
                setAllRoles(response.data.data);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };
    //Empty the UserSite data 
    const handleDrawerClose = () => {
        setUserSitesData([]);
        setIsDrawerOpen(false);


    };
    const handleToggle = () => {
        setAllowSupportCall(!allowSupportCall);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredRoles = allRoles.filter((role) =>
        role.roleName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredCompany = masterCompanies.filter((company) =>
        company.companyName.toLowerCase().includes(searchCompany.toLowerCase())
    );

    const handleCompanyChange = (e) => {
        setSearchCompany(e.target.value);
    };
    const getallcompaniesdata = () => {
        fetchAllCompaniesdata()
            .then((response) => {
                dispatch(getallcompanies(response.data));
                setMasterCompanies(response.data.data);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const GetUserAccessLevels = async (userId) => {
        try {
            const response = await getUserAccessLevels(userId);
            if (response?.data?.data) {
                window.open(`User/Access/${userId}`, "_blank");
            }
            else {
                toast.error(t("AddUserSiteRelation"), {
                    className: "toastError",
                });
                history.push('/User')
            }
        } catch (error) {
            console.error("Error fetching user access levels:", error);
        }
    };


    const cloneUserData = async (e) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FirstName", name);
        formData.append("LastName", lname);
        formData.append("EmailId", email.toLowerCase());
        formData.append("PasswordHash", password);
        formData.append("PhoneNumber", phnumber);
        formData.append("RoleId", roleId);
        formData.append("CompanyId", companyId);
        formData.append("IsActive", isActive);
        formData.append("IsSupportCall", allowSupportCall);
        // formData.append("LoggedInId", loggedUserId);
        formData.append("CloneUserId", currentId);
        const response_ = await axios({
            method: "post",
            url: url.POST_ADDUSER,
            data: formData,
        })
            .then((response) => {
                if (response.data.success == false) {
                    toast.error(t("SubCompanyDeletionfailed"), {
                        className: "toastSuccess",
                    });
                } else {
                    dispatch(saveuserdetails(response.data));
                    toast.success(t("SubCompanyDeletedSuccessfully"), {
                        className: "toastSuccess",
                    });
                    setName("");
                    setLname("");
                    setEmail("");
                    setPassword("");
                    setPhnumber("");
                    setCloneModal(false);
                    getalluserswithroles();
                }
                setLoading(false);
            })
            .catch((err) => {
                toast.error(err.response.data.message, {
                    className: "toastSuccess",
                });
            });
        setLoading(false);
    };
    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then((response) => {
                dispatch(getuserroleandmenu(response.data));
                // setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const handleRole = (roleId) => {
        setIsRoleCheck(true);
        setSelectedRoles((prev) => {
            if (prev.includes(roleId)) {
                return prev.filter((sel) => sel !== roleId);
            } else {
                return [...prev, roleId];
            }
        });
    };
    const handleCompany = (companyId) => {
        setIsCompanyCheck(true);
        setSelectedMasterCompanies((prev) => {
            if (prev.includes(companyId)) {
                return prev.filter((sel) => sel !== companyId);
            } else {
                return [...prev, companyId];
            }
        });
    };

    const getalluserswithroles = (isReset = false) => {
        setLoading(true);
        const requestPayload = {
            UserId: loggedUserId,
            ...(isReset ? { UserName: "" } : userName ? { UserName: userName } : {}),
            ...(selectedRoles && selectedRoles.length > 0 ? { roleIds: selectedRoles } : {}),
            ...(selectedMasterCompanies && selectedMasterCompanies.length > 0
                ? { companyIds: selectedMasterCompanies }
                : {}),
        };

        fetchUsersWithRoles(requestPayload)
            .then((response) => {
                dispatch(getusersandroles(response.data));
                setTable(response.data.data);
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };

    const getvalidateuser = (emailid) => {
        loginasuser(emailid)
            .then((response) => {
                dispatch(validateuserdata(response.data));
                localStorage.setItem("UserId", response.data.data.userId);
                localStorage.setItem("tekaVerseToken", response.data.data.token);
                localStorage.setItem("isloginClicked", true);
                history.push("/Dashboard");
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : "",
            lname: lname ? lname : "",
            email: email ? email : "",
            password: password ? password : "",
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("PleaseEnterName"))
                .matches("^[A-Za-z][A-Za-z]*$", t("PleaseentervalidName")),

            lname: Yup.string()
                .required(t("PleaseEnterLastName"))
                .matches("^[A-Za-z][A-Za-z]*$", t("PleaseentervalidLastName")),

            email: Yup.string()
                .required(t("PleaseEnterEmail"))
                .matches(
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    t("Pleaseentervalidemail")
                ),

            password: Yup.string()
                .required(t("PleaseEnterPassword"))
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    t("MustContainCharacters")
                ),
        }),

        onSubmit: (values) => {
            if (!phoneError) {
                cloneUserData(values);
            }
        },
    });

    const SearchControl = async () => {
        if (userName !== "") {
            setResetDisplay(true);
        } else if (userName == "" && resetDisplay) {
            setResetDisplay(false);
        }
        setSearchClicked(true);
        getalluserswithroles();
    };

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions);
    };

    const getSorterTooltipTitle = () => {
        if (sortCompany === "ascend") {
            return t("Sorttodescend");
        } else if (sortCompany === "descend") {
            return t("Sorttoascend");
        }
        return "Sort";
    };

    const bindRole = (userRoles) => {
        return userRoles.map((x) => x.roleName).join(", ");
    };

    const handleEdit = (userId) => {
        history.push("/User/edit/" + userId);
    };

    const handleLogin = (emailId) => {
        localStorage.removeItem("tekaVerseToken");
        getvalidateuser(emailId);
    };

    const handleClone = (userId) => {
        setCurrentId(userId);
        setIsCloneUser(true);
        // history.push('/User/edit/' + userId)
        setCloneModal(true);
    };

    const handleCancel = () => {
        setName("");
        setLname("");
        setEmail("");
        setPassword("");
        setPhnumber("");
        setSlectedCountryCode();
        setCloneModal(false);
        //getalluserswithroles();
    };

    const { confirm } = Modal;
    const handleDelete = (userId) => {
        confirm({
            title: t("Areyousureyouwanttodeletethisrecord"),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                deleteUserRecord(userId);
            },
            onCancel() { },
        });
    };

    const handleResetButton = () => {
        setUserName("");
        getalluserswithroles(true);
        setResetDisplay(false);
    };

    const handleSendRequest = (emailid) => {
        confirm({
            title: t("Areyousureyouwanttosendmailtouser"),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleSendMail(emailid);
            },
            onCancel() { },
        });
    };

    const handleSendMail = async (email) => {
        setLoading(true);
        if (email) {
            const response = await axios
                .get(url.GET_SENDUSEREMAIL + "\\" + email)
                .then((response) => {
                    if (response.data.success) {
                        toast.success(t("Emailsent"), {
                            className: "toastSuccess",
                        });
                        setLoading(false);
                    } else {
                        toast.error(t("UserNotFound"), {
                            className: "toastSuccess",
                        });
                    }
                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        }
        setLoading(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            SearchControl();
        }
    };

    const deleteUserRecord = async (userId) => {
        setLoading(true);
        await axios
            .delete(url.DELETE_USERTYPE + "/" + userId, {})
            .then((response) => {
                dispatch(deleteuserdata(response.data));
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                toast.success(t("UserDeletedSuccessfully"), {
                    className: "toastSuccess",
                });
                getalluserswithroles();
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false);
    };
    //call the fetchUSersite function and opens the drawer 
    const handleUserSiteClick = (user) => {
        setSelectedUser({
            name: user.lastName + " " + user.firstName,
        });

        fetchUsersites(user.userId);
        setIsDrawerOpen(true);
    };

    //fetch the user site data relation 
    const fetchUsersites = async (userId) => {
        setLoading(true);
        try {
            const response = await fetchUserSiteName(userId);

            console.log("API Response:", response.data);
            const data = Array.isArray(response.data) ? response.data : response.data.data;
            setUserSitesData(data);
            dispatch(getusersitedata(data));
            setdrawerOptions({
                ...drawerptions,
                pagination: {
                    ...drawerptions.pagination,
                    current: 1,
                    pageSize: drawerPageSize,
                    total: data.length,
                }
            });


        } catch (err) {
            console.error("Error fetching user sites: ", err);
        }
        setLoading(false);
    };

    const siteColoumns = [
        {
            title: t("SrNo"),
            key: "serialNo",
            render: (text, record, index) => {
                setDrawerIndex(
                    `${(drawerptions.pagination.current === undefined
                        ? 0
                        : drawerptions.pagination.current - 1) *
                    10 + index + 1
                    }`
                );
                return `${(drawerptions.pagination.current === undefined
                    ? 0
                    : drawerptions.pagination.current - 1) * 10 + index + 1
                    }`
            },
        },
        {
            title: t("Sub Company"),
            dataIndex: "companyName",
            render: (text, record) => (
                <Tooltip title={record.companyName}>{record.companyName}</Tooltip>
            ),
        },
        {
            title: t("Site Name"),
            dataIndex: "siteName",
            render: (text, record) => (
                <Tooltip title={record.siteName}>{record.siteName}</Tooltip>
            ),
        },
    ];
    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            render: (text, record, index) => {
                setCurrentIndex(
                    `${(options.pagination.current === undefined
                        ? 0
                        : options.pagination.current - 1) *
                    REACT_APP_DEFAULT_PAGESIZE +
                    index +
                    1
                    }`
                );
                return `${(options.pagination.current === undefined
                    ? 0
                    : options.pagination.current - 1) *
                    REACT_APP_DEFAULT_PAGESIZE +
                    index +
                    1
                    }`;
            },
        },

        {
            title: t("Name"),
            dataIndex: "name",
            render: (data, user) => user.lastName + " " + user.firstName,
        },

        {
            title: t("Email"),
            dataIndex: "emailId",
        },

        {
            title: t("MasterCompany"),
            dataIndex: "companyName",
            render: (companyName) => (
                <Tooltip title={companyName}>{companyName}</Tooltip>
            ),
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            sortOrder: sortCompany,
            onHeaderCell: () => ({
                onClick: () =>
                    setSortCompany(sortCompany === "ascend" ? "descend" : "ascend"),
                sortDirections: ["ascend", "descend", "ascend"],
            }),
        },

        {
            title: t("Roles"),
            dataIndex: "roles",
            render: (data, user) =>
                user.roles ? (
                    <div className="ellips-dec">
                        <Tooltip title={bindRole(user.roles)}>
                            {bindRole(user.roles)}
                        </Tooltip>
                    </div>
                ) : (
                    "NA"
                ),
        },
        {
            title: t("SupportCall"),
            dataIndex: "isSupportCall",
            render: (data) => (data ? "Y" : "N"),
            align: "center",
        },

        {
            title: t("Status"),
            dataIndex: "isActive",
            render: (data) => (data ? "Active" : "Inactive"),
        },

        {
            title: "User Sites",
            key: "index",
            render: (record, index) => (
                <>
                    <Tooltip title={t("Clicktoviewusersites")}>
                        <button className="table-btn" id={index + 1}>
                            <MapIcon
                                style={{ color: "white", cursor: "pointer" }}
                                onClick={() => handleUserSiteClick(record)}
                            />
                        </button>
                    </Tooltip>
                </>
            ),
        },

        {
            title: t("AccessLevel"),
            key: "index",
            render: (record, index) => (
                <>
                    <Tooltip title={t("Clicktoviewaccesslevel")}>
                        <button className="table-btn" id={index + 1}>
                            <AdminPanelSettingsIcon
                                style={{ color: "white", cursor: "pointer" }}
                                onClick={(e) => {
                                    // Open the URL in a new tab
                                    GetUserAccessLevels(record.userId)
                                 //   window.open(`User/Access/${record.userId}`, "_blank");
                                }}
                            />
                        </button>
                    </Tooltip>
                </>
            ),
        },

        {
            title: t("SendRequest"),
            key: "index",
            render: (text, record, index) => (
                <>
                    <Tooltip title={t("Clicktosendrequest")}>
                        <button className="table-btn" id={index + 1}>
                            <img
                                src={emailIcon}
                                onClick={(e) => handleSendRequest(record.emailId)}
                            ></img>
                        </button>
                    </Tooltip>
                </>
            ),
        },
        {
            title: t("Action"),
            dataIndex: "",

            render: (data, companyId) => (
                <Dropdown
                    className="action-dropdown"
                    overlay={
                        <Menu>
                            {roleName == "SuperAdmin" && loggedUserId != data.userId ? (
                                <Menu.Item key="edit" onClick={() => handleLogin(data.emailId)}>
                                    {t("LoginasThisUser")}
                                </Menu.Item>
                            ) : (
                                ""
                            )}
                            {roleName == "SuperAdmin" ? (
                                <Menu.Item key="clone" onClick={() => handleClone(data.userId)}>
                                    {t("CloneUser")}
                                </Menu.Item>
                            ) : (
                                ""
                            )}
                            <Menu.Item key="edit" onClick={() => handleEdit(data.userId)}>
                                {t("Edit")}
                            </Menu.Item>
                            <Menu.Item key="delete" onClick={() => handleDelete(data.userId)}>
                                {t("Delete")}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                >
                    <a
                        className="ant-dropdown-link d-flex justify-content-between align-items-center"
                        onClick={(e) => e.preventDefault()}
                    >
                        <span>{t("Actions")} </span>
                        {/*<DownOutlined style={{ fontSize: '8px' }}*/}
                        {/*/>*/}
                    </a>
                </Dropdown>
            ),
        },
    ];

    const scrollerStyle = {
        maxHeight: "300px",
        overflowY: "auto",
    };
    const fixedBottomStyle = {
        position: "-webkit-sticky",
        position: "sticky",
        bottom: "0",
        left: "0",
        width: "100%",
        backgroundColor: "white",
        padding: "10px",
        boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
        textAlign: "right",
        zIndex: "1000",
    };
    useEffect(() => {
        if (isRoleReset || isCompanyReset) {
            SearchControl();
            if (isCompanyReset) {
                setIsCompanyReset(false);
            }
            if (isRoleReset) {
                setIsRoleReset(false);
            }
        }
    }, [isRoleReset, isCompanyReset]);

    const handleUserAccess = () => {
        window.open("/UserAccess", "_blank"); // Opens the URL in a new tab
    };

    return (
        <div>
            <Title title="User"></Title>
            {roleName == null ? (
                <Loader />
            ) : roleName &&
                roleName != "SuperAdmin" &&
                roleName &&
                roleName != "Admin" &&
                roleName &&
                roleName != "Developer" ? (
                <NotAuthorised></NotAuthorised>
            ) : (
                <>
                    {loading ? <Loader /> : ""}
                    <Layout>
                        <section className="User_section common ">
                            <Toaster />
                            <div className="container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="px-1">{t("User")}</h4>

                                    <div className="ml-auto d-flex flex-wrap right-section">
                                        <div className="type-drop common-drop dropdown roles-dropbox-btn">
                                            <button
                                                className="btn w-100 text-left text-white dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {t("Roles")}
                                                {selectedRoles.length > 0 && (
                                                    <span className="badge bg-secondary rounded-circle position-absolute top-0 start-100 translate-middle">
                                                        {selectedRoles.length}
                                                    </span>
                                                )}
                                            </button>
                                            <ul
                                                className="dropdown-menu roles-dropbox"
                                                aria-labelledby="dropdownMenuButton1"
                                                style={scrollerStyle}
                                            >
                                                <li className="px-3">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-2"
                                                        placeholder={t("SearchByRole")}
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                </li>

                                                {filteredRoles.map((item) => (
                                                    <li className="px-3">
                                                        <a className="dropdown-item">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input cursor-pointer"
                                                                name="checkname"
                                                                checked={selectedRoles.includes(item.roleId)}
                                                                onChange={() => {
                                                                    handleRole(item.roleId);
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label ml-2 non-clickable"
                                                                for="exampleCheck1"
                                                            >
                                                                {item.roleName}
                                                            </label>
                                                        </a>
                                                    </li>
                                                ))}
                                                <li className="text-right" style={fixedBottomStyle}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered mr-2"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            SearchControl();
                                                        }}
                                                        disabled={!isrolecheck}
                                                    >
                                                        {t("Apply")}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered"
                                                        onClick={() => {
                                                            setSelectedRoles([]);
                                                            setIsRoleReset(true);
                                                            setSearchQuery("");
                                                            setIsRoleCheck(false);
                                                        }}
                                                    >
                                                        {t("Clear")}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="type-drop common-drop dropdown companies-dropbox-btn">
                                            <button
                                                className="btn w-100 text-left text-white dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {t("MasterCompany")}
                                                {selectedMasterCompanies.length > 0 && (
                                                    <span className="badge bg-secondary rounded-circle position-absolute top-0 start-100 translate-middle">
                                                        {selectedMasterCompanies.length}
                                                    </span>
                                                )}
                                            </button>
                                            <ul
                                                className="dropdown-menu companies-dropbox"
                                                aria-labelledby="dropdownMenuButton1"
                                                style={scrollerStyle}
                                            >
                                                <li className="px-3">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-2"
                                                        placeholder={t("SearchByCompany")}
                                                        value={searchCompany}
                                                        onChange={handleCompanyChange}
                                                    />
                                                </li>
                                                {filteredCompany.map((item) => (
                                                    <li className="px-3">
                                                        <a className="dropdown-item">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input cursor-pointer"
                                                                name="checkname"
                                                                checked={selectedMasterCompanies.includes(
                                                                    item.companyId
                                                                )}
                                                                onChange={() => {
                                                                    handleCompany(item.companyId);
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label ml-2 non-clickable"
                                                                for="exampleCheck1"
                                                            >
                                                                {item.companyName}
                                                            </label>
                                                        </a>
                                                    </li>
                                                ))}

                                                <li className="text-right" style={fixedBottomStyle}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered mr-2"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            SearchControl();
                                                        }}
                                                        disabled={!iscompanycheck}
                                                    >
                                                        {t("Apply")}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered"
                                                        onClick={(e) => {
                                                            setSelectedMasterCompanies([]);
                                                            setIsCompanyReset(true);
                                                            setSearchCompany("");
                                                            setIsCompanyCheck(false);
                                                        }}
                                                    >
                                                        {t("Clear")}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex position-relative searchBox">
                                            <input
                                                type="text"
                                                className="form-control searchQR"
                                                value={userName}
                                                placeholder={t("SearchByName/Email")}
                                                onChange={(e) => {
                                                    setUserName(e.target.value.trimLeft());
                                                }}
                                                onKeyDown={(e) => handleKeyPress(e)}
                                                    ></input>

                                            {userName == "" ? (
                                                <button
                                                    onClick={() => SearchControl()}
                                                    disabled
                                                    className="searchButton mt-sm-0"
                                                >
                                                    {" "}
                                                    <img src={searchIcon}></img>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => SearchControl()}
                                                    className="searchButton  mt-sm-0"
                                                >
                                                    {" "}
                                                    <img src={searchIcon}></img>
                                                </button>
                                            )}
                                        </div>
                                        {userName == "" && !resetDisplay ? (
                                            ""
                                        ) : (
                                            <button
                                                className="btn btn-primary d-flex align-items-center btn-new"
                                                onClick={() => handleResetButton()}
                                            >
                                                {t("Reset")}
                                            </button>
                                        )}

                                        <div>
                                            <button
                                                className="btn btn-primary user-access"
                                                onClick={handleUserAccess}
                                            >
                                                {" "}
                                                <GroupIcon /> {t("UserAccess")}{" "}
                                            </button>
                                        </div>

                                        <Link
                                            className="btn btn-primary d-flex align-items-center btn-new"
                                            to="/Add/User"
                                        >
                                            <img src={Add}></img>
                                            {t("AddUser")}
                                        </Link>
                                    </div>
                                </div>

                                <div className="Viewer-table index--table position-relative common-table mb-4">
                                    <Table
                                        className="antd--detail-table1"
                                        showSorterTooltip={{ title: getSorterTooltipTitle() }}
                                        columns={columns}
                                        dataSource={table}
                                        //  pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}
                                        pagination={
                                            options.pagination.total > REACT_APP_DEFAULT_PAGESIZE
                                                ? {
                                                    ...options.pagination,
                                                    locale: { items_per_page: t("customPage") },
                                                    onShowSizeChange: (currentPage, pageSize) => {
                                                        console.log(
                                                            "Hit in show size change",
                                                            currentPage,
                                                            pageSize
                                                        );
                                                    },
                                                }
                                                : false
                                        }
                                        onChange={handleChange}
                                        paginationTotalRows={total}
                                    />

                                    <Drawer
                                        title={
                                            <div className="drawer-title-container">
                                                <div className="drawer-user-icon">
                                                    <UserOutlined />
                                                </div>
                                                <Typography.Text className="drawer-title-text">
                                                    {`User Name : ${selectedUser.name}`}
                                                </Typography.Text>
                                            </div>
                                        }

                                        placement="right"
                                        onClose={handleDrawerClose}
                                        open={isDrawerOpen}
                                        width={500}
                                        closable={false}
                                        className="associated-sites"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "16px",
                                            }}
                                        >
                                            <Button
                                                type="link"
                                                icon={<CloseOutlined style={{ color: "#FFFFFF" }} />}
                                                onClick={handleDrawerClose}
                                                style={{
                                                    // alignSelf: "flex-end",
                                                    padding: 0,
                                                    marginBottom: "8px",
                                                }}
                                            />

                                            <h3
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "#FFFFFF",
                                                    margin: 0,
                                                }}
                                            >
                                                Associated Sites
                                            </h3>

                                            <Link
                                                className="btn btn-primary d-flex align-items-center btn-new"
                                                to={{
                                                    pathname: "/UserSite/Add",
                                                    state: { origin: "UserPage" },
                                                }}
                                            >
                                                <img src={Add}></img>
                                                {t("Add User Site")}
                                            </Link>
                                        </div>
                                        <div className="Viewer-table index--table position-relative common-table mb-4">
                                            <Table
                                                className="antd--detail-table1 "
                                                columns={siteColoumns}
                                                dataSource={userSitesData}
                                                // pagination={false}
                                                rowKey={(record, index) =>
                                                    `${record.siteName}-${index}`
                                                }
                                                size="small"
                                                pagination={
                                                    drawerptions.pagination.total > 10
                                                        ? {
                                                            ...drawerptions.pagination,
                                                            locale: { items_per_page: t("customPage") },
                                                            onShowSizeChange: (currentPage, pageSize) => {
                                                                console.log(
                                                                    "Hit in show size change",
                                                                    currentPage,
                                                                    pageSize
                                                                );
                                                            },
                                                        }
                                                        : false
                                                }
                                                onChange={handleTableChange}
                                                paginationTotalRows={total}
                                            />
                                        </div>
                                    </Drawer>
                                </div>
                            </div>
                        </section>
                    </Layout>

                    <Modal
                        title={
                            <div className="modal-title">{`Clone ${firstname} ${lastname}`}</div>
                        }
                        visible={cloneModal}
                        onCancel={handleCancel}
                        footer={null}
                        width={1000}
                    >
                        <div className="content-area">
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    if (!phnumber || !(phnumber && phnumber.length > 10)) {
                                        setPhoneError(true);
                                    }

                                    return false;
                                }}
                            >
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    {t("FirstName")}{" "}
                                                    <span className="required-fields"> * </span>
                                                </label>
                                                <div className="input-group">
                                                    <Input
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={t("EnterName")}
                                                        type="text"
                                                        maxLength={50}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            setName(e.target.value.replace(/\s{2}/g, ""));
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name}
                                                        invalid={
                                                            validation.touched.name && validation.errors.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    {t("LastName")}{" "}
                                                    <span className="required-fields"> * </span>
                                                </label>
                                                <div className="input-group">
                                                    <Input
                                                        name="lname"
                                                        className="form-control"
                                                        placeholder={t("EnterLastName")}
                                                        type="text"
                                                        maxLength={50}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            setLname(e.target.value.replace(/\s{2}/g, ""));
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.lname}
                                                        invalid={
                                                            validation.touched.lname &&
                                                                validation.errors.lname
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.lname &&
                                                        validation.errors.lname ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.lname}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    {t("Email")}{" "}
                                                    <span className="required-fields"> * </span>
                                                </label>
                                                <div className="input-group">
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder={t("EnterEmail")}
                                                        type="text"
                                                        maxLength={50}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            setEmail(e.target.value);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email}
                                                        invalid={
                                                            validation.touched.email &&
                                                                validation.errors.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.email &&
                                                        validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    {t("Password")}
                                                    <span className="required-fields"> * </span>
                                                </label>
                                                <div className="input-group">
                                                    <Input
                                                        name="password"
                                                        className="form-control"
                                                        placeholder={t("EnterPassword")}
                                                        type={passwordType}
                                                        maxLength={50}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            setPassword(e.target.value);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password}
                                                        invalid={
                                                            validation.touched.password &&
                                                                validation.errors.password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <div class="input-group-append">
                                                        <span
                                                            class="input-group-text user_icon"
                                                            id="basic-addon2"
                                                        >
                                                            <div onClick={togglePassword}>
                                                                {passwordType === "password" ? (
                                                                    <VisibilityOffIcon
                                                                        style={{ color: "gray" }}
                                                                    />
                                                                ) : (
                                                                    <VisibilityIcon style={{ color: "gray" }} />
                                                                )}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {validation.touched.password &&
                                                        validation.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.password}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    {t("PhoneNumber")}{" "}
                                                    <span className="required-fields"> * </span>
                                                </label>
                                                <div className="input-group phnumber">
                                                    <PhoneInput
                                                        className="w-5"
                                                        country={"us"}
                                                        enableSearch={true}
                                                        value={phnumber}
                                                        placeholder={t("Enterphonenumber")} // Custom placeholder
                                                        onChange={(phone, country) => {
                                                            setSlectedCountryCode((prevState) => {
                                                                if (prevState !== country.countryCode) {
                                                                    setPhnumber(country.dialCode);
                                                                } else {
                                                                    setPhnumber(phone);
                                                                }
                                                                return country.countryCode;
                                                            });
                                                            if (
                                                                phone &&
                                                                phone.length > 10 &&
                                                                !phone
                                                                    .replace(country.dialCode, "")
                                                                    .startsWith("0")
                                                            ) {
                                                                setPhoneError(false);
                                                            } else {
                                                                setPhoneError(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    <small className="text-danger d-block mt-2">
                                                        {!phoneError
                                                            ? " "
                                                            : t("PleaseEnterValidPhoneNumber")}
                                                    </small>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group wk-select">
                                                <label>{t("MasterCompany")}</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-0 flex-grow-1 filter-select"
                                                    value={
                                                        (masterCompanies &&
                                                            masterCompanies.find(
                                                                (company) => company.companyId === companyId
                                                            )?.companyName) ||
                                                        t("SelectCompany")
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group wk-select">
                                                <label>{t("Roles")}</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-0 flex-grow-1 filter-select"
                                                    value={
                                                        (allRoles &&
                                                            allRoles.find((role) => role.roleId === roleId)
                                                                ?.roleName) ||
                                                        t("Select Roles")
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="alarm-toggle--wrap d-inline-block">
                                                <Switch
                                                    id="allowNotifications"
                                                    className="ms-2"
                                                    checked={allowSupportCall}
                                                    onChange={handleToggle}
                                                />

                                                <label className="alarm-toggle-label ml-2">
                                                    {t("AllowSupportCall")}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="text-right mt-3 clone-button">
                                            <button type="submit" className="btn btn-primary mr-3">
                                                {t("Clone")}
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={handleCancel}
                                            >
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default connect()(UserIndex);
