import Layout from "../Layout";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import BugReportIcon from '@mui/icons-material/BugReport';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import TaskIcon from '@mui/icons-material/Task';
import { useParams } from 'react-router-dom';
import Compressor from 'compressorjs';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { DatePicker } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import QAIcon from "../../assets/images/QAIcon.png";
import DevIcon from "../../assets/images/developerIcon.png";
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg"
import ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import Loader from "../loader";
import { getAllDevs, getallprojects } from "../../Redux/Actions/AccessibilityActions";
import { fetchAllDevelopers, fetchProjectDetails, fetchUserMenudata, fetchAllWorkdata, fetchAllSubCompaniesdata, fetchSubCompaniesByUserId, fetchsitebyusercompany } from "../../Redux/Api/fetch.api";
import { addworkitems, getworkcategory } from '../../Redux/Actions/WorkItemAction';
import { postWorkItem } from "../../Redux/Api/fetch.api";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Link, useHistory, useLocation } from "react-router-dom";
import * as dayjs from 'dayjs'
import { getallsubcompanydata } from '../../Redux/Actions/CompanyRelationAction';
import { getsubcompanybyuserid } from '../../Redux/Actions/UserCompanyAction';
import { getusersitecompanydata } from '../../Redux/Actions/UserCompanySiteAction';
import { useTranslation } from "react-i18next";
import Title from "../Title";

const Create = () => {
    const { t } = useTranslation('workItemCreate');
    const { type, supportid = 0, companyid = 0, siteid = 0 } = useParams();
    const [supportId, setSupportId] = useState(supportid);
    const [siteId, setSiteId] = useState(siteid);
    const [companyId, setCompanyId] = useState(companyid);
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [titleError, setTittleError] = useState(false)
    const [project, setProject] = useState("");
    const [projectError, setProjectError] = useState(false)
    const [categoryError, setCategoryError] = useState(false)
    const [priority, setPriority] = useState("")
    const [checked, setChecked] = useState(true);
    const [priorityError, setPriorityError] = useState(false)
    const [userRole, setUserRole] = useState("Super Admin")
    const [userRoleId, setUserRoleId] = useState();
    const [systemInfo, setSystemInfo] = useState("")
    const [reproSteps, setReproSteps] = useState("")
    const [discussion, setDiscussion] = useState("")
    const [summary, setSummary] = useState("")
    const [status, setStatus] = useState(0)
    const [allProjects, setAllProjects] = useState([])
    const [allWorkCategory, setAllWorkCategory] = useState([])
    const [timeError, setTimeError] = useState(false);
    const [allDevs, setAllDevs] = useState([])
    const [assignedTo, setAssignedTo] = useState("UnAssigned")
    const [assignToId, setAssignToId] = useState(0)
    const [isActive, setIsActive] = useState(true);
    const [intialLetterSynmbol, setIntialLetterSynmbol] = useState("UA")
    const [dueDate, setDueDate] = useState("");
    const [estimateDateTime, setEstimateDateTime] = useState();
    const [compltedTime, setCompletedTime] = useState();
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    let history = useHistory();
    const [assignedName, setAssignedName] = useState("UnAssigned");
    const [intialLetters, setInitialLetter] = useState("UA");
    const [allSubCompanies, setAllSubCompanies] = useState([]);
    const [subCompanies, setSubCompanies] = useState([]);
    const userid = window.localStorage.getItem('UserId')
    const [workcategoryId, setWorkCategoryId] = useState(0);
    const [companySiteData, setCompanySiteData] = useState([]);

    useEffect(() => {
        getallapps();
        getAllDeveloper();
        getUserDetails();
        getSubCompanyData();
        getSubCompaniesDetails();
        getallworkdata();
    }, [])

    useEffect(() => {
        getsitesbyuserandcompany();
    }, [companyId]);

    //useEffect(() => {
    //    setSupportId(supportid ?? null);
    //    setCompanyId(companyid ?? 0);
    //    setSiteId(siteid ?? 0);
    //}, [supportId]);

    useEffect(() => {
        document.body.style.overflow = loading ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [loading]);


    const getallapps = () => {
        setLoading(true)
        fetchProjectDetails()
            .then(response => {
                dispatch(getallprojects(response.data))
                setAllProjects(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    const getallworkdata = () => {
        setLoading(true)
        fetchAllWorkdata()
            .then(response => {
                dispatch(getworkcategory(response.data))
                setAllWorkCategory(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        // setTimeout(() => {
        setLoading(false)
        // }, 3000)
    }

    const handleChange = () => {
        setIsActive(!isActive)
    }
    const getAllDeveloper = () => {
        setLoading(true)
        fetchAllDevelopers()
            .then(response => {
                dispatch(getAllDevs(response.data))
                setAllDevs(response.data.data)
            }).catch((err) => {
                console.log("Err: ", err);
            });
        // setTimeout(() => {
        setLoading(false)
        //  }, 3000)
    }

    const getsitesbyuserandcompany = () => {

        setLoading(true)
        fetchsitebyusercompany({
            UserId: userid,
            CompanyId: companyId
        })
            .then(response => {

                dispatch(getusersitecompanydata(response.data.data))
                setCompanySiteData(response.data.data);
                if (response.data.data.length == 1) {
                    setSiteId(response.data.data[0].siteId)

                }

                else if (response.data.data.length == 0) {
                    setSiteId(0)
                }


            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }
    const getSubCompanyData = () => {

        fetchAllSubCompaniesdata()
            .then(response => {
                dispatch(getallsubcompanydata(response.data))
                setSubCompanies(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getSubCompaniesDetails = () => {
        fetchSubCompaniesByUserId(userid)
            .then(response => {
                dispatch(getsubcompanybyuserid(response.data))
                setAllSubCompanies(response.data.data.companyUserdata)
                if (response.data.data.companyUserdata.length <= 1)
                    setCompanyId(response.data.data.companyUserdata[0].subCompanyId)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }
    const getUserDetails = () => {
        const loggedUserId = localStorage.getItem('UserId')
        fetchUserMenudata(loggedUserId)
            .then(response => {

                setUserRole(response.data.data.userRoleDetails[0].roleName)
                setUserRoleId(response.data.data.userRoleDetails[0].roleId)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }



    const handlePriority = (event) => {
        event.preventDefault();
        if (event.target.value === "" || event.target.value === 0) {
            setPriorityError(true)
        }
        else {
            setPriorityError(false)
        }
        setPriority(event.target.value)
    }

    const handleTitleInput = (event) => {
        if (event.target.value === "") {
            setTittleError(true)
        }
        else {
            setTittleError(false);
            setTitle(event.target.value).trimLeft();
        }
    }

    const handleSubmit = async (e) => {
        let isValidationPassed = true;

        //  setLoading(true)
        e.preventDefault();
        if (title === "") {
            setTittleError(true)
            isValidationPassed = false;
        }
        if (project === 0 || project === "") {
            setProjectError(true);
            isValidationPassed = false;
        }
        if (priority === "" || priority === 0) {
            if (userRoleId >= 7) {
                setPriorityError(true);
                isValidationPassed = false;
            }
        }
        if (workcategoryId === 0 || workcategoryId === "") {
            setCategoryError(true);
            isValidationPassed = false;
        }

        if (isValidationPassed) {
            let WorkType;
            switch (type) {
                case "Bug":
                    WorkType = 0
                    break;
                case "Task":
                    WorkType = 1
                    break;
                case "Support":
                    WorkType = 3
                    break;
                case "QA":
                    WorkType = 2
                    break;
                default:
                    break;
            }

            const compressImage = (base64Image) => {
                return new Promise((resolve, reject) => {
                    // Extract the base64 data (remove the data URL scheme part)
                    const base64Data = base64Image.split(',')[1];
                    const mimeString = base64Image.split(',')[0].split(':')[1].split(';')[0];

                    // Decode base64 string to binary data
                    const byteString = atob(base64Data);

                    // Create an ArrayBuffer and a Uint8Array to hold the binary data
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const intArray = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        intArray[i] = byteString.charCodeAt(i);
                    }

                    // Create a Blob from the ArrayBuffer
                    const blob = new Blob([arrayBuffer], { type: mimeString });

                    // Use Compressor.js to compress the Blob
                    new Compressor(blob, {
                        quality: 0.8,
                        maxWidth: 1024,
                        success(result) {
                            let reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onloadend = () => {
                                resolve(reader.result);
                            };
                        },
                        error(err) {
                            reject(err);
                        },
                    });
                });
            };
            debugger;
            const formData = new FormData();
            formData.append("ProjectId", project);
            formData.append("WorkTitle", title);
            formData.append("WorkType", WorkType);
            formData.append("AssignedTo", assignToId);
            const maxSize = 700 * 1024; // 700 KB
            const processImages = async (content) => {
                const regex = /<figure class="image"><img src="data:image\/[^"]+"/g;
                const images = content.match(regex);

                if (images) {
                    for (let imageTag of images) {
                        let base64ImageStart = imageTag.indexOf('data:image/');
                        let base64ImageEnd = imageTag.indexOf('"', base64ImageStart);
                        let base64Image = imageTag.substring(base64ImageStart, base64ImageEnd);

                        const base64Size = base64Image.length * (3 / 4) - (base64Image.endsWith('==') ? 2 : base64Image.endsWith('=') ? 1 : 0);
                        if (base64Size > maxSize) {
                            try {
                                let compressedImage = await compressImage(base64Image);
                                content = content.replace(base64Image, compressedImage);
                            } catch (error) {
                                console.error("Image compression failed:", error);
                            }
                        }
                    }
                }
                return content;
            };
            let updatedDiscussion = await processImages(discussion);
            let updatedSystemInfo = await processImages(systemInfo);
            let updatedReproSteps = await processImages(reproSteps);
            formData.append("ReproduceSteps", updatedReproSteps);
            formData.append("SystemInfo", updatedSystemInfo);
            formData.append("Description", updatedDiscussion);
            formData.append("VersionSummary", summary);
            formData.append("CompanyId", companyId == undefined ? 0 : companyId);
            formData.append("SiteId", siteId == undefined ? 0 : siteId);
            formData.append("WorkCategoryId", workcategoryId);
            let taskPriority = userRoleId >= 7 ? priority : 1;
            formData.append("Priority", taskPriority);
            formData.append("Status", status);
            formData.append("ItemDueDate", dueDate == null ? "" : dueDate);
            formData.append("EstimatedTime", estimateDateTime == null ? 0 : estimateDateTime);
            formData.append("ItemCompletedTime", compltedTime == null ? 0 : compltedTime);
            formData.append("IsActive", isActive);
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().replace(/\.\d{3}Z$/, '');
            formData.append("UpdatedDate", formattedDate);
            formData.append("SupportId", supportId != null || supportId != undefined ? supportId : "");
            formData.append("AssignedBy", localStorage.getItem("UserId"));
            if (titleError == false && projectError == false && priorityError == false) {

                setLoading(true)
                postWorkItem(formData)
                    .then(response => {
                        dispatch(addworkitems(response.data))
                        if (!response.data.success) // False 
                        {
                            toast.error(t("WorkItemAlreadyExists"), {
                                className: "toastSuccess",
                            },);
                            setTimeout(() => {
                                setLoading(false)
                            }, 1000)
                        }
                        else {
                            toast.success(t("WorkItemCreatedsuccessfully"), {
                                className: "toastSuccess",
                            },);
                            setDisable(false)

                            history.push('/Workitem');

                            setTimeout(() => {
                                setLoading(false)
                            }, 1000)
                        }

                        //setProject("")
                        //setTitle("")
                        //setAssignToId(0)
                        //setReproSteps("")
                        //setSystemInfo("")
                        //setDiscussion("")
                        //setPriority("")
                        //setStatus(1)
                        //setAssignedTo("UnAssigned")
                        //setDueDate("")
                        //setEstimateDateTime("")                   

                    })

                    .catch(error => {

                        console.log("Err :", error)
                    })
            }
        }
    }

    function handleProjectChange(event) {
        event.preventDefault();
        if (event.target.value === "0") {
            setProjectError(true)
        }
        else {
            setProjectError(false)
            setProject(event.target.value);
        }
    }

    const handleDateChange = (date, dateString) => {
        setDueDate(dateString);
    };

    const handleDateTimeChange = (date, dateString) => {
        setEstimateDateTime(dateString);
    };

    const disabledDate = (current) => {
        // Disable dates before today (including today)
        return current && current.isBefore(dayjs().startOf('day'));
    };

    return (
        <>
            <Title title="Create Workitem"></Title>
            <Layout>
                <section className="Company_section common ">
                    <Toaster />
                    {loading ? <Loader /> : ''}
                    <div className="container">
                        <div class="mt-4 w-100 position-relative wk-select">
                            <h4 class="m-0">
                                <a class="backBTN" href="/workitem">
                                    <img src={Arrow_leftbrown} />  {t("Back")}
                                </a>
                            </h4>
                        </div>

                        <h4>{type == "Bug" ? t("ReportBug") : type == "Task" ? t("FeatureRequest") : type == "TestCase" ? t("TestCase") : type == "Support" ? t("SupportRequest") : ""}</h4>
                        <div className="content-area">
                            <form>
                                <p><span>{type === "Bug" ? <BugReportIcon style={{ color: 'red', marginRight: 5 }} fontSize="small" /> : type === "Task" ? <TaskIcon style={{ color: '#fb9502', marginRight: 5 }} fontSize="small" /> : <PlaylistAddCheckIcon style={{ color: '#3c98e7', marginRight: 5 }} fontSize="small" />}</span> {type == "Bug" ? t("ReportNewBug") : type == "Task" ? t("NewFeatureRequest") : type == "Support" ? t("NewSupportRequest") : type == "TestCase" ? t("NewTestCase") : ""} <span style={{ color: 'red' }}>*</span></p>
                                <input type="text" name="companyName" placeholder={t("EnterTitle")} maxlength="150" className="mb-2 form-control form-control" aria-invalid="false" value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value.trimLeft());
                                        if (e.target.value === "") {
                                            setTittleError(true)
                                        }
                                        else {
                                            setTittleError(false);
                                        }
                                    }}
                                />
                                {< small className="text-danger d-block mt-2">{!titleError ? ' ' : t('PleaseEnterTitle')}</small>}
                                <div className="assigned-section d-flex align-items-center mb-3">
                                    <div className="assigned-user dropdown mr-4">
                                        <button className="btn text-white dropdown-toggle position-relative" type="button" id="dropdownMenuButton1" disabled data-bs-toggle="dropdown" aria-expanded="false">
                                            {assignedName}
                                            <p className="position-absolute name-prefix assigned-name mb-0">{intialLetters}</p>
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li className="px-2">
                                                <span className="dropdown-item d-flex p-0" onClick={() => { setAssignedName("UnAssigned"); setAssignedToId(0); setInitialLetter("UA") }}>
                                                    <p className="name-prefix-unassigned assigned-name">UA</p>
                                                    <div className="name-details ml-2">
                                                        <h6 className="mt-2">{t("UnAssigned")}</h6>
                                                    </div>
                                                    {/* <img src={item.roles[0].roleName === "Developer" ? DevIcon : QAIcon} alt="DevIcon" className="DevIcon"></img> */}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <div className="more-element-wrap p-2 mb-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="other-aspects d-flex justify-content-between align-items-center">
                                                <ul className="list-inline d-flex justify-content-top flex-wrap">
                                                    <li className="list-inline-item mr-1">
                                                        <div className="states-drop mr-2">
                                                            <label className="mr-2 d-block mb-0">{t("State")}</label>
                                                            <select className="" onChange={(event) => setStatus(event.target.value)} defaultValue={0} disabled>
                                                                <option value="">{t("SelectState")}</option>
                                                                <option selected value={0}>{t("New")}</option>
                                                                <option value={1}>{t("Active")}</option>
                                                                <option value={2}>{t("Resolved")}</option>
                                                                <option value={3}>{t("ReOpen")}</option>
                                                                <option value={4}>{t("QA")}</option>
                                                                <option value={5}>{t("Closed")}</option>
                                                            </select>
                                                        </div>
                                                        <div style={{ height: 8, textDecoration: 'none' }}></div>
                                                    </li>
                                                    {allSubCompanies && allSubCompanies.length >= 1 && subCompanies && subCompanies.length >= 1 ?
                                                        <li className="list-inline-item mr-1">
                                                            <div className="states-drop mr-2">
                                                                <label className="mr-2 mb-0 d-block"><div>{t("Company")}</div></label>
                                                                <select className="" name="users" aria-label=".form-select-lg example"
                                                                    value={companyId}
                                                                    key={companyId}
                                                                    onChange={(e) => {
                                                                        setSiteId(0)
                                                                        setCompanyId(e.target.value)
                                                                    }}
                                                                >
                                                                    <option selected value="0">{t("SelectCompany")}</option>
                                                                    {userRole == "User" ?
                                                                        allSubCompanies && allSubCompanies.map(sub =>
                                                                            <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                {sub.subCompanyName} </option>
                                                                        ) :
                                                                        subCompanies && subCompanies.map(sub =>
                                                                            <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                {sub.subCompanyName} </option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                        </li> :

                                                        <li className="list-inline-item mr-1">
                                                            <div className="states-drop mr-2">
                                                                <label className="mr-2 mb-0 d-block">{t("Company")}</label>
                                                                <select className="" name="users" aria-label=".form-select-lg example"
                                                                    value={companyId}
                                                                    key={companyId}
                                                                    disabled
                                                                    onChange={(e) => {
                                                                        setSiteId(0)
                                                                        setCompanyId(e.target.value)
                                                                    }}
                                                                >
                                                                    <option selected value="0">{t("SelectCompany")}</option>
                                                                    {userRole == "User" ?
                                                                        allSubCompanies && allSubCompanies.map(sub =>
                                                                            <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                {sub.subCompanyName} </option>
                                                                        ) :
                                                                        subCompanies && subCompanies.map(sub =>
                                                                            <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                {sub.subCompanyName} </option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                        </li>

                                                    }
                                                    {companyId && companyId != "0" ?

                                                        <li className="list-inline-item mr-1">
                                                            <div className="states-drop mr-2">
                                                                <label className="mr-2 mb-0 d-block"><div>{t("Site")}</div></label>
                                                                <select className="" name="users" aria-label=".form-select-lg example"
                                                                    value={siteId}
                                                                    key={siteId}
                                                                    onChange={(e) => {

                                                                        setSiteId(e.target.value)

                                                                    }}
                                                                >
                                                                    <option value="">{t("SelectSite")}</option>
                                                                    {
                                                                        companySiteData && companySiteData.map(sub =>

                                                                            <option key={sub.siteId} value={sub.siteId}>
                                                                                {sub.siteName} </option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                        </li> :

                                                        <li className="list-inline-item mr-1">
                                                            <div className="states-drop mr-2">
                                                                <label className="mr-2 mb-0 d-block"><div>{t("Site")}</div></label>
                                                                <select className="" name="users" aria-label=".form-select-lg example"
                                                                    value={siteId}
                                                                    key={siteId}
                                                                    disabled
                                                                    onChange={(e) => {

                                                                        setSiteId(e.target.value)

                                                                    }}
                                                                >
                                                                    <option value="">{t("SelectSite")}</option>
                                                                    {
                                                                        companySiteData && companySiteData.map(sub =>

                                                                            <option key={sub.siteId} value={sub.siteId}>
                                                                                {sub.siteName} </option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                        </li>
                                                    }

                                                    <li className="list-inline-item mr-1">
                                                        <div className="states-drop mr-2">
                                                            <label className="mr-2 mb-0 d-block">{t("Category")}<span style={{ color: 'red', marginLeft: 5 }}>*</span></label>
                                                            <select className="" name="users" aria-label=".form-select-lg example"
                                                                value={workcategoryId}
                                                                key={workcategoryId}
                                                                onChange={(e) => {
                                                                    setWorkCategoryId(e.target.value)
                                                                    if (e.target.value === "0") {
                                                                        setCategoryError(true)
                                                                    }
                                                                    else {
                                                                        setCategoryError(false)

                                                                    }

                                                                }}
                                                            >
                                                                <option value="">{t("SelectCategory")}</option>
                                                                {
                                                                    allWorkCategory && allWorkCategory.map(sub =>
                                                                        <option key={sub.workCategoryId} value={sub.workCategoryId}>
                                                                            {sub.workDescription} </option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                        {< small className="text-danger d-block mt-1">{!categoryError ? ' ' : t('PleaseSelectCategory')}</small>}
                                                    </li>
                                                    <li className="list-inline-item mr-1">
                                                        <div className="states-drop mr-2">
                                                            <label className="mr-2 mb-0 d-block">Project<span style={{ color: 'red', marginLeft: 5 }}>*</span></label>
                                                            <select className="" onChange={(event) => handleProjectChange(event)}

                                                            >
                                                                <option selected value="0">{t("SelectProject")}</option>
                                                                {allProjects.map(item =>

                                                                    <option value={item.appId}>{item.name}
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        {< small className="text-danger d-block mt-1">{!projectError ? ' ' : t('PleaseSelectProject')}</small>}
                                                    </li>

                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="Form-body">

                                    <div className="row">

                                        <div className="col-md-8">
                                            {type && type == "Bug" && (
                                                <div className="repolink-wrap mb-3" >
                                                    <h5>{t("ReproduceSteps")}</h5>
                                                    <div style={{ color: 'gray' }}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data="<p></p>"
                                                            style={{ color: 'gray' }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setReproSteps(data)
                                                            }}
                                                        // config={{
                                                        //     placeholder: t('Entersteps'), // Placeholder text
                                                        // }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="sysinfo-wrap mb-3">
                                                <h5>{t("Description")}</h5>
                                                <div style={{ color: 'gray' }}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data="<p></p>"
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setSystemInfo(data);
                                                        }}
                                                    // config={{
                                                    //     placeholder: t("EnterDescription"), // Placeholder text
                                                    // }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="repolink-wrap mb-3" >
                                                <h5>{t("Discussion")}</h5>
                                                <div style={{ color: 'gray' }}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data="<p></p>"
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setDiscussion(data);
                                                        }}
                                                    // config={{
                                                    //     placeholder: t("EnterDiscussion"), // Placeholder text
                                                    // }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="repolink-wrap mb-3" >
                                                <h5>{t("VersionSummary")}</h5>
                                                <div style={{ color: 'gray' }}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data="<p></p>"
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setSummary(data);
                                                        }}
                                                        config={{
                                                            //   placeholder: t('EnterSummary'),
                                                            toolbar: {
                                                                items: [
                                                                    'heading',
                                                                    '|',
                                                                    'bold',
                                                                    'italic',
                                                                    '|',
                                                                    'bulletedList',
                                                                    'numberedList',
                                                                    '|',
                                                                    'indent',
                                                                    'outdent',
                                                                    '|',
                                                                    'undo',
                                                                    'redo'
                                                                ]
                                                            }// Placeholder text
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        {userRoleId && userRoleId > 6 && (
                                            <div className="col-md-4">
                                                <div className="planning-main-wrap border rounded p-3">
                                                    <div className="planning-box mb-5">
                                                        <h5>{t("Planning")}</h5>
                                                        <div className="reason-inputs">
                                                            <label className="mr-2 mb-0 d-block">{t("Priority")} <span style={{ color: 'red' }}>*</span></label>
                                                            <select className="w-100" onChange={(event) => handlePriority(event)}>
                                                                <option value="">{t("SelectPriority")}</option>
                                                                <option value={0}>{t("High")}</option>
                                                                <option value={1}>{t("Medium")}</option>
                                                                <option value={2}>{t("Low")}</option>
                                                            </select>
                                                            {< small className="text-danger d-block mt-2">{!priorityError ? ' ' : t('PleaseSelectPriority')}</small>}

                                                        </div>
                                                    </div>
                                                    <div className="planning-box">
                                                        <h5>{t("Effort")}</h5>
                                                        <div className="reason-inputs">
                                                            <div className="row align-items-center">
                                                                <div className="col-lg-5">
                                                                    <label>{t("DueDate")}:</label>
                                                                </div>
                                                                <div className="col-lg-7 mb-2">
                                                                    <DatePicker
                                                                        showTime
                                                                        size={"small"}
                                                                        format="YYYY-MM-DD HH:mm:ss"
                                                                        className="dateTime"
                                                                        onChange={handleDateChange}
                                                                        disabledDate={disabledDate}
                                                                        placeholder={t("Selectdate")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="reason-inputs">
                                                            <div className="row align-items-center">
                                                                <div className="col-lg-5">
                                                                    <label>{t("EstimateTime")} :</label>
                                                                </div>
                                                                <div className="col-lg-7 mb-2 ">
                                                                    <input type="text" className="dateTime" value={estimateDateTime}
                                                                        maxLength="2"
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
                                                                            const sanitizedValue = newValue.slice(0, 2); // Limit to two characters
                                                                            setEstimateDateTime(sanitizedValue);

                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="reason-inputs">
                                                            <div className="row align-items-center">
                                                                <div className="col-lg-5">
                                                                    <label>{t("CompletedTime")} :</label>
                                                                </div>

                                                                <div className="col-lg-7 mb-2">
                                                                    <input type="text" className="dateTime" value={compltedTime}
                                                                        maxLength="2"
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
                                                                            const sanitizedValue = newValue.slice(0, 2); // Limit to two characters
                                                                            setCompletedTime(sanitizedValue);

                                                                        }}


                                                                    />
                                                                </div>


                                                            </div>
                                                        </div>
                                                        {compltedTime != null ?
                                                            <div className="reason-inputs">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-5">

                                                                        <label>
                                                                            {estimateDateTime > compltedTime ? t("RemainingTime") : t("ExtraTime")}
                                                                        </label>


                                                                    </div>
                                                                    <div className="col-lg-7">

                                                                        <label className="mb-0">
                                                                            {estimateDateTime != null && compltedTime != null ?
                                                                                (estimateDateTime - compltedTime >= 0 ? (estimateDateTime - compltedTime) + " hrs" :
                                                                                    ` ${(Math.abs(estimateDateTime - compltedTime) === 1 ? '1 hr' : `${Math.abs(estimateDateTime - compltedTime)} hrs`)}`)
                                                                                : "0 hrs"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div class="col-md-12">
                                            <div className="discuss-wrap">
                                                <div> <input type="checkbox" className="check mr-1" defaultChecked={checked}
                                                    checked={isActive} onChange={() => handleChange()} /> {t("Active")} </div>
                                                <div className="d-flex mt-2 mb-3">
                                                    <Link className="btn btn-bordered mr-3" to="/Workitem">{t("Cancel")}</Link>
                                                    {disable == true ?
                                                        <button className="btn btn-primary text-black" onClick={(e) => handleSubmit(e)}>{t("Save")}</button> :
                                                        <button className="btn btn-primary text-black" disabled onClick={(e) => handleSubmit(e)}>{t("Save")}</button>
                                                    }
                                                </div>
                                                {userRole === "Developer" ? <div>
                                                    <h5>{t("DeveloperComment")}</h5>
                                                    <div style={{ color: 'gray' }}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            //   data="<p>Add developer's comment</p>"
                                                            onReady={editor => {
                                                                console.log('Editor is ready to use!', editor);
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                console.log({ event, editor, data });
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                console.log('Focus.', editor);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex  mb-3 mt-3">
                                                        <button className="btn btn-bordered mr-3">{t("Cancel")}</button>
                                                        <button className="btn btn-primary text-black">{t("Save")}</button>
                                                    </div>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section >
            </Layout >

        </>
    )
}
export default connect()(Create);
