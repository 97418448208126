import React, { useState, useEffect } from 'react';
import Header from '../../src/components/PageLayout/Header'
import Footer from '../../src/components/PageLayout/Footer'
import { fetchUserMenudata, validateuser } from "../Redux/Api/fetch.api";
import { getuserroleandmenu, validateuserdata } from '../Redux/Actions/UsersActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';

const Layout = ({ children, supportCount }) => {
    const loggedInUserId = localStorage.getItem('UserId')
    const [bodyId, setBodyId] = useState("");
    const [language, setLanguage] = useState(1); // English
    const [countNewSupportRequest, setcountNewSupportRequest] = useState(0);
    const dispatch = useDispatch();
    let history = useHistory();
    const [stateProfilePicture, setStateProfilePicture] = useState(false)

    useEffect(() => {
        getvalidateuser();
        getUserDetails();
    }, [])

    useEffect(() => {
        if (localStorage.getItem('tekaVerseToken') == null) {
            history.push("/");
        }
    }, []);

    const getUserDetails = async () => {
        try {
            // Use await to handle asynchronous fetching of user menu data
            const response = await fetchUserMenudata(loggedInUserId);

            // Dispatch the response data to the Redux store
            dispatch(getuserroleandmenu(response.data));

            // Set the color code and language from the fetched data
            setBodyId(response.data.data.colorCodeDetails.bodyColorCode);

            // Store profile picture in local storage
            localStorage.setItem('profilePicture', response.data.data.userRoleDetails[0].profilePicture);

            // Update the profile picture state to reflect the change
            setStateProfilePicture(true);

            // Set the language from the response data
            setLanguage(response.data.data.language);

        } catch (err) {
            console.error("Error while fetching user details: ", err);
        }
    };

    const getvalidateuser = () => {
        validateuser(loggedInUserId)
            .then(response => {
                if (response.data.success == false) {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                    localStorage.removeItem('tekaVerseToken');
                    history.push("/");
                }
                else {
                    dispatch(validateuserdata(response.data))
                    setcountNewSupportRequest(response.data.count);
                }
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    return (
        <React.Fragment>
            <div className="custom">
                <div>
                    {stateProfilePicture ? (
                        <Header
                            supportRequestCount={supportCount == null ? countNewSupportRequest : supportCount}
                            language={language}
                        />
                    ) : null}
                </div>

                <div>
                    <div className="main_section" style={{
                        backgroundColor: bodyId,
                    }}>
                        <div> {children} </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}
export default Layout;