import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getContractedSiteCoverage, getSiteCoverageData } from "../../Redux/Api/fetch.api";
import Table from "antd/lib/table";
import '../../../src/calendar.css';

const CoverageModal = ({ isOpen, onClose, coverageDate, is12HrFormat }) => {
    // If the modal is not open, don't render anything
    if (!isOpen) return null;

    // useEffect : On page load 
    useEffect(() => {
        if (coverageDate) {
            getContractedSiteCoverages();
        }
    }, [coverageDate]);
    
    const [coverageTable, setCoverageTable] = useState([]);

    // State for dynamically updating chart data
    const timings = generateAllTimings();
    const [chartData, setChartData] = useState({
        series: [
            {
                name: "Site Coverage",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "rangeBar", // Changed chart type to rangeBar 
            },

            title: {
                text: "Support Site Coverage Over Time",
                align: "center",
            },
            xaxis: {
                categories: [], // Site names will be dynamically set
                title: {
                    text: "Sites", // X-axis label (Sites)
                },               
            },
            yaxis: {
                title: {
                    text: "Timings", // Y-axis label (Timing)
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                    },
                },
                labels: {
                    formatter: (value, index) => {
                        return index === 12 ? `${timings[index]}.` : timings[index] || value;
                    }, 
                    style: {
                        fontSize: "12px", // Increase font size for better visibility
                        colors: "#000", // Set text color for labels 
                    },
                    offsetX: 16, // Add padding to create space between the labels and the chart
                },
                min: 0, // Start y-axis from 0
                max: 24, // Adjust to match your data
                tickAmount: 24, // Number of ticks (to match the timing array)
            },
            plotOptions: {
                bar: {
                    borderRadius: 4, // Rounded bar edges
                    columnWidth: "20%",
                },
            },
            colors: ["#BA7B53"], // Bar color
            dataLabels: {
                enabled: false, // Disable data labels
            },
            tooltip: {
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    // Access the specific series data for the current index
                    const seriesData = w.config.series[seriesIndex]?.data;
                    if (!seriesData || !Array.isArray(seriesData)) {
                        return "Invalid Data";
                    }

                    // Get the specific data point
                    const dataPoint = seriesData[dataPointIndex];
                    if (!dataPoint || !dataPoint.y || dataPoint.y.length !== 2) {
                        return "Invalid Data";
                    }

                    const [start, end] = dataPoint.y; // Extract start and end times

                    // Format the time with AM/PM
                    const formatTime = (value) => {
                        const hours = Math.floor(value);
                        const minutes = Math.round((value - hours) * 60);
                        const formattedMinutes = minutes === 0 ? "00" : minutes.toString().padStart(2, "0");
                        const period = hours < 12 ? "AM" : "PM";
                        const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
                        return `${formattedHours}:${formattedMinutes} ${period}`;
                    };

                    const startTime = formatTime(start);
                    const endTime = formatTime(end);

                    // Return the HTML content for the tooltip
                    return `
            <div style="
                padding: 6px 12px;
                position: relative;
                font-size: 12px;
                color: #fff;
                background: #333;
                border-radius: 4px;
                text-align: center;
            ">
                <strong>${startTime} - ${endTime}</strong>
            </div>`;
                },
                shared: false, // Disable shared tooltips
                intersect: true, // Tooltip only shows when directly hovering the bar
                fixed: {
                    enabled: true,
                    position: "topRight", // Adjust position (e.g., "topRight", "topLeft", "bottomRight", etc.)
                    offsetX: -10,
                    offsetY: 430, // Fine-tune alignment
                },
            }
        },
    });

    const [paginationState, setPaginationState] = useState({
        currentPage: 1,
        pageSize: 5,
    });

    // Fetch Contracted Site Coverages data based on selected day
    const getContractedSiteCoverages = async () => {
        try {
            const day = getDay(coverageDate);
            const response = await getContractedSiteCoverage(day);

            if (response.data && response.data.data.length > 0) {
                const dynamicData = response.data.data;

                setChartData((prev) => ({
                    ...prev,
                    series: [{ ...prev.series[0], data: dynamicData }],
                    options: {
                        ...prev.options,
                        xaxis: {
                            categories: [...new Set(dynamicData.map((item) => item.x))],
                        },
                    },
                }));

                fetchSiteCoverageData(day);
            } else {
                console.warn("No site coverage data available for the selected date.");
            }
        } catch (error) {
            console.error("Error fetching site coverage data:", error);
        }
    };

    // Fetch Contracted Site Coverages data based on selected day
    const fetchSiteCoverageData = async (day) => {
        try {
            if (!day) {
                console.warn("The 'day' parameter is required to fetch site coverage data.");
                return;
            }

            const response = await getSiteCoverageData(day);

            if (response?.data?.data?.length > 0) {
                // Add logic to process or display the fetched data
                setCoverageTable(response.data.data);
            } else {
                console.warn("No site coverage data available for the provided day:", day);
            }
        } catch (error) {
            console.error("Error fetching site coverage data for the day:", day, error);
        }
    };

    // Helper to get the day name
    const getDay = (coverageDate = new Date()) => {
        const date = new Date(coverageDate);
        if (isNaN(date)) {
            throw new Error("Invalid date format");
        }
        return date.toLocaleDateString(undefined, { weekday: "long" });
    };

    // Helper to get formatted date
    const getDate = (coverageDate = null) => {
        coverageDate = coverageDate || new Date();
        return coverageDate.toLocaleDateString();
    };

    // Generate All Timings
    function generateAllTimings() {
        return Array.from({ length: 25 }, (_, i) => {
            if (is12HrFormat) {
                // 12-hour format logic
                return i === 0
                    ? "12:00 AM" // Midnight
                    : i < 12
                        ? `${i.toString().padStart(2, "0")}:00 AM` // Morning hours
                        : i === 12
                            ? "12:00 PM" // Noon
                            : `${(i - 12).toString().padStart(2, "0")}:00 PM`; // Afternoon/evening
            } else {
                // 24-hour format logic
                return `${i.toString().padStart(2, "0")}:00`;
            }
        });
    }

    // We can check if a number is not an integer, which means it has a fractional part (and is therefore a decimal).
    function isDecimal(value) {
        return !Number.isInteger(value);
    }

    // Using the pagination object to access current page, pageSize
    const handleChange = (pagination, filters, sorter) => {
        setPaginationState({
            currentPage: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    // Coverages columns
    const columns = [
        {
            title: 'Site',
            dataIndex: 'siteName',
            key: 'siteName',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Time Zone',
            dataIndex: 'timeZone',
            key: 'timeZone',
            render: (timeZone) => (
                // Assuming timeZone is a string representing the time zone
                getAbbreviation(timeZone)
            ),
        },
        {
            title: 'Local Start',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (startTime) => (
                convertTo12HrFormat(startTime, is12HrFormat) // Convert to 12-hour format for display
            ),
        },
        {
            title: 'Local Stop',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (endTime) => (
                convertTo12HrFormat(endTime, is12HrFormat) // Convert to 12-hour format for display
            ),
        },
        {
            title: 'GMT Start',
            dataIndex: 'gmtStartTime', 
            key: 'gmtStartTime',
            render: (gmtStartTime) => (
                convertTo12HrFormat(gmtStartTime, is12HrFormat) // Convert to 12-hour format for display
            ),
        },
        {
            title: 'GMT Stop',
            dataIndex: 'gmtEndTime',
            key: 'gmtEndTime',
            render: (gmtEndTime) => (
                convertTo12HrFormat(gmtEndTime, is12HrFormat) // Convert to 12-hour format for display
            ),
        },
    ];

    // Dynamic Classes 
    const rowClassName = (record, index) => {
        // Alternate the row color by checking if the index is even or odd
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    
    // Get Time Zone Abbreviation
    function getAbbreviation(timezone) {
        return timezone ? timezone.split(" ").map(word => word[0]).join("") : "";
    }

    // Convert To 12Hr Format
    function convertTo12HrFormat(timeStr, is12HrFormat) {
        // Check if timeStr is null, undefined, or an empty string
        if (!timeStr) {
            return "-"; // Return a default value or message
        }

        // Split the timeStr into hours, minutes, and seconds
        const [hours, minutes, seconds] = timeStr.split(":").map(Number);

        // Validate that hours, minutes, and seconds are valid numbers
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return "-"; // Return a default value or message
        }

        // For the 24-hour format, you want to retain the hour
        let period = hours >= 12 ? "PM" : "AM";

        if (is12HrFormat) {
            // Format the time as "HH:mm PM" where HH is the 12-hour format
            let hoursIn12HrFormat = hours % 12 || 12; // Convert to 12-hour format, 0 becomes 12
            return `${String(hoursIn12HrFormat).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${period}`;
        } else {
            // Return the original 24-hour format if is12HrFormat is false
            return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        }
    }
    
    return (
        <div className="view-report-modal" onClick={onClose}>
            <div
                className="view-report-modal-content"
                onClick={(e) => e.stopPropagation()}
                role="dialog"
                aria-labelledby="coverage-modal-title"
            >
                {/* Close icon */}
                <div className="modal-header">
                <h2>Contracted Site Coverage</h2>
                    <span className="coverage-modal-title">
                        {getDay(coverageDate)} | {getDate(coverageDate)}
                    </span>

                    <div>
                        <span className="right-button">
                            GMT
                        </span>
                        <span className="view-report-close" onClick={onClose}>
                            &times;
                        </span>
                    </div>
                </div>

                {/* Range Bar chart */}
                <div className="view-report-content">
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="rangeBar"
                        height={850}
                       // width={1200} 
                    />

                    <div className="Viewer-table coverage-table mb-4 ">
                        <Table
                            className="table-responsive antd--detail-table1"
                            columns={columns}
                            dataSource={coverageTable}
                            pagination={{
                                pageSize: paginationState.pageSize, // Display 5 rows per page
                                total: coverageTable.length, // Total rows
                                current: paginationState.currentPage, // Current page
                                showSizeChanger: false,
                            }}
                            onChange={handleChange}
                            paginationTotalRows={coverageTable.length}
                            rowClassName={rowClassName} // Apply the alternating row color class
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoverageModal;