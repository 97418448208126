import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import Loader from "../loader";
import Add from "../../assets/images/Add.svg";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { getsupportgroupdata, updatecallorderdata, deletecallorderdata, updategroupname, deletegroupdata, getusersforsupport } from '../../Redux/Actions/CallOrderActions';
import { getuserroleandmenu, getusersandroles } from '../../Redux/Actions/UsersActions';
import { updateusername, savegroupandusers } from '../../Redux/Actions/CallOrderActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { Modal, Button } from "react-bootstrap";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Input, Form, FormFeedback } from "reactstrap"
import { Checkbox, Select, Collapse, Tooltip, Popover } from "antd";
import Trash_light from "../../assets/images/Trash_light.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import XLSIcon from "../../assets/images/dropdownFile.svg";
import * as url from "../../api/uri_helper";
import {
    getSupportEventByGroupId, postSupportEvent, putSupportEvent, deleteSupportEvent, fetchSupportGroupdata,
    fetchUserRoledata, fetchUsersWithRoles, putSupportDataOnCalendar, fetchUsersForSupportGroup, getSupportGroupSites
} from "../../Redux/Api/fetch.api";
import axios from "axios";
import MenuIcon from '@mui/icons-material/Menu';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import GroupEditIcon from "../../assets/images/groupedit.svg";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import addPeople from "../../assets/images/add_person.svg"
import logicon from "../../assets/images/log-icon.svg";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import { Accordion, AccordionItem } from 'smart-webcomponents-react/accordion';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Menu, Dropdown } from 'antd';
import { utils, writeFile } from 'xlsx';
import { Calendar } from 'smart-webcomponents-react/calendar';
import { Tree, TreeItem, TreeItemsGroup } from 'smart-webcomponents-react/tree';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SupportTree from "./SupportTree";
import { useTranslation } from "react-i18next";
import Title from "../Title";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const Option = Select.Option;
const SupportGroupIndex = () => {
    const { t } = useTranslation('supportGroup');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [roleName, setRoleName] = useState(null);
    const loggedUserId = localStorage.getItem('UserId')
    const [tableChild, setTableChild] = useState();
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [editable, setEditable] = useState(false);
    const [editableRow, setEditableRow] = useState(null);
    const [groupeditable, setGroupEditable] = useState(false);
    const [isaddnewgroup, setIsAddNewGroup] = useState(false);
    const [groupeditableRow, setGroupEditableRow] = useState(null);
    const [userId, setUserId] = useState();
    const [draggroupid, setDragGroupId] = useState();
    const [allUsers, setAllUsers] = useState([]);
    const [comingdata, setComingData] = useState([]);
    const [cominguserId, setComingUserId] = useState();
    const [calenderusername, setCalenderUserName] = useState();
    const [calendergroupname, setCalenderGroupName] = useState();
    const [newuserId, setNewUserId] = useState();
    const [comingcallorderId, setComingCallOrderId] = useState();
    const [comingindex, setComingIndex] = useState();
    const [newdata, setNewData] = useState([]);
    const [newcallorderId, setNewCallOrderId] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteGroupModal, setDeleteGroupModal] = useState(false);
    const [notificationId, setNotificationId] = useState();
    const [deletegroupId, setDeleteGroupId] = useState();
    const [editingKey, setEditingKey] = useState(null);
    const [groupname, setGroupName] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isusercheck, setIsUserCheck] = useState(false);
    const [isapplycheck, setIsApplyCheck] = useState(false);
    const [isapplytechniciancheck, setIsApplyTechnicianCheck] = useState(false);
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [isTechniciancheck, setIsTechniciancheck] = useState(false);
    const [isTechnicianpopoverclick, setIsTechnicianPopoverClick] = useState(false);
    const [showGroupName, setShowGroupNames] = useState("")
    const [getAlreadyExistUserId, setAlreadyExistUserId] = useState({})
    const [getGroupId, setGroupId] = useState()
    const [addNewTecnician, setAddNewTechnician] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [visiblePopover, setVisiblePopover] = useState(null);
    const location = useLocation();
    const [expandedGroup, setExpandedGroup] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [selectedSupportUserGroupID, setselectedSupportUserGroupID] = useState(0);
    const history = useHistory();
    const [passedSiteId, setPassedSiteId] = useState(0);
    const [passedSupportId, setPassedSupportId] = useState(0);
    const [supportSites, setSupportSites] = useState([null]);
    const scheduler = useRef();
    const view = 'month';
    const today = new Date();
    const dateCurrent = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const keyboardNavigation = true;
    const currentDate = dateCurrent.getDate();
    const currentYear = dateCurrent.getFullYear();
    const currentMonth = dateCurrent.getMonth();
    const currentDayOfWeek = today.getDay();

    // Format date fields
    function formatDate(settings) {
        settings.value = new Date(settings.value).toString();
    }

    useEffect(() => {
        const fetchData = async () => {
            GetSupportGroupDetails();
            getRoleDetails();
            getAllSupportAssociatedSites();
            // getalluserswithroles();
            GetUsersForSupportGroup();
            setDataLoaded(true); // Set to true once all data is loaded
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (newcallorderId > 0 && newuserId !== cominguserId)
            updateCallOrderData();
    }, [newcallorderId]);

    useEffect(() => {

        if (dataLoaded) {
            const queryParams = new URLSearchParams(location.search);
            const groupName = queryParams.get('group');
            const siteIdFromUrl = queryParams.get('siteId');
            const supportIdFromUrl = queryParams.get('supportId');

            if (groupName) {
                const group = table.find(item => item.groupName === groupName);
                if (group) {

                    setPassedSiteId(siteIdFromUrl);
                    setPassedSupportId(supportIdFromUrl);
                    handleExpand(true, { key: group.groupId, users: group.users });

                    queryParams.delete('group');
                    queryParams.delete('siteId');
                    queryParams.delete('supportId');

                    history.replace({ search: queryParams.toString() });
                }
            }

        }
    }, [dataLoaded, location.search, table]);


    const GetSupportGroupDetails = () => {
        setLoading(true)
        fetchSupportGroupdata()
            .then(response => {

                dispatch(getsupportgroupdata(response.data))
                setTable(response.data.data)
                setTableChild(response.data.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const GetUsersForSupportGroup = () => {
        setLoading(true)
        fetchUsersForSupportGroup()
            .then(response => {
                dispatch(getusersforsupport(response.data))
                const filteredUsers = response.data.data;
                setAllUsers(filteredUsers)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const getRoleDetails = () => {
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getAllSupportAssociatedSites = async () => {
        try {
            const response = await getSupportGroupSites(loggedUserId);

            if (response.data && response.data.data) {
                setSupportSites(response.data.data); // Check if both events are present
            } else {
                console.error('No events returned from API.');
            }
        }
        catch (error) {
            console.error('Error initializing scheduler:', error);
        }
    };

    const exportAsExcel = () => {
        fetchSupportGroupdata()
            .then(response => {
                const groupData = response?.data?.data || [];

                if (!Array.isArray(groupData)) {
                    console.error("Expected groupData to be an array, but got:", groupData);
                    return;
                }

                const todayDate = new Date();
                todayDate.setHours(todayDate.getHours() - 5);
                const formattedDateTime = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()} ${todayDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

                let assignedColumns = [
                    "Sr. No.",
                    "Group Id",
                    "Group Name",
                    "User Id",
                    "User Name",
                    "Order Id"
                ];

                // Center alignment style object
                const centerAlignmentStyle = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    },
                    font: {
                        name: 'Arial',
                        sz: 12
                    }
                };

                const generateRows = (groups) => {
                    let rows = [];
                    groups.forEach((group, groupIndex) => {
                        if (group && Array.isArray(group.users)) {
                            group.users.forEach((user, userIndex) => {
                                rows.push([
                                    { v: rows.length + 1, s: centerAlignmentStyle }, // Sr. No.
                                    { v: group.groupId || "", s: centerAlignmentStyle }, // Group Id
                                    { v: group.groupName || "", s: centerAlignmentStyle }, // Group Name
                                    { v: user.userId || "", s: centerAlignmentStyle }, // User Id
                                    { v: user.userName || "", s: centerAlignmentStyle }, // User Name
                                    { v: user.orderId || "", s: centerAlignmentStyle }, // Order Id
                                ]);
                            });
                        }
                    });
                    return rows;
                };

                let combinedData = [
                    [],
                    [{ v: `Project: TEKaVerse`, s: centerAlignmentStyle }],
                    [
                        { v: `Extracted Date:`, s: centerAlignmentStyle },
                        { v: `${formattedDateTime}`, s: centerAlignmentStyle },
                        "", "", "", "", "", "", "",
                        { v: "Powered By : AuthenTEK", s: centerAlignmentStyle }
                    ],
                    [],
                    assignedColumns.map(col => ({ v: col, s: centerAlignmentStyle })), // Adding the header row with the column names
                    ...generateRows(groupData)
                ];

                const currentDate = new Date();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');
                const year = currentDate.getFullYear();
                const hours = String(currentDate.getHours()).padStart(2, '0');
                const minutes = String(currentDate.getMinutes()).padStart(2, '0');

                let wb = utils.book_new();
                let ws = utils.aoa_to_sheet(combinedData);

                // Set column widths (optional)
                ws['!cols'] = assignedColumns.map(col => ({ wpx: 120 }));

                // Apply styles to all cells with data
                const range = utils.decode_range(ws['!ref']);
                for (let R = range.s.r; R <= range.e.r; ++R) {
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        const cellAddress = utils.encode_cell({ r: R, c: C });
                        if (ws[cellAddress] && !ws[cellAddress].s) {
                            ws[cellAddress].s = centerAlignmentStyle;  // Apply style if not already styled
                        }
                    }
                }

                utils.book_append_sheet(wb, ws, `Support Group List`);
                const downloadFileName = `Support_Group_${year}${month}${day}_${hours}${minutes}.xlsx`;

                writeFile(wb, downloadFileName);
            })
            .catch((err) => {
                console.log("Error exporting data to Excel:", err);
            });
    };



    const handleAddTechnician = (id, users, name) => {
        setIsTechnicianPopoverClick(true)

        setAddNewTechnician(!addNewTecnician);
        setShowGroupNames(name)
        setAlreadyExistUserId(users)
        setGroupId(id)
    }

    const handleTechnician = (userId) => {
        setSelectedTechnicians((prev) => {
            let updatedTechnicians;
            if (prev.includes(userId)) {
                updatedTechnicians = prev.filter(sel => sel !== userId);
            } else {
                updatedTechnicians = [...prev, userId];
            }

            // Update the `isTechniciancheck` state after updating the technicians
            if (updatedTechnicians.length === 0) {
                setIsTechniciancheck(false);
            } else {
                setIsTechniciancheck(true);
            }
            return updatedTechnicians;
        });
    }

    const handleCancelNewGroup = () => {
        setIsAddNewGroup(false);
        setIsUserCheck(false);
        setSelectedUsers([])
        setGroupName("")
    }

    const handleCancelPopOver = () => {
        setVisiblePopover(null);
        setIsTechniciancheck(false)
        setSelectedTechnicians([]);
    }

    const DeleteGroup = async () => {
        setLoading(true); // Start loading
        setDeleteGroupModal(false); // Close the modal

        try {
            const response = await axios.delete(`${url.DELETE_GROUP}/${deletegroupId}`);

            // Dispatch the successful deletion
            dispatch(deletegroupdata(response.data));

            // Show success toast
            toast.success(t("GroupDeletedSuccessfully"), {
                className: "toastSuccess",
            });

        } catch (err) {
            console.error("Error deleting group:", err);
            toast.error(t("Failedtodeletegroup"), { // Show error toast
                className: "toastError",
            });
        } finally {
            setLoading(false); // Ensure loading is turned off regardless of success or failure
            window.location.reload(); // Reloads the current page
        }
    };

    const updateUserData = async (notid, callid, grpid) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("UserId", userId);
        formData.append("OrderId", callid);
        formData.append("Id", notid);
        formData.append("GroupId", grpid);
        formData.append("LoggedId", loggedUserId);
        const response_ = await axios({
            method: 'put',
            url: url.UPDATE_GROUPUSERNAME,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("CallOrderorUserAlreadyExists"), {
                    className: "toastSuccess",
                });

            }
            else {
                dispatch(updateusername(response.data))
                toast.success(t("Usernameupdatedsuccessfully"), {
                    className: "toastSuccess",
                });
                setUserId()
                GetSupportGroupDetails();
                setEditable(false)

            }
            setLoading(false)
        })

    }

    const AddNewGroupMembers = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("GroupName", groupname);
        formData.append("LoggedId", loggedUserId);
        selectedUsers.forEach(user => formData.append("users[]", user));
        const response_ = await axios({
            method: 'post',
            url: url.POST_GROUPANDUSERS,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("GroupAlreadyExists"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(savegroupandusers(response.data))
                toast.success(t("GroupandUsersAddedsuccessfully"), {
                    className: "toastSuccess",
                });
                GetSupportGroupDetails();
                GetUsersForSupportGroup();
                setSelectedUsers([])
                setIsAddNewGroup(false)
                setGroupName("")
                setIsUserCheck(false)
            }
            setLoading(false)
        })
    }

    const AddUsersInGroup = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("GroupId", getGroupId);
        formData.append("LoggedId", loggedUserId);
        selectedTechnicians.forEach(user => formData.append("users[]", user));
        const response_ = await axios({
            method: 'post',
            url: url.POST_USERSINOLDGROUP,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("CompanyAlreadyCreated"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(savegroupandusers(response.data))
                toast.success(t("GroupandUsersAddedsuccessfully"), {
                    className: "toastSuccess",
                });
                GetSupportGroupDetails();
                setVisiblePopover(null)
                setSelectedTechnicians([])
                setIsTechniciancheck(false)
                setIsTechnicianPopoverClick(false)
                GetUsersForSupportGroup();

            }
            setLoading(false)
        })
    }

    const updateGroupName = async (grpid) => {
        if (groupname.length <= 0) {
            toast.error(t("PleaseEnterGroupName"), {
                className: "toastSuccess",
            });
            return;
        }
        setLoading(true)
        const formData = new FormData();
        formData.append("GroupName", groupname);
        formData.append("GroupId", grpid);
        const response_ = await axios({
            method: 'put',
            url: url.UPDATE_GROUPNAME,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("GroupNameAlreadyExists"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updategroupname(response.data))
                toast.success(t("Groupnameupdatedsuccessfully"), {
                    className: "toastSuccess",
                });
                setGroupName()
                GetSupportGroupDetails();
                setGroupEditable(false)
                setGroupEditableRow()

            }
            setLoading(false)
        })

    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleDelete = (id) => {
        setNotificationId(id)
        setDeleteModal(true);
    };

    const handleGroupDelete = (id) => {
        setDeleteGroupId(id)
        setDeleteGroupModal(true);
    };

    const handleDeleteGroupModalClose = () => {
        setDeleteGroupModal(false);
    };

    const updateCallOrderData = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("oldUserId", cominguserId);
        formData.append("newUserId", newuserId);
        formData.append("oldCallOrderId", comingcallorderId);
        formData.append("newCallOrderId", newcallorderId);
        formData.append("GroupId", draggroupid);
        formData.append("LoggedId", loggedUserId);
        const response_ = await axios({
            method: 'put',
            url: url.UPDATE_CALLORDERDATA,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("CallOrderorUserAlreadyExists"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updatecallorderdata(response.data))
                toast.success(t("CallOrderupdatedsuccessfully"), {
                    className: "toastSuccess",
                });

                setUserId()
                GetSupportGroupDetails();
                setComingUserId();
                setNewUserId();
                setComingCallOrderId();
                setNewCallOrderId();

            }
            setLoading(false)
        })

    }

    const DeleteCallOrderRecord = async () => {

        setLoading(true)
        await axios.delete(`${url.DELETE_CALLORDER}/${notificationId}/${loggedUserId}`).then(response => {

            dispatch(deletecallorderdata(response.data))
            //setTimeout(() => {
            setLoading(false)
            //}, 1000)
            toast.success(t("CallOrderDeletedSuccessfully"), {
                className: "toastSuccess",
            },);
            setselectedSupportUserGroupID(0);
            setDeleteModal(false)
            GetSupportGroupDetails()
            getAllSupportAssociatedSites()

            GetUsersForSupportGroup();
        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)

    }

    const handleCancel = () => {
        setEditable(false)
        setUserId();
    }

    const handleGroupCancel = () => {
        setGroupEditable(false)
        setGroupName();
    }

    const handleEdit = (Id) => {
        setEditable(true)
        setEditableRow(Id);
    }

    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleInputForDropdown = (input) => {
        const filtered = allUsers && allUsers.filter(item => {
            const fullName = item.lastName + " " + item.firstName;
            return fullName.toLowerCase().includes(input.toLowerCase());
        });
        setFilteredOptions(filtered);
    };

    const handleGroupNameChange = (e) => {
        setGroupName(e.target.value);
    };

    const handleGroupName = (record) => {

        setGroupEditable(true)
        setGroupEditableRow(record.groupId);
        setGroupName(record.groupName);
    };

    const handleNewGroup = () => {
        setIsAddNewGroup(!isaddnewgroup);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePopoverVisibleChange = (groupId, visible) => {
        if (visible) {
            setVisiblePopover(groupId); // Show the Popover for the clicked group
        } else {
            setVisiblePopover(null); // Hide the Popover
        }
    };

    const columns = [
        {
            title: "",
            dataIndex: "groupName",
            key: "groupName",
            render: (text, record) => (
                <>
                    {groupeditable && groupeditableRow === record.groupId ?
                        <>
                            <div className="row align-items-center m-0 row-gap-5">
                                <div className="col-md-3 pl-0">
                                    <input
                                        name="groupname"
                                        className="form-control edit-new-groupname"
                                        type="text"
                                        value={groupname}
                                        onChange={handleGroupNameChange}
                                    />
                                </div>

                                <ul className="list-inline d-flex">
                                    <li className="list-inline-item">
                                        <button className="group-btn-primary w-auto px-2 text-white "
                                            onClick={() => updateGroupName(record.groupId)}
                                        >
                                            {t("Update")}
                                        </button>
                                    </li>

                                    <li className="list-inline-item">
                                        <button className="group-btn w-auto px-2 text-white btn-primary"
                                            onClick={() => handleGroupCancel(record.groupId)}
                                        >
                                            {t("Cancel")}
                                        </button>
                                    </li>
                                </ul>
                            </div>

                        </>

                        :

                        <span className="group-name">
                            {text}
                            <div className="add-edit-group-member">
                                <div className="mr-2">
                                    <Tooltip title="Click to view support calendar">
                                        <CalendarMonthIcon style={{ cursor: "pointer" }}
                                            onClick={(event) => {
                                                event.stopPropagation(); // Prevents triggering the button's onClick
                                                handleSupportCalendar(record.groupId); // Icon-specific action
                                            }}
                                        />
                                    </Tooltip>
                                </div>

                                <div className="logs-icon rounded">
                                    <Tooltip title={t("Clicktoviewsupportlogs")}>
                                        <button className=""
                                            onClick={() => handleSupportLogs(record.groupId)}
                                        >
                                            <img class="w-100" src={logicon}
                                                className="apply-img calender-log"></img>
                                            {/*Logs*/}
                                        </button>
                                    </Tooltip>
                                </div>

                                <Tooltip title={t("AddTechnician")} >
                                    <Popover content={content}
                                        trigger="click"
                                        placement="bottom"
                                        visible={visiblePopover === record.groupId}
                                        onVisibleChange={(visible) => handlePopoverVisibleChange(record.groupId, visible)}
                                        className=""
                                    >
                                        <img
                                            src={addPeople}
                                            className="add-group-member"
                                            onClick={() => handleAddTechnician(record.groupId, record.users, record.groupName)}
                                        />
                                    </Popover>
                                </Tooltip>

                                <Tooltip title={t("Clicktoedit")}>
                                    <img
                                        src={GroupEditIcon}
                                        className="group-edit-icon"
                                        onClick={() => handleGroupName(record)}
                                    />
                                </Tooltip>

                                <Tooltip title={t("Clicktodelete")}>
                                    <button className="delete-btn" >
                                        <img
                                            src={Trash_light}
                                            // className="table-btn"
                                            onClick={() => handleGroupDelete(record.groupId)}
                                        />
                                    </button>
                                </Tooltip>
                            </div>
                        </span>
                    }
                </>
            ),
        },
    ]

    const handleExpand = (expanded, record) => {

        setVisiblePopover(null)
        setExpandedRowKey(expanded ? [record.key] : []);
        setAlreadyExistUserId(record.users)
    };

    const handleDragStart = (record, e, index) => {
        setComingData(record);
        setComingCallOrderId(index + 1)
        setComingIndex(index)
        setComingUserId(record.userId)
        setDragGroupId(record.groupId)
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (record, e, targetIndex) => {
        e.preventDefault();
        const newData = Array.from(table);
        const [draggedItem] = newData.splice(comingindex, 1);
        newData.splice(targetIndex, 0, draggedItem);
        //  setTable(newData);
        setNewData(record)
        setNewCallOrderId(targetIndex + 1)
        setNewUserId(record.userId)

    };

    const SearchControl = async () => {
        AddNewGroupMembers();
    }

    const handleDragEnd = (result) => {
        debugger;
        if (!result.destination) return;
        const items = Array.from(table);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTable(items);
    };

    const scrollerStyle = {
        maxHeight: '250px', // Adjust the height as needed
        overflowY: 'auto',
    };

    //const filteredUsers = allUsers.filter(user =>
    //    user.userName.toLowerCase().includes(searchQuery.toLowerCase())
    //);

    const filteredUsers = allUsers.filter(user =>
        (user.lastName + " " + user.firstName).toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleUser = (userId) => {
        setSelectedUsers((prev) => {
            let updatedUsers;
            if (prev.includes(userId)) {
                updatedUsers = prev.filter(sel => sel !== userId);
            } else {
                updatedUsers = [...prev, userId];
            }

            // Update the `isUserCheck` state after updating the users
            if (updatedUsers.length === 0) {
                setIsUserCheck(false);
            } else {
                setIsUserCheck(true);
            }

            return updatedUsers;
        });
    }

    const handleAddNewTechnician = () => {
        AddUsersInGroup();
    }

    const expandedRowRender = (record) => {

        const nestedcolumns = [

            {
                title: t("DragMe"),
                dataIndex: "",
                render: (value, item, index) =>
                (<>
                    <div className="d-flex align-items-center">
                        <Tooltip title={t("Rowdraganddrop")}>
                            <MenuIcon fontSize="small" />
                        </Tooltip>
                    </div>
                </>)
            },

            {
                title: t("Priority"),
                dataIndex: "orderId",
                key: "orderid"
            },

            {
                title: t("TechnicianId"),
                dataIndex: "userId",
                key: "userid"
            },

            {
                title: t("TechnicianName"),
                dataIndex: "",
                render: (text, record, index) =>
                (<>
                    {editable && editableRow === record.id ?
                        <div className="select-Company comdropdown mr-2">
                            <Select className="company-drop callorder"
                                placeholder={t("SelectUser")}
                                showSearch
                                // value={userId}
                                defaultValue={record.userName}
                                // labelInValue
                                // key={record.callOrderId}
                                onChange={(value) => {
                                    setUserId(value)

                                }}
                                onSearch={(value) => {
                                    handleInputForDropdown(value)
                                }}
                                filterOption={false}
                                style={{ width: '100%' }}
                            >
                                {filteredOptions.length > 0 ?
                                    filteredOptions.filter(item => item.userId !== record.userId).map(item =>
                                        <Option key={item.userId} value={item.userId}>
                                            {item.lastName + " " + item.firstName}
                                        </Option>
                                    )
                                    :

                                    getAlreadyExistUserId.length > 0 && allUsers
                                        .filter(item => !getAlreadyExistUserId.some(user => user.userId === item.userId))
                                        .map((item) => (
                                            <Option key={item.userId} value={item.userId}>
                                                {item.lastName + " " + item.firstName}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </div>
                        :
                        <label>{record.userName}</label>
                    }
                </>
                )
            },

            {
                title: " ",
                className: "sitebuttons",
                dataIndex: "",
                render: (data, companyId) => (<>

                    {editable && editableRow === data.id ?
                        <ul className="list-inline d-flex">

                            <>
                                <li className="list-inline-item">
                                    <button className="table-btn w-auto px-2 text-white btn-primary" disabled={!(userId && userId !== "Select User")}
                                        onClick={() => updateUserData(data.id, data.orderId, data.groupId)}>
                                        {t("Update")}
                                    </button>
                                </li>

                                <li className="list-inline-item">
                                    <button className="table-btn w-auto px-2 text-white" onClick={handleCancel}>
                                        {t("Cancel")}
                                    </button>
                                </li>
                            </>
                        </ul>
                        : ''
                    }
                </>)
            },

            {
                title: t("Actions"),
                dataIndex: "",

                render: (data, companyId) => (
                    <Dropdown className="action-dropdown"
                        overlay={
                            <Menu>
                                <Menu.Item key="edit" onClick={() => handleEdit(data.id)}>
                                    {t("Edit")}
                                </Menu.Item>
                                <Menu.Item key="delete" onClick={() => handleDelete(data.id)}>
                                    {t("Delete")}
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                        placement="bottomRight"
                    >
                        <a className="ant-dropdown-link d-Inline-flex justify-content-between align-items-center" onClick={e => e.preventDefault()}>
                            <span>{t("Actions")} </span>
                            {/*<DownOutlined style={{ fontSize: '8px' }}*/}
                            {/*/>*/}
                        </a>
                    </Dropdown>
                )
            },

            {
                title: t("TodayStatus"),
                dataIndex: "",
                render: (value, item, index) =>
                (<>
                    {item.isPresentToday == true ?
                        <span className="activeWorkItem">{t("Present")}</span>
                        :
                        <span className="closedWorkItem">{t("Absent")}</span>
                    }
                </>)
            },
        ]
        return (
            <div className="Viewer-table index--table position-relative common-table data-table mb-4 custom-child-table">
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="rows">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <Table
                                    columns={nestedcolumns}
                                    dataSource={record.users}
                                    rowKey={(record) => record.OrderId}
                                    onRow={(record, index) => ({
                                        onDragStart: (e) => handleDragStart(record, e, index),
                                        onDragOver: (e) => handleDragOver(e),
                                        onDrop: (e) => handleDrop(record, e, index),
                                        draggable: true,
                                    })}
                                    pagination={false}
                                />

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }

    const content = (
        <div className="add-group" >

            <div className="new-group-form">
                <label className="Group-name">{t("Groupname:")} {showGroupName}</label>
                <div className="group-drop dropdown group-dropbox-btn">
                    <button
                        className="btn w-100 text-left text-white dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {t("SelectNewGroupMembers")}
                    </button>
                    <ul
                        className="dropdown-menu roles-dropbox"
                        aria-labelledby="dropdownMenuButton1"
                        style={scrollerStyle}
                    >
                        <li className="px-3">
                            <input
                                type="text"
                                className="form-control mb-2"
                                placeholder={t("SearchByUserName")}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </li>
                        {getAlreadyExistUserId.length > 0 && filteredUsers
                            .filter(item => !getAlreadyExistUserId.some(user => user.userId === item.userId))
                            .map((item) => (
                                <li key={item.userId} className="px-3">
                                    <a className="dropdown-item">
                                        <input
                                            type="checkbox"
                                            className="form-check-input cursor-pointer"
                                            name="checkname"
                                            checked={selectedTechnicians.includes(item.userId)}
                                            onChange={() => handleTechnician(item.userId)}
                                        />
                                        <label className="form-check-label ml-2 non-clickable" htmlFor={`check${item.userId}`}>
                                            {`${item.lastName} ${item.firstName}`}
                                        </label>
                                    </a>
                                </li>
                            ))}
                        <li className="px-3 py-2 text-right clear-btn">
                            <div className="d-flex">
                                <button
                                    className="btn btn-primary w-100 me-2"
                                    onClick={() => {
                                        setSelectedTechnicians([]);
                                        setSearchQuery('');
                                        setIsTechniciancheck(false);
                                    }}
                                    disabled={!isTechniciancheck}
                                >
                                    {t("Clear")}
                                </button>
                                <button
                                    className="btn btn-primary grp-apply w-100"
                                    onClick={() => {
                                        setPopoverVisible(false);
                                        //setIsTechniciancheck(false)
                                    }}
                                    disabled={!isTechniciancheck}
                                >
                                    {t("Apply")}
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-flex justify-content-end gap-2">
                    <button className="btn btn-primary w-100 mt-3 me-2"
                        onClick={handleCancelPopOver}>{t("Cancel")}</button>
                    <button
                        className="btn btn-primary w-100 mt-3"
                        onClick={(e) => {
                            e.preventDefault();
                            handleAddNewTechnician();
                        }}
                        disabled={!isTechniciancheck}
                    >
                        {t("Add")}
                    </button>
                </div>
            </div>
        </div>
    );

    const headerDatePosition = 'far';

    const headerViewPositio = 'near';

    const scrollButtonsPosition = 'far';

    const openloadreport = () => {
        GetSupportGroupDetails();
    }

    const handleVisibleChange = (visible) => {
        setPopoverVisible(visible);
    };

    const handleSupportLogs = (groupid) => {
        var win = window.open(`/supportgrouplogs?groupid=${groupid}`);
        win.focus();
    }

    const handleSupportCalendar = (groupid) => {
        var win = window.open(`/SupportTechnician?groupId=${encodeURIComponent(groupid)}&supportId=${encodeURIComponent(0)}&siteId=${encodeURIComponent(0)}`);
        win.focus();
    }

    const CloseCalendar = () => {
        setselectedSupportUserGroupID(0);
    };

    function getInitials(name) {
        if (name) {
            const nameParts = name.split(' ');
            const initials = nameParts.map(part => part.charAt(0));
            const result = initials.join('');
            return result;
        }
    }

    return (
        <div>
            <Title title="Support Group"></Title>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    <Layout>
                        <section className="Company_section common calender-group">
                            <Toaster />
                            {loading ? <Loader /> : ''}
                            <div className="container banner-container">

                                <div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <h5 className="mb-4">{t("AssociatedSites")}</h5>
                                                {supportSites && supportSites.length > 0 ? (
                                                    <SupportTree data={supportSites} />
                                                ) : (
                                                    <div>{"No sites are available"}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="mt-4 w-100 position-relative wk-select mb-0">
                                                <h5 className="m-0 user-group-heading">{t("SupportGroupsTechnicians")}</h5>
                                                <div className="ml-auto d-flex gap-2 align-items-center">

                                                    <div>
                                                        <Tooltip title={t("Clicktodownloadsupportrequest")}>
                                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'transparent', color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => exportAsExcel(true)}>
                                                                <img style={{ filter: 'invert(3) brightness(9)' }} src={XLSIcon} /></button>
                                                        </Tooltip>
                                                    </div>

                                                    <div>
                                                        <Tooltip title={t("Clicktoloadsupportrequest")}>
                                                            <RestartAltIcon onClick={() => openloadreport()} style={{ color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} />
                                                        </Tooltip>
                                                    </div>

                                                    <button className="btn btn-primary d-flex align-items-center btn-newgroup group-log"
                                                        onClick={handleNewGroup}>
                                                        <GroupAddOutlinedIcon style={{ color: '#ffffff', marginRight: '10px' }} fontSize="small" />
                                                        {t("NewGroup")}
                                                    </button>

                                                    <div className="parent-container">
                                                        {isaddnewgroup && (
                                                            <div className="add-group">
                                                                <div className="new-group-form">
                                                                    <label className="form-label">{t("NewGroup")}</label>
                                                                    <div className="col-md-12 p-0">
                                                                        <input
                                                                            name="groupname"
                                                                            className="form-control edit-groupname"
                                                                            type="text"
                                                                            value={groupname}
                                                                            placeholder={t("Entergroupname")}
                                                                            onChange={handleGroupNameChange}
                                                                        />
                                                                    </div>
                                                                    <div className="group-drop dropdown group-dropbox-btn mt-3">
                                                                        <button className="btn w-100 text-left text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            {t("SelectNewGroupMembers")}

                                                                        </button>
                                                                        <ul className="dropdown-menu roles-dropbox" aria-labelledby="dropdownMenuButton1"
                                                                            style={scrollerStyle}
                                                                        >
                                                                            <li className="px-3">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control mb-2"
                                                                                    placeholder="Search By Technician"
                                                                                    value={searchQuery}
                                                                                    onChange={handleSearchChange}
                                                                                />
                                                                            </li>

                                                                            {filteredUsers.map((item) =>
                                                                                <li className="px-3">
                                                                                    <a className="dropdown-item">
                                                                                        <input type="checkbox" className="form-check-input cursor-pointer" name="checkname"
                                                                                            checked={selectedUsers.includes(item.userId)}
                                                                                            onChange={() => { handleUser(item.userId) }}
                                                                                        />
                                                                                        <label className="form-check-label ml-2 non-clickable" for="exampleCheck1">
                                                                                            {`${item.firstName} ${item.lastName}`}
                                                                                        </label>
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                            <li className="px-3 py-2 text-right clear-btn">
                                                                                <div className="d-flex">
                                                                                    <button
                                                                                        className="btn btn-primary w-100 me-2"
                                                                                        onClick={() => {
                                                                                            setSelectedUsers([])
                                                                                            setSearchQuery('');
                                                                                            setIsUserCheck(false)
                                                                                        }}
                                                                                        disabled={!isusercheck}
                                                                                    >
                                                                                        {t("Clear")}
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-primary w-100 "
                                                                                        onClick={() => {
                                                                                            setIsApplyCheck(false)
                                                                                            // setIsUserCheck(false)
                                                                                        }}
                                                                                        disabled={!isusercheck}
                                                                                    >
                                                                                        {t("Apply")}
                                                                                    </button>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>


                                                                    <div className="d-flex justify-content-end">
                                                                        <button className="btn btn-primary w-100 mt-3 me-2"
                                                                            onClick={handleCancelNewGroup}>{t("Cancel")}</button>
                                                                        <button
                                                                            className="btn btn-primary w-100 mt-3"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                SearchControl()
                                                                            }}
                                                                            disabled={!(isusercheck && groupname)}
                                                                        >
                                                                            {t("Add")}
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 custom-table">
                                                <Table
                                                    className="table-responsive antd--detail-table1"
                                                    columns={columns}
                                                    dataSource={table.map((item, index) => ({ ...item, key: item.groupId }))}
                                                    expandedRowRender={expandedRowRender}
                                                    expandedRowKeys={expandedRowKey}
                                                    onExpand={handleExpand}
                                                    pagination={false}

                                                ></Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>

                    <Modal
                        show={deleteModal}
                        onHide={handleDeleteModalClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-dialog-copy"
                    >
                        <Modal.Body>
                            <div class="copy-model">
                                <h5> {t("Areyousureyouwanttodeletethisrecord")}</h5>
                                <div class="w-100 mt-3 btn-yesno d-flex justify-content-end">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => DeleteCallOrderRecord()}
                                    >
                                        {t("Yes")}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary ml-2"
                                        onClick={handleDeleteModalClose}
                                    >
                                        {t("No")}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={deleteGroupModal}
                        onHide={handleDeleteGroupModalClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-dialog-copy"
                    >
                        <Modal.Body>
                            <div class="copy-model">
                                <h5> {t("Areyousureyouwanttodeletethisgroup")}</h5>
                                <div class="w-100 mt-3 btn-yesno d-flex justify-content-end">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => DeleteGroup()}
                                    >
                                        {t("Yes")}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary ml-2"
                                        onClick={handleDeleteGroupModalClose}
                                    >
                                        {t("No")}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </div>
    )
}
export default connect()(SupportGroupIndex);

