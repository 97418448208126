import React, { useState, useEffect } from "react";

const Technician = ({ technicians, handleDragStart, is12HrFormat, loading }) => {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        setTasks(technicians);
    }, [technicians]);
    
    // Helper function to parse time strings into fractional hour values
    const parseTime = (time) => {
        const [hours, minutes, seconds] = time.split(':').map(Number);

        // Calculate the fractional hours
        const fractionalHours = hours + minutes / 60 + seconds / 3600;

        // Check if the time is a decimal (i.e., has any fractional part)
        const isDecimal = fractionalHours % 1 !== 0; // If fractional part exists, it's a decimal

        return { time: fractionalHours, isDecimal };
    };

    const format12TimeZone = (timeSpan) => {
        // Check if timeSpan is null or invalid
        if (!timeSpan) return ""; // Return a fallback value if timeSpan is invalid

        // Parse the timeSpan string (HH:mm:ss format)
        const [hours, minutes] = timeSpan.split(":").map(Number);

        // Check for invalid time format
        if (isNaN(hours) || isNaN(minutes)) return "Invalid Time"; // Handle invalid format

        // Determine the period (AM/PM)
        const period = hours >= 12 ? "pm" : "am";

        // Format hours for 12-hour clock
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return formatted time
        return `${formattedHours}${formattedMinutes !== "00" ? `:${formattedMinutes}` : ""}${period}`;
    };

    const format24TimeZone = (formattedTime) => {

        // Check if formattedTime is null or invalid
        if (!formattedTime) return ""; // Return a fallback value if invalid

        // Regular expression to match 12-hour or 24-hour formats
        const match12Hour = formattedTime.match(/^(\d{1,2})(?::(\d{2}))?(am|pm)$/i);
        const match24Hour = formattedTime.match(/^(\d{2}):(\d{2}):(\d{2})$/);

        if (match24Hour) {
            // If the time is already in 24-hour format, return as it is
            const [_, hours, minutes] = match24Hour;
            return `${hours}:${minutes}`; // Drop the seconds if not needed
        }

        if (match12Hour) {
            // Handle 12-hour format (e.g., "2:15pm" or "12am")
            let [_, hours, minutes = "00", period] = match12Hour;
            hours = Number(hours);
            minutes = Number(minutes);

            // Validate hours and minutes
            if (isNaN(hours) || isNaN(minutes) || hours < 1 || hours > 12 || minutes < 0 || minutes > 59) {
                return "Invalid Time";
            }

            // Convert hours to 24-hour format
            if (period.toLowerCase() === "pm" && hours !== 12) {
                hours += 12;
            } else if (period.toLowerCase() === "am" && hours === 12) {
                hours = 0;
            }

            // Format hours and minutes into HH:mm
            const formattedHours = hours.toString().padStart(2, "0");
            const formattedMinutes = minutes.toString().padStart(2, "0");

            return `${formattedHours}:${formattedMinutes}`;
        }

        // If neither 12-hour nor 24-hour format matches, return invalid
        return "Invalid Time";
    };
    
    // calculate Task Position
    const calculateTaskPosition = (task, rowHeight = 36) => {
        // Validate input: Return empty array if startTime or endTime is invalid
        if ((!task.startTime || task.startTime.trim() === '' || task.startTime === '00:00:00') &&
            (!task.endTime || task.endTime.trim() === '' || task.endTime === '00:00:00') ||
            task.startTime === task.endTime) {
            // If both start and end times are invalid or same, return an empty array
            return [];
        }       

        // Parse start and end times
        const _startHour = parseTime(task.startTime); // Start time in fractional hours
        const _endHour = parseTime(task.endTime);     // End time in fractional hours

        const gmtStartTime = is12HrFormat ? format12TimeZone(task.startTime) : format24TimeZone(task.startTime);
        const gmtEndTime = is12HrFormat ? format12TimeZone(task.endTime) : format24TimeZone(task.endTime);

        const segments = []; // Array to hold the calculated segments for task positioning

        // Normalize hours to fit within a 24-hour format
        let startHour = (_startHour.time % 24) + 1; // Add 1 to ensure hours start from 1 instead of 0
        //let endHour = (_endHour.time % 24) + ((_endHour.isDecimal) ? 1 : 2);     // Add 2 to account for any extra offset

        if (_endHour.time < _startHour.time) {
            let endHour = (_endHour.time % 24) + 2;     // Add 2 to account for any extra offset
            // Case 1: Task crosses midnight
            // Segment 1: From startHour to midnight
            // First segment: From startHour to midnight
            segments.push({
                top: (startHour * rowHeight) + 10,             // Top position in pixels
                height: (26 - startHour) * rowHeight,         // Height covers from startHour to midnight
                gmtStartTime: gmtStartTime,
                gmtEndTime: gmtEndTime
            });

            // Second segment: From midnight (start of the next day) to endHour
            segments.push({
                top: 45,                                      // Top position starts from the next day
                height: (endHour - 2) * rowHeight,                  // Height covers until endHour
                gmtStartTime: gmtStartTime,
                gmtEndTime: gmtEndTime
            });
        } else {
            // Case 2: Task occurs within the same day
            // Handle tasks that occur within the same day
            let endHour = (_endHour.time % 24) + ((_endHour.isDecimal) ? 1 : 2);     // Add 2 to account for any extra offset
            segments.push({
                top: startHour === 1 ? 45 : (startHour * rowHeight) + 10, // Adjust top position for the first hour
                height: (endHour - startHour) * rowHeight,               // Height covers the difference between start and end hours
                gmtStartTime: gmtStartTime,
                gmtEndTime: gmtEndTime
            });
        }

        return segments; // Return calculated task segments
    };
    
    const getLastName = (name) => {
        return name.split(" ")[0];  // Returns only the first name
    };

    function getAbbreviation(timezone) {
        return timezone ? timezone.split(" ").map(word => word[0]).join("") : "";
    }

    if (loading) {
        return <div className="no-data-tech">Technicians are loading...</div>;
    }

    if (!tasks.length) {
        return <div className="no-data-tech">No technicians available</div>;
    }

    return (
        <div className="scheduler-wrapper">
            <div className="scheduler" style={{
                gridTemplateColumns: `60px repeat(${tasks.length}, 75px)`, // Dynamic columns based on tasks.length
            }}>
                <div className="time-column">
                    <div className="column-header">GMT</div>
                    {Array.from({ length: 25 }, (_, i) => {
                        // Format the hour based on the `is12HrFormat` flag
                        const hour = is12HrFormat
                            ? i === 0
                                ? "12:00 AM" // Midnight in 12-hour format
                                : i < 12
                                    ? `${i.toString().padStart(2, "0")}:00 AM` // Morning hours
                                    : i === 12
                                        ? "12:00 PM" // Noon in 12-hour format
                                        : `${(i - 12).toString().padStart(2, "0")}:00 PM` // Afternoon/evening hours
                            : `${i.toString().padStart(2, "0")}:00`; // 24-hour format with leading zeros

                        return (
                            <div key={i} className="time-slot tech-time-slot">
                                {hour}
                            </div>
                        );
                    })}

                </div>

                {tasks.map((task, index) => {
                    // Calculate task positions, including possible split for tasks crossing midnight
                    const segments = calculateTaskPosition(task);
                    if (segments.length === 0) {
                        // Skip rendering if there are no valid segments
                        return (
                            <div
                                key={index}
                                className="technician-column">
                                <div className="column-header">{getLastName(task.label)}</div> 
                            </div>
                        );
                    }

                    return (
                        <div
                            key={index}
                            className="technician-column">
                            <div className="column-header">{task.label}</div>
                            {segments.map((segment, segmentIndex) => (
                                <div
                                    key={segmentIndex}
                                    className="task"
                                    style={{
                                        top: `${segment.top}px`,           // Correct top positioning
                                        height: `${segment.height}px`,     // Correct height for each segment
                                        backgroundColor: `${task.bgColor}`, // Background color for task
                                        lineHeight: `${segment.height}px`, // Set lineHeight equal to segment.height for vertical centering
                                        textAlign: "center",               // Ensure text is horizontally centered
                                    }}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, task)}
                                    onMouseEnter={(e) => {
                                        const tooltip = document.createElement('div');
                                        tooltip.className = 'tech-custom-tooltip';

                                        // Define local times
                                        const localStartTime = is12HrFormat ? format12TimeZone(task.localStartTime) : format24TimeZone(task.localStartTime);
                                        const localEndTime = is12HrFormat ? format12TimeZone(task.localEndTime) : format24TimeZone(task.localEndTime);

                                        // Add task details to the tooltip
                                        tooltip.innerHTML = `
                                            <div><strong>${task.label} (${getAbbreviation(task.timeZone)})</strong></div>
                                            <div><strong>Time Zone: ${task.timeZone}</strong></div>
                                            <div>Local Start Time: ${localStartTime}</div>
                                            <div>Local End Time: ${localEndTime}</div>
                                        `;

                                        tooltip.style.position = 'absolute';
                                        tooltip.style.backgroundColor = '#000';
                                        tooltip.style.padding = '8px';
                                        tooltip.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                        tooltip.style.borderRadius = '4px';
                                        tooltip.style.zIndex = '1000';
                                        tooltip.style.pointerEvents = 'none';

                                        document.body.appendChild(tooltip);

                                        const rect = e.target.getBoundingClientRect();
                                        const tooltipWidth = tooltip.offsetWidth;
                                        const tooltipHeight = tooltip.offsetHeight;

                                        // Calculate the tooltip position
                                        const tooltipTop = rect.top - tooltipHeight - 8; // Position above the element
                                        const tooltipLeft = rect.left + rect.width / 2 - tooltipWidth / 2; // Centered horizontally

                                        tooltip.style.top = `${Math.max(tooltipTop, 0)}px`; // Ensure it doesn't go above the viewport
                                        tooltip.style.left = `${Math.max(tooltipLeft, 0)}px`; // Ensure it doesn't go off-screen horizontally

                                        // Attach tooltip to the element
                                        e.target.tooltipElement = tooltip;
                                    }}
                                    onMouseLeave={(e) => {
                                        if (e.target.tooltipElement) {
                                            document.body.removeChild(e.target.tooltipElement);
                                            e.target.tooltipElement = null;
                                        }
                                    }}
                                >
                                    <>
                                        {getAbbreviation(task.timeZone)}
                                    </>
                                </div>

                            ))}
                        </div>
                    );
                })}

            </div>
        </div>
    );
};

export default Technician;