import React, { useState, useEffect } from "react";

const Clock = ({ is12HrFormat = true }) => {
    // Helper function to get the current GMT time in a standard format
    const getCurrentGMTTime = () => new Date().toISOString();

    // Function to format GMT time into 12-hour or 24-hour format
    const formatGMTTime = (gmtDateString) => {
        const gmtDate = new Date(gmtDateString);

        if (isNaN(gmtDate)) {
            throw new Error("Invalid date format. Please provide a valid GMT date string.");
        }

        // Formatting options for date and time
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: is12HrFormat,
            timeZone: "GMT",
        };

        return new Intl.DateTimeFormat("en-US", options).format(gmtDate);
    };

    const [currentTime, setCurrentTime] = useState(getCurrentGMTTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(getCurrentGMTTime());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return <>GMT: {formatGMTTime(currentTime)}</>;
};

export default Clock;