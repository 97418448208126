import axios from "axios";
// import * as url from "../../api/uri_helper"
import * as url from "../../api/uri_helper"

export const fetchdeshboardurl = async (userid, appid) => {
    const response = await axios
        .post(url.GETDASHBOARD_URL(userid, appid))

    return response;
};

export const fetchlogindata = async (emailid, password) => {
    const response = await axios
        .post(url.POST_LOGIN(emailid, password))

    return response;
};

export const fetchUserSiteName = async (userid) => {
    const response = await axios.get(`${url.GET_USERSITENAME}/${userid}`);
    return response;
};

export const fetchsendmessage = async (name, emailid, message) => {
    const response = await axios
        .post(url.VERIFY_SEND_MESSAGE(name, emailid, message))

    return response;
};

export const fetchAllAppsdata = async (userId) => {
    const response = await axios
        .get(url.GETAPPS_DATA + "/" + userId)
    return response;
};

export const fetchSupportRequestHistory = async (data) => {

    const response = await axios
        .post(url.GET_SUPPORTREQUEST_HISTORY, data)
    return response;
};

export const fetchSitesWithResellerCompany = async (userId) => {
    const response = await axios
        .get(url.GET_ALLSITESWITHRESELLER_COMPANY + "/" + userId)
    return response;
}

export const fetchCallOrderdata = async () => {
    const response = await axios
        .get(url.GET_ALLCALLORDERDATA)
    return response;
};

export const fetchSupportGroupdata = async () => {
    const response = await axios
        .get(url.GET_SUPPORTGROUPDATA)
    return response;
};

export const fetchUsersForSupportGroup = async () => {
    const response = await axios
        .get(url.GET_SUPPORTGROUPUSERS)
    return response;
};

export const fetchSupportGroupLogsdata = async (data) => {
    const response = await axios
        .post(url.GET_SUPPORTGROUPLOGS, data)
    return response;
};

export const fetchAllWorkdata = async () => {
    const response = await axios
        .get(url.GET_ALLWORKCATEGORY)
    return response;
};

export const fetchAllBannerdata = async () => {
    const response = await axios
        .get(url.GET_BANNERDATA)
    return response;
};

export const fetchAllSupportdata = async (data) => {
    const response = await axios
        .post(url.GET_ALLSUPPORTDATA, data)
    return response;
};

export const fetchAllNotificationdata = async () => {
    const response = await axios
        .get(url.GET_NOTIFICATIONDATA)
    return response;
};

export const fetchAllVersiondata = async () => {
    const response = await axios
        .get(url.GET_ALLVERSIONSUMMARY)
    return response;
};

export const fetchAllRetentiondata = async () => {
    const response = await axios
        .get(url.GET_ALLRETENTIONDATA)
    return response;
};

export const fetchWorkItemswithsupport = async () => {
    const response = await axios
        .get(url.GET_WORKITEMSHAVINGSUPPORT)
    return response;
};

export const fetchsupportrequestratio = async (data) => {
    const response = await axios
        .post(url.GET_SUPPORTREQUESTRATIO, data)
    return response;
};

export const fetchAllProjectdata = async () => {
    const response = await axios
        .get(url.GET_ALLAPPS)
    return response;
};

export const fetchSiteAppsdata = async (siteId) => {
    const response = await axios
        .get(url.GETSITEAPPS_DATA + "/" + siteId)
    return response;
};

export const validateuser = async (userId) => {
    const response = await axios
        .post(url.VALIDATE_USER + "/" + userId)
    return response;
};

export const loginasuser = async (emailId) => {
    const response = await axios
        .post(url.LOGINAS_USER + "/" + emailId)
    return response;
};

export const fetchSitesByCompanyId = async (companyId) => {
    const response = await axios
        .get(url.GET_SITESBYCOMPANYID + "/" + companyId)
    return response;
};

export const fetchCompanySitesById = async (companyId) => {
    const response = await axios
        .get(url.GET_COMPANYSITESBYID + "/" + companyId)
    return response;
};

export const fetchSitesByUserId = async (userId) => {
    const response = await axios
        .get(url.GET_SITESBYUSERID + "/" + userId)
    return response;
};

export const fetchUserSiteById = async (Id) => {
    const response = await axios
        .get(url.GET_USERSITEBYID + "/" + Id)
    return response;
};

export const fetchUserCompanyById = async (userId) => {
    const response = await axios
        .get(url.GET_USERCOMPANIESBYID + "/" + userId)
    return response;
};

export const fetchSubCompaniesByUserId = async (userId) => {
    const response = await axios
        .get(url.GET_SUBCOMPANIESBYUSERID + "/" + userId)
    return response;
};

export const fetchCompanyRelationById = async (Id) => {
    const response = await axios
        .get(url.GET_COMPANYRELATIONBYID + "/" + Id)
    return response;
};

export const fetchUsersByGroupId = async (sitename) => {
    const response = await axios
        .post(url.GET_USERSBYGROUPID + "/" + sitename)
    return response;
};

export const fetchAllCompaniesdata = async () => {
    const response = await axios
        .get(url.GET_ALLCOMPANIES)
    return response;
};

export const fetchUsersFromNotification = async () => {
    const response = await axios
        .get(url.GET_USERSFROMNOTIFICATION)
    return response;
};

export const fetchAllWorkItemsdata = async (data) => {

    const response = await axios
        .post(url.GET_ALLWORKITEMSSP, data)
    return response;
};

export const fetchAllTEKaController = async (data) => {
    const response = await axios
        .post(url.GET_ALLTEKACONTROLLER, data)
    return response;
};

export const fetchAllActivityLogs = async (data) => {

    const response = await axios
        .post(url.GET_ALLACTIVITYLOGS, data)
    return response;
};

export const fetchAllSupportLogs = async (data) => {

    const response = await axios
        .post(url.GET_SUPPORTLOGS, data)
    return response;
};

export const fetchCompanySupportLogs = async (data) => {

    const response = await axios
        .post(url.GET_COMPANYSUPPORT, data)
    return response;
};

export const fetchAllSessionLogs = async (data) => {

    const response = await axios
        .post(url.GET_ALL_SESSION_LOGS, data)
    return response;
};
export const fetchAllSubCompaniesdata = async () => {
    const response = await axios
        .get(url.GET_ALLSUBCOMPANIES)
    return response;
};

export const fetchAllCompanieswithsites = async (data) => {
    const response = await axios
        .post(url.GET_COMPANYWITHSITES, data)
    return response;
};

export const fetchSubCompanieswithsites = async () => {
    const response = await axios
        .get(url.GET_SUBCOMPANYWITHSITES)
    return response;
};

export const fetchUserSiteRelation = async (userid) => {
    const response = await axios
        .post(url.GET_USERSITERELATION, userid)
    return response;
};

export const fetchAllSites = async (userid) => {
    const response = await axios
        .post(url.GET_ALLSITES, userid)
    return response;
};

export const fetchAllSiteData = async () => {
    const response = await axios
        .get(url.GET_ALLSITESDETAILS)
    return response;
};

export const fetchAllCompanyRelation = async () => {
    const response = await axios
        .get(url.GET_ALLCOMPANYRELATION)
    return response;
};

export const fetchUpdatedCompanyRelation = async (id, masid, subid, act) => {
    const response = await axios
        .put(url.UPDATE_MAPPING_DATA, id, masid, subid, act)
    return response;
};

export const fetchUpdatedUserSiteRelation = async (userid, siteid) => {
    const response = await axios
        .put(url.UPDATE_USERSITERELATION, userid, siteid)
    return response;
};

export const fetchAllRoles = async () => {
    const response = await axios
        .get(url.GET_ALLROLES)
    return response;
};

export const changepassword = async (id, currentpaswd, newpaswd) => {
    const response = await axios
        .post(url.POST_CHANGEPASSWORD, id, currentpaswd, newpaswd)
    return response;
};

export const fetchsitebyusercompany = async (userid, companyid) => {
    const response = await axios
        .post(url.GET_SITESBYUSERCOMPANYID, userid, companyid)
    return response;
};

export const fetchupdatepassword = async (id, newpaswd) => {
    const response = await axios
        .post(url.ADD_UPDATEPASSWORD, id, newpaswd)
    return response;
};



export const fetchcompanyrelationdata = async (masid, subid) => {
    const response = await axios
        .post(url.POST_COMPANYRELATION, masid, subid)
    return response;
};

export const fetchusersitedata = async (userid, siteid) => {
    const response = await axios
        .post(url.POST_USERSITERELATION, userid, siteid)
    return response;
};

export const fetchProjectDetails = async () => {
    const response = await axios
        .get(url.GET_ALLPROJECTS)

    return response;
};
export const fetchAllDevelopers = async () => {
    const response = await axios.get(url.GET_ALLDEVELOPERS)
    return response;
};

export const fetchUsersDetails = async () => {
    const response = await axios
        .get(url.GET_ALLUSERS)

    return response;
};

export const fetchGroupDetails = async () => {
    const response = await axios
        .get(url.GET_ALLGROUPS)

    return response;
};

export const fetchUsersWithRoles = async (userid) => {
    const response = await axios
        .post(url.GET_USERSWITHROLES, userid)
    return response;
};

export const fetchUsersWithCompanies = async (userid) => {
    const response = await axios
        .post(url.GET_USERSWITHCOMPANIES, userid)
    return response;
};
export const postWorkItem = async (data) => {
    const response = await axios
        .post(url.POST_ALLWORKITEMS, data)
    return response;
};
export const postUpdateItem = async (data) => {
    
    const response = await axios.put(url.UPDATE_WORKITEMS ,data)
    return response;
}
export const fetchUserMenudata = async (userId) => {
    const response = await axios
        .get(url.GET_USERROLESANDMENUS + "/" + userId)
    return response;
};

export const fetchUserRoledata = async (userId) => {
    const response = await axios
        .get(url.GET_USERROLENAME + "/" + userId)
    return response;
};

export const fetchCompanyById = async (companyId) => {
    const response = await axios
        .get(url.GET_COMPANYBYID + "/" + companyId)
    return response;
};

export const fetchProjectById = async (projectId) => {
    const response = await axios
        .get(url.GET_PROJECTBYID + "/" + projectId)
    return response;
};
export const fetchWorkItemDetail = async (id) => {
    const response = await axios
        .get(url.GET_WORKITEMBYID + "/" + id)
    return response;
}

export const fetchSiteById = async (siteId) => {
    const response = await axios
        .get(url.GET_SITEBYID + "/" + siteId)
    return response;
};

export const fetchBannerById = async (bannerId) => {
    const response = await axios
        .get(url.GET_BANNERBYID + "/" + bannerId)
    return response;
};

export const fetchNotificationById = async (Id) => {
    const response = await axios
        .get(url.GET_NOTIFICATIONDATABYID + "/" + Id)
    return response;
};

export const fetchTEKaControllerById = async (tekaID) => {
    const response = await axios
        .get(url.GET_TEKACONTROLLERBYID + "/" + tekaID)
    return response;
};

export const fetchUserById = async (userId) => {
    const response = await axios
        .get(url.GET_USERBYID + "/" + userId)
    return response;
};
export const fetchdevComments = async () => {
    const response = await axios
        .get(url.GET_DEVELOPERCOMMENTS)
    return response;
}
export const postDeveloperComments = async (data) => {
    const response = await axios
        .post(url.POST_DEVELOPERDETAILS, data)
    return response;
}

export const fetchUpdatedComments = async (data) => {
    const response = await axios.post(url.UPDATE_DEVELOPERCOMMENTS, data)
    return response;
}

export const fetchTimeZones = async () => {
    const response = await axios
        .get(url.GET_TIMEZONES)

    return response;
};

export const fetchLogout = async (emailId, IsLogoutOrRefresh, IsSelfLogout) => {

    const response = await axios
        .post(url.GET_Logout + "/" + emailId + "/" + IsLogoutOrRefresh + "/" + IsSelfLogout)
    return response;
};
export const fetchRefreshTime = async (emailId) => {

    const response = await axios
        .post(url.GET_REFRESHTIME + "/" + emailId)
    return response;
};

export const fetchIdleTime = async () => {

    const response = await axios
        .get(url.GET_IDLETIME)
    return response;
};

export const fetchSitesWithAlarms = async (userid) => {
    const response = await axios
        .post(url.GET_SITES_WITH_ALARMS, userid)
    return response;
};

export const fetchCompanyDataBySiteId = async (siteId) => {
    const response = await axios
        .get(url.GET_COMPANYDATABYSITEID + "/" + siteId)
    return response;
};

export const postSupportEvent = async (data) => {
    const response = await axios
        .post(url.POST_SUPPORTEVENT, data)
    return response;
}

export const putSupportEvent = async (data) => {
    const response = await axios
        .put(url.UPDATE_SUPPORTEVENT, data)
    return response;
}

export const deleteSupportEvent = async (eventId) => {
    const response = await axios
        .delete(url.DELETE_SUPPORTEVENT + "/" + eventId)
    return response;
}

export const getSupportEventByGroupId = async (groupId) => {
    const response = await axios
        .get(url.GET_SUPPORTEVENT + "/" + groupId)
    return response;
};

export const getAllEventByGroupId = async (data) => {
    const response = await axios
        .post(url.GET_ALLEVENTSBYDATE , data)
    return response;
};

export const getAllSupportEvents = async (userId) => {
    const requestUrl = userId ? `${url.GET_ALLEVENTS}?userId=${userId}` : url.GET_ALLEVENTS;
    const response = await axios.get(requestUrl);
    return response;
};

export const getAllTodaysEvents = async (userId) => {
    const response = await axios
        .get(url.GET_ALLTODAYSEVENTS + "/" + userId)
    return response;
};

export const putSupportDataOnCalendar = async (data) => {
    const response = await axios
        .put(url.UPDATE_SUPPORTONCALENDAR, data)
    return response;
}

export const getUsersAndOrderByDate = async (data) => {
    const response = await axios
        .post(url.GET_USERSANDORDERBYDATE, data)
    return response;
}

export const swapTechnicianPresentOrder = async (data) => {
    const response = await axios
        .put(url.SWAP_TECHNICIANPRESENTORDER, data)
    return response;
}
 
export const getAllAssignedTechnicians = async () => {
    const response = await axios
        .get(url.GET_ASSIGNEDTECHNICIANS)
    return response;
};

export const getAllAssignedTechniciansByGroup = async (groupid) => {
    const response = await axios
        .get(url.GET_ASSIGNEDTECHNICIANSBYGROUP + "/" + groupid)
    return response;
};

export const deletefallback = async (fallbackid) => {
    const response = await axios
        .delete(url.DELETE_FALLBACK + "/" + fallbackid)
    return response;
};

export const getSupportGroupSites = async (userid) => {
  
    const response = await axios
        .get(url.GET_SUPPORTASSOCIATEDSITES + "/" + userid)
    return response;
};

export const getSubCompaniesByUserId = async (userId) => {
    const response = await axios
        .get(url.GET_SUBCOMPANYBYUSERID + "/" + userId)
    return response;
};

export const getUserAccessLevels_ = async (userId) => {
    const response = await axios
        .get(url.GET_USERACCESSLEVELS + "/" + userId)
    return response;
};

export const getUserAccessLevels = async (userId) => {
    // Construct the API endpoint URL based on whether userId is provided or not
    const apiUrl = userId
        ? `${url.GET_USERACCESSLEVELS}/${userId}` // With userId
        : `${url.GET_USERACCESSLEVELS}`; // Without userId

    // Make the GET request
    const response = await axios.get(apiUrl);
    return response;
};

export const updateUserLanguage = async (data) => {
    const response = await axios
        .put(url.UPDATE_USERLANGUAGE, data)
    return response;
};

export const getallSupperAdmins = async () => { 
    const response = await axios
        .get(url.GET_ALLSUPPERADMINFALLBACK)  
    return response;
};

export const assignSupportFallback = async (data) => {
    const response = await axios
        .post(url.ASSIGN_SUPPERADMINFALLBACK, data.payload) 
    return response;
}

export const getFallbackBySupportId = async (supportGroupId) => {
    const response = await axios
        .get(url.GET_FALLBACKBYSUPPORTID + "/" + supportGroupId)
    return response;
};

export const getSitesBySupportGroupId = async (supportGroupId) => {
    const response = await axios
        .get(url.GET_SITESBYSUPPORTID + "/" + supportGroupId)
    return response;
};

export const postBulkEventsAssign = async (data) => {
    const response = await axios
        .post(url.POST_BULKEVENTSASSIGN, data)
    return response;
}

export const getContractedSiteCoverage = async (day) => {
    const response = await axios
        .get(url.GET_CONTRACTEDSITECOVERAGE + "/" + day)
    return response;
}; 

export const getSiteCoverageData = async (day) => {
    const response = await axios
        .get(url.GET_SITECOVERAGEDATA + "/" + day)
    return response;
}; 